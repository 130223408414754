import { useAnalyticsEvent, CONTEXTS, ACTIONS, Payload } from 'hooks/analytics/useAnalyticsEvent';

const context = CONTEXTS.FACILITY_PER_DIEM;

interface IUsePerDiemEvent {
  trackClickedApplyTemplate: (payload: Payload) => void;
  trackClickedPostButtonPerDiemForm: (payload: Payload) => void;
  trackJobPostPageLoad: () => void;
  trackRepeatToggleClicked: (payload: Payload) => void;
}

export const usePerDiemEvent = (): IUsePerDiemEvent => {
  const trackClickedApplyTemplate = useAnalyticsEvent({
    context,
    subcontext: CONTEXTS.APPLY_TEMPLATE_BUTTON,
    action: ACTIONS.CLICKED,
  });

  const trackClickedPostButtonPerDiemForm = useAnalyticsEvent({
    context,
    subcontext: CONTEXTS.POST_BUTTON,
    action: ACTIONS.CLICKED,
  });

  const trackJobPostPageLoad = useAnalyticsEvent({ context, action: ACTIONS.VIEWED });

  const trackRepeatToggleClicked = useAnalyticsEvent({
    context,
    subcontext: CONTEXTS.REPEAT_TOGGLE,
    action: ACTIONS.CLICKED,
  });

  return {
    trackClickedApplyTemplate,
    trackClickedPostButtonPerDiemForm,
    trackJobPostPageLoad,
    trackRepeatToggleClicked,
  };
};

export default usePerDiemEvent;
