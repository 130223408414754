import { Button, Dialog } from '@medely/web-components';
import React, { ReactNode } from 'react';
import useDevice from 'hooks/useDevice';

type ConnectedModalProps = {
  open: boolean;
  onClose: () => void;
  handleSecondButtonClick?: () => void;
  handleSubmit: (values: any) => void;
  submitting: boolean;
  submitButtonText?: string;
  secondButtonText?: string;
  showSecondButton?: boolean;
  children: ReactNode;
  title: string;
  allowClose?: boolean;
};

const ConnectedModal = ({
  open = false,
  onClose,
  handleSubmit,
  handleSecondButtonClick,
  title,
  children,
  submitting,
  submitButtonText = 'Submit',
  secondButtonText = 'Cancel',
  showSecondButton = true,
  allowClose = true,
}: ConnectedModalProps): React.ReactElement => {
  const isMobile = useDevice() === 'mobile';

  const handleSecondButton = () => {
    handleSecondButtonClick?.();
    onClose();
    return;
  };

  return (
    <Dialog
      open={open}
      onClose={allowClose ? onClose : undefined}
      title={title}
      fullWidth={!isMobile}
      fullScreen={isMobile}
      content={children}
      actions={
        <>
          {showSecondButton && (
            <Button
              color="primary"
              variant="text"
              onClick={handleSecondButton}
              data-testid="second_button"
            >
              {secondButtonText}
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            disabled={submitting}
            loading={submitting}
            type="submit"
            onClick={handleSubmit}
          >
            {submitButtonText}
          </Button>
        </>
      }
    />
  );
};

export default ConnectedModal;
