import { Box, Button, IconButton } from '@medely/web-components';
import { useAuthContext } from '@medely/ui-kit/web';
import { Xmark } from '@medely/web-components/icons';
import { useLocationContext } from 'context/locationContext';
import useCurrentUser from 'hooks/useCurrentUser';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import React from 'react';
import isDevOrStaging from 'utils/isDevOrStaging';

type Props = {
  isImpersonating: boolean;
  isImpersonateBannerVisible: boolean;
  setIsImpersonateBannerVisible: (value: boolean) => void;
};

const Unimpersonate = ({
  isImpersonating,
  isImpersonateBannerVisible,
  setIsImpersonateBannerVisible,
}: Props): React.ReactElement | null => {
  const { logout } = useAuthContext();
  const { currentUser } = useCurrentUser();
  const handleStop = () => logout();

  const { location } = useLocationContext();
  const isStaging = isDevOrStaging();

  const { width } = useWindowDimensions();
  const isAtMinWidth = width < 600;

  const stopButton = (
    <Button
      sx={{
        color: 'white',
        borderColor: 'white',
        marginLeft: '8px',
        width: '80px',
        height: '24px',
        fontSize: '12px',
        fontWeight: 'medium',
        paddingLeft: '8px',
      }}
      variant="outlined"
      onClick={handleStop}
    >
      Stop
    </Button>
  );

  return isImpersonating && isImpersonateBannerVisible ? (
    <Box height={42}>
      <Box
        bgcolor={isStaging ? '#015582' : '#3E3E83'}
        color="white"
        height={42}
        width="100%"
        sx={{
          zIndex: 100,
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '13px',
          fontWeight: 'medium',
        }}
      >
        <IconButton
          sx={{ float: 'left', paddingRight: '12px' }}
          onClick={() => setIsImpersonateBannerVisible(false)}
        >
          <Xmark htmlColor="white" />
        </IconButton>
        <Box>
          {`Impersonating ${currentUser.first_name} ${currentUser.last_name} (${currentUser.id}, Loc ${location?.id})`}
          {!isAtMinWidth && stopButton}
        </Box>
        {isAtMinWidth ? stopButton : <div />}
      </Box>
    </Box>
  ) : null;
};

export default Unimpersonate;
