import { FundingSourceStatus } from '@medely/types';
import { BillingGroup } from 'graphql/generated/graphql';
import { useBillingGroupContext } from 'context/billingGroupContext';
import { usePay } from 'routes/billing/PayContext';

export const getFundingSources = (billingGroup: BillingGroup | undefined) => {
  if (!billingGroup) {
    // the appropriate billing group must exist
    return {
      savedCC: undefined,
      savedBankAccount: undefined,
    };
  }

  const fundingSources = (billingGroup?.funding_sources ?? [])
    .filter((fs) =>
      [FundingSourceStatus.Active, FundingSourceStatus.Processing].includes(
        fs.status as FundingSourceStatus,
      ),
    )
    .sort((a, b) => b.id - a.id);
  const savedCC = fundingSources.find((fs) => !!fs.brand);
  const savedBankAccount = fundingSources.find((fs) => !!fs.bank_name);

  return {
    savedCC,
    savedBankAccount,
  };
};

export const useFundingSources = () => {
  const { billingGroup: authBillingGroup, hostedBillingGroup } = useBillingGroupContext();
  const { isHostedBilling } = usePay();
  const billingGroup = isHostedBilling ? hostedBillingGroup : authBillingGroup;

  return getFundingSources(billingGroup);
};
