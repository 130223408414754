import React from 'react';
import { useForm, useFormState } from '@medely/web-components/forms';
import { useConfigurationContext } from '@medely/web-components/contexts';
import FormContainer from './FormContainer';

type Props = {
  handleSubmit: () => void;
  hasMobileNumber: boolean;
};

const DialogContent = ({ handleSubmit, hasMobileNumber }: Props) => {
  const config = useConfigurationContext();
  const { change } = useForm();
  const { values, submitSucceeded } = useFormState();

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      hasMobileNumber={hasMobileNumber}
      submitSucceeded={submitSucceeded}
      values={values}
      change={change}
      config={config}
    />
  );
};

export default DialogContent;
