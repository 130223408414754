import { getOT1Label, getOT2Label } from '@medely/base';

export const amountDueTooltipText =
  'Amount Due is the current amount due, which is the original amount net any payments or credits applied';

export const originalAmountTooltipText =
  'Original amount reflects the amount that was due in total when the invoice was created';

export const VERIFY_ON_STRIPE_TOOLTIP_TITLE =
  'Verify the micro-deposits in your account. Refer to the email from Stripe for instructions';

const TEN99_OT1_HOURS_TERM = getOT1Label({ isW2: false, abbreviate: true });
const TEN99_OT2_HOURS_TERM = getOT2Label({ isW2: false, abbreviate: true });
export const TEN99_OT1_HOURS_AS_LABEL = `${TEN99_OT1_HOURS_TERM} hrs`;
export const TEN99_OT2_HOURS_AS_LABEL = `${TEN99_OT2_HOURS_TERM} hrs`;

const W2_OT1_HOURS_TERM = getOT1Label({ isW2: true, abbreviate: true });
const W2_OT2_HOURS_TERM = getOT2Label({ isW2: true, abbreviate: true });
export const W2_OT1_HOURS_AS_LABEL = `${W2_OT1_HOURS_TERM} hrs`;
export const W2_OT2_HOURS_AS_LABEL = `${W2_OT2_HOURS_TERM} hrs`;
