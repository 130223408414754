import React from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';

type Props = {
  isHostedBilling: boolean;
};

export const StripePaymentField = ({ isHostedBilling }: Props) => {
  const options: StripePaymentElementOptions = {
    fields: {
      billingDetails: {
        name: isHostedBilling ? 'auto' : 'never',
        email: isHostedBilling ? 'auto' : 'never',
        address: {
          country: 'never',
        },
      },
    },
  };
  return <PaymentElement options={options} />;
};
