import { Box } from '@medely/web-components';
import Chat from './Chat';
import Notifications from './Notifications';
import React from 'react';
import SettingsDropdown from 'containers/SettingsDropdown';
import TopBarLocationSelector from 'containers/TopBar/TopBarLocationSelector';
import TopBarBillingGroupSelector from './TopBarBillingGroupSelector';

const TopBarDesktop = (): React.ReactElement => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      ml="88px"
      sx={{
        py: 1,
        px: 2,
      }}
    >
      <Box>
        <TopBarLocationSelector mr={3} />
        <TopBarBillingGroupSelector />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" data-testid="header-menu">
        <Chat />
        <Notifications />
        <SettingsDropdown />
      </Box>
    </Box>
  );
};

export default TopBarDesktop;
