import { Avatar, Button, Menu, MenuItem } from '@medely/web-components';
import { ChevronDown } from '@medely/web-components/icons';
import Protected from 'components/Authorization/Protected';
import React from 'react';
import routes from 'routes';
import useCurrentUser from 'hooks/useCurrentUser';
import { useConfigurationContext } from '@medely/ui-kit';
import { useAuthContext } from '@medely/ui-kit/web';
import { useHistory } from 'react-router';
import { useMenu } from 'hooks/useMenu';
import facilityConfig from 'config';

const SettingsDropdown = () => {
  const { currentUser } = useCurrentUser();
  const { logout } = useAuthContext();
  const history = useHistory();
  const menu = useMenu();
  const config = useConfigurationContext();

  return (
    <>
      <Button {...menu.triggerProps} endIcon={<ChevronDown />} data-testid="header-menu-dropdown">
        <Avatar name={currentUser?.name} />
      </Button>

      <Menu
        {...menu.menuProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {currentUser?.company?.verified && (
          <>
            <MenuItem
              key="account-settings"
              onClick={() => {
                history.push('/account-settings');
                menu.menuProps.onClose();
              }}
              data-testid="header-menu-dropdown-settings"
            >
              Account Settings
            </MenuItem>
            <Protected
              key="bill-rates"
              roles={['company_admin', 'facility_manager', 'billing_manager']}
            >
              <MenuItem
                onClick={() => {
                  history.push(routes.billRates.path);
                  menu.menuProps.onClose();
                }}
                data-testid="header-menu-dropdown-bill-rates"
              >
                {routes.billRates.name}
              </MenuItem>
            </Protected>
          </>
        )}

        <MenuItem
          onClick={() => {
            window.open(config.help.url, '_blank');
            menu.menuProps.onClose();
          }}
          data-testid="header-menu-dropdown-help"
        >
          Help
        </MenuItem>

        <MenuItem
          onClick={() => {
            window.open(config.userServiceAgreement, '_blank');
            menu.menuProps.onClose();
          }}
          data-testid="header-menu-dropdown-service"
        >
          Service Agreement
        </MenuItem>

        <MenuItem
          onClick={() => {
            window.open(facilityConfig.help.facilityCommunityGuidelines, '_blank');
            menu.menuProps.onClose();
          }}
          data-testid="header-menu-dropdown-community-guidelines"
        >
          Community Guidelines
        </MenuItem>

        <MenuItem
          onClick={() => {
            logout?.();
            menu.menuProps.onClose();
          }}
          data-testid="header-menu-dropdown-logout"
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default SettingsDropdown;
