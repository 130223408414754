import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { GET_BILLING_GROUPS } from 'graphql/billingGroupsQueries';
import { useAuthContext } from '@medely/ui-kit/web';
import { BillingGroup } from 'graphql/generated/graphql';
import useHandleError from 'hooks/useHandleError';
import { useQuery } from '@tanstack/react-query';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import { useFeatureGateContext } from './featureGateContext';

interface IContext {
  isLoading: boolean;
  billingGroup?: BillingGroup;
  setBillingGroup?: (billingGroup: BillingGroup) => void;
  hostedBillingGroup?: BillingGroup;
  setHostedBillingGroup?: (billingGroup: BillingGroup) => void;
  allBillingGroups: BillingGroup[];
  trackUnpaidBreaks: boolean;
}

const BillingGroupContext = createContext({
  isLoading: false,
  billingGroup: undefined,
  setBillingGroup: undefined,
  hostedBillingGroup: undefined,
  setHostedBillingGroup: undefined,
  allBillingGroups: [],
  trackUnpaidBreaks: false,
});

export const BillingGroupProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const { user } = useAuthContext();
  const onError = useHandleError();
  const { request } = useGraphQLRequest();
  const { selectedBillingGroupId, setBillingGroupId } = useFeatureGateContext();

  const getBillingGroups = async (): Promise<BillingGroup[]> => {
    const { billingGroups } = await request(GET_BILLING_GROUPS, {
      input: { filter: { status: 'active' }, orderBy: { name: true } },
    });
    return billingGroups;
  };
  const enabled = !!user;
  const { isLoading, data, isInitialLoading } = useQuery(['billingGroups'], getBillingGroups, {
    enabled,
    onError,
  });

  const [billingGroup, setBillingGroup] = useState<BillingGroup>();
  const [hostedBillingGroup, setHostedBillingGroup] = useState<BillingGroup>();

  useEffect(() => {
    if (data?.length) {
      setBillingGroup(
        data.find((billingGroup: BillingGroup) => billingGroup.id === selectedBillingGroupId) ??
          data[0],
      );
    }
  }, [data]);

  useEffect(() => {
    if (billingGroup?.id) {
      setBillingGroupId(billingGroup?.id);
    }
  }, [billingGroup?.id]);

  return (
    <BillingGroupContext.Provider
      value={{
        isLoading: enabled ? isLoading : isInitialLoading,
        billingGroup,
        setBillingGroup,
        hostedBillingGroup,
        setHostedBillingGroup,
        trackUnpaidBreaks: data?.some((b) => b.track_unpaid_breaks),
        allBillingGroups: data ?? [],
      }}
    >
      {children}
    </BillingGroupContext.Provider>
  );
};

export const useBillingGroupContext = (): IContext => useContext(BillingGroupContext);
