import { Query, useQuery } from '@tanstack/react-query';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { GET_LOCATIONS } from 'graphql/locationsQueries';
import { Location } from 'graphql/generated/graphql';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import { useAuthContext } from '@medely/ui-kit/web';
import useHandleError from 'hooks/useHandleError';
import noop from 'lodash/noop';
import { useFeatureGateContext } from './featureGateContext';

interface IContext {
  isLoading: boolean;
  location?: Location;
  locationHasValidBillingGroup?: boolean;
  setLocation: (location: Location) => void;
  allLocations: Location[];
  invalidate?: () => Promise<Query<unknown, unknown>>[];
  setLocationFromId: (newId: number) => void;
}

export const LocationContext = createContext<IContext>({
  isLoading: false,
  location: undefined,
  locationHasValidBillingGroup: false,
  setLocation: noop,
  allLocations: [],
  setLocationFromId: noop,
});

export const LocationProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const { user } = useAuthContext();
  const onError = useHandleError();
  const { request } = useGraphQLRequest();
  const { selectedLocationId, setLocationId: updateFeatureGateLocationId } =
    useFeatureGateContext();

  const getLocations = async () => {
    const { locations } = await request(GET_LOCATIONS, {
      input: {
        filter: {
          status: 'active',
        },
        orderBy: {
          name: true,
        },
      },
    });
    return locations;
  };

  const enabled = !!user;
  const { isLoading, data, isInitialLoading } = useQuery(['locations'], getLocations, {
    enabled,
    onError,
  });

  const [location, setLocation] = useState<Location | undefined>();

  useEffect(() => {
    if (data?.length) {
      setLocation(data.find((location: Location) => location.id === selectedLocationId) ?? data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (location?.id) {
      updateFeatureGateLocationId(location?.id);
    }
  }, [location?.id]);

  const setLocationFromId = (newId: number) => {
    if (data?.length) {
      setLocation(data.find((location: Location) => location.id === newId) ?? data[0]);
    }
  };

  return (
    <LocationContext.Provider
      value={{
        isLoading: enabled ? isLoading : isInitialLoading,
        location,
        setLocation,
        allLocations: data ?? [],
        locationHasValidBillingGroup: !!location?.billing_groups?.length,
        setLocationFromId,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationContext);
