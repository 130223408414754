import { ChatSolid } from '@medely/web-components/icons';
import { IconButton } from '@medely/web-components';
import React from 'react';
import show from 'utils/Intercom/show';

const TopBarMobileChat = (): React.ReactElement => (
  <IconButton onClick={show}>
    <ChatSolid color="primary" />
  </IconButton>
);

export default TopBarMobileChat;
