import { axios } from 'utils/networkRequests';

type ApproveResponse = {
  payload: {
    ids: number[];
  };
};

export const approvals = {
  approve: (id: number, subjectType: string) =>
    axios.post<ApproveResponse>('v3/facility/approvals/approve', {
      subject_id: id,
      subject_type: subjectType,
    }),
  reject: (id: number, subjectType: string, rejectionReason: string) =>
    axios.post('v3/facility/approvals/reject', {
      subject_id: id,
      subject_type: subjectType,
      rejection_reason: rejectionReason,
    }),
  preview: (
    locationId: number,
    ruleType: string,
    chargeRateCents: number,
    positionIds: number[],
    specialtyIds: number[],
    isSaas: boolean,
  ) =>
    axios.get('v3/facility/approvals/preview', {
      params: {
        location_id: locationId,
        rule_type: ruleType,
        charge_rate_cents: chargeRateCents,
        position_ids: positionIds,
        specialty_ids: specialtyIds,
        is_saas: isSaas,
      },
    }),
};
