import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';

export interface RedirectWithParamsProps<T extends { [K in keyof T]?: string }> {
  from: string;
  to: string | ((params: T) => string);
  exact?: boolean;
  strict?: boolean;
  push?: boolean;
}

export function RedirectWithParams<T extends { [K in keyof T]?: string }>(
  props: RedirectWithParamsProps<T>,
) {
  const { from, to, exact, strict, push } = props;
  const toFn = typeof to === 'string' ? () => to : to;
  const withWildcard = from.endsWith('/*');

  return (
    <Route
      exact={exact}
      strict={strict}
      path={from}
      render={(props: RouteComponentProps<T>) => {
        // In order to support nested routing we need wildcards in the from
        // which come across as param[0]
        let toPath = toFn(props.match.params);
        if (withWildcard) {
          if (!toPath.endsWith('/')) {
            toPath += '/';
          }

          toPath += props.match.params?.[0];
        }

        return (
          <Redirect
            push={push}
            to={{
              pathname: toPath,
              search: props.location.search,
              hash: props.location.hash,
            }}
          />
        );
      }}
    />
  );
}

export default Redirect;
