import { axios } from 'utils/networkRequests';

export const blocks = {
  create: ({
    id,
    reason,
    comment,
    locationId,
  }: {
    id: number;
    reason: number;
    comment: string;
    locationId: number;
  }) =>
    axios.post(`/v3/facility/locations/${locationId}/blocks`, {
      professional_id: id,
      block: {
        reason_id: reason,
        comments: comment,
      },
    }),
  delete: (id: number, locationId: number) =>
    axios.delete(`/v3/facility/locations/${locationId}/blocks/${id}`, {
      data: {
        professional_id: id,
      },
    }),
};
