import { gql } from 'graphql-request';

// Gets the approval groups the logged in account is a part of.
export const GET_APPROVAL_GROUPS = gql`
  query ApprovalGroups($input: ApprovalGroupQueryInput) {
    approvalGroups(input: $input) {
      id
    }
  }
`;

export const GET_JOBS_AWAITING_APPROVAL = gql`
  query JobsAwaitingApproval {
    jobsAwaitingApproval {
      id
      starts_time
      ends_time
      created_by {
        name
      }
      location {
        name
        timezone_lookup
      }
      positions {
        display_name
      }
      charge_base_hourly_rate_cents
      approval_violated_parameters
      approval_status
      approvals {
        id
        status
      }
    }
  }
`;
