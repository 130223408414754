import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Link,
  List,
  ListItem,
  Typography,
} from '@medely/web-components';
import React, { useState } from 'react';
import DisplayHelpers from 'utils/displayHelpers';
import { PhoneScreeningRequest } from 'graphql/generated/graphql';
import { PhoneSolid } from '@medely/web-components/icons';
import { centsToCurrency } from 'core/utils/currency';
import useDialog from 'hooks/useDialog';

interface IPhoneInterviewsProps {
  phone_interviews: PhoneScreeningRequest[];
}

const PhoneInterviews = ({ phone_interviews }: IPhoneInterviewsProps): React.ReactElement => {
  const [interview, setSelectedInterview] = useState<PhoneScreeningRequest>(phone_interviews[0]);
  const { open, openDialog, closeDialog } = useDialog();
  return (
    <Box mb={6} mx={3} overflow="hidden">
      <Box display="flex" alignItems="center" sx={{ bgcolor: 'grey.200', px: 2, py: 1 }}>
        <PhoneSolid color="primary" />
        &nbsp;&nbsp;
        <Typography variant="body2" weight="bold">
          Phone Interviews
        </Typography>
      </Box>
      <List>
        {phone_interviews.map((p) => (
          <ListItem key={p.id}>
            <Box>
              <Typography weight="medium">{displayTitle(p)}</Typography>
              <Typography variant="body2">with {p.professional.account.name}</Typography>
              <Button
                variant="link"
                onClick={() => {
                  setSelectedInterview(p);
                  openDialog();
                }}
              >
                View Details
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={open}
        onClose={closeDialog}
        title="Phone Interview"
        content={
          <>
            <Typography>
              Your phone interview is scheduled for{' '}
              {DisplayHelpers.dateTime.time(interview.confirmed_phone_screening_time)} on{' '}
              {DisplayHelpers.dateTime.date(interview.confirmed_phone_screening_time)}.<br />
              <br /> 1) Tap on the DIAL IN button to call directly from your mobile device <br />
              <br /> 2) Or join from any phone {interview.masked_professional_phone_number} <br />
              <br /> 3) After dialing please wait for {interview.professional.account.name} to join
              the conference call.{' '}
            </Typography>
            <Box height={1} bgcolor="gray.10" mt={3} />
            <Card>
              <CardHeader
                title={interview.assignment_request.assignment.positions
                  .map((p) => p.display_name)
                  .join(', ')}
              />
              <CardContent>
                <Typography>Assignment ID: {interview.assignment_request.assignment.id}</Typography>
                <Typography>
                  Start Date:&nbsp;
                  {DisplayHelpers.dateTime.date(
                    interview.assignment_request.assignment.starts_date,
                  )}
                </Typography>
                <Typography>
                  End Date:&nbsp;
                  {DisplayHelpers.dateTime.date(interview.assignment_request.assignment.ends_date)}
                </Typography>
                <Typography>
                  Hours per Week: {interview.assignment_request.assignment.hours_per_week}
                </Typography>
                <Typography>
                  Shift:&nbsp;
                  {DisplayHelpers.dateTime.time(
                    interview.assignment_request.assignment.starts_time,
                    interview.assignment_request.assignment.primary_location.timezone_lookup,
                  )}
                  &nbsp; –&nbsp;
                  {DisplayHelpers.dateTime.time(
                    interview.assignment_request.assignment.ends_time,
                    interview.assignment_request.assignment.primary_location.timezone_lookup,
                  )}
                </Typography>
                <Typography>
                  Compensation:&nbsp;
                  {centsToCurrency(interview.assignment_request.assignment.pay_per_week_cents)}/wk
                </Typography>
              </CardContent>
            </Card>
          </>
        }
        actions={
          <>
            <Button variant="link" onClick={closeDialog}>
              Close
            </Button>
            <Link href={`tel:${interview.masked_professional_phone_number}`}>
              <Button>Dial</Button>
            </Link>
          </>
        }
      />
    </Box>
  );
};

export default PhoneInterviews;

function displayTitle(p: PhoneScreeningRequest): string {
  return `${DisplayHelpers.dateTime.date(
    p.confirmed_phone_screening_time,
  )} at ${DisplayHelpers.dateTime.time(p.confirmed_phone_screening_time)}`;
}
