import { PermissionGroup } from 'graphql/generated/graphql';

export const hasRestrictedRoles = (
  restrictedRoles: string[] | undefined,
  permissionGroups: PermissionGroup[] | undefined,
) => {
  if (!restrictedRoles?.length || !permissionGroups?.length) {
    return false;
  }

  return permissionGroups.every((group) => restrictedRoles.includes(group.role));
};

export const hasPermittedRoles = (
  permittedRoles: string[] | undefined,
  permissionGroups: PermissionGroup[] | undefined,
) => {
  if (!permittedRoles?.length || !permissionGroups?.length) {
    return false;
  }

  return permissionGroups.some((group) => permittedRoles.includes(group.role));
};
