import { Button, Dialog } from '@medely/web-components';
import React, { ReactNode } from 'react';
import { FormRenderProps } from 'react-final-form';
import useDevice from 'hooks/useDevice';

type LocationWizardModalProps = FormRenderProps & {
  title: string;
  open?: boolean;
  children?: ReactNode;
  canGoBack?: boolean;
  onClose?: () => void;
  handlePrevClick?: () => void;
  submitForm?: string;
  nextLabel?: string;
};

const LocationWizardModal = ({
  title,
  open = false,
  children,
  submitting,
  invalid,
  canGoBack = true,
  onClose = null,
  handleSubmit,
  handlePrevClick,
  values,
  submitForm = '',
  nextLabel = 'Next',
}: LocationWizardModalProps): React.ReactElement => {
  const isMobile = useDevice() === 'mobile';
  const handleFormSubmit = () => {
    handleSubmit(values);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      fullWidth={!isMobile}
      fullScreen={isMobile}
      content={children}
      actions={
        <>
          {canGoBack && (
            <Button color="primary" variant="text" onClick={handlePrevClick}>
              Back
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={submitting || invalid}
            form={submitForm}
            type="submit"
            onClick={submitForm ? undefined : handleFormSubmit}
            data-testid="new-location-form-button"
          >
            {submitting ? 'Saving...' : nextLabel}
          </Button>
        </>
      }
    />
  );
};

export default LocationWizardModal;
