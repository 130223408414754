import React, { useState } from 'react';
import { Banner } from '@medely/web-components';

type RemindersBannerProps = {
  action: React.ReactElement;
  children: string | React.ReactElement;
};

const RemindersBanner = ({ children, action }: RemindersBannerProps): React.ReactElement | null => {
  const [show, setShow] = useState(true);
  return show ? (
    <Banner
      zIndex={99}
      position="bottom"
      color="error"
      closeButton={{
        onClick: () => setShow(false),
      }}
      action={action}
    >
      {children}
    </Banner>
  ) : null;
};

export default RemindersBanner;
