import React from 'react';
import { Typography } from '@medely/web-components';
import useNavbarMobile from 'hooks/useNavbarMobile';
import { useLocation } from 'react-router';

const HIDDEN_PATHS = ['/scheduler'];

const TopBarMobileTitle = (): React.ReactElement => {
  const { pathname } = useLocation();
  const mobileNavRoutes = useNavbarMobile();
  const route = mobileNavRoutes.find((r) => {
    const regex = new RegExp(`${r.path}${r.exact ? '$' : ''}`);
    return !HIDDEN_PATHS.includes(r.path) && regex.test(pathname);
  });
  return <Typography weight="medium">{route?.name}</Typography>;
};

export default TopBarMobileTitle;
