import { TermsOfServiceCheckbox, TermsOfServiceCheckboxProps } from '@medely/web-components';
import React from 'react';
import useTermsOfServiceAnalytics, { TermsOfServiceForms } from './useTermsOfServiceAnalytics';
import { useAuthContext } from '@medely/ui-kit/web';

type FacilityTermsOfServiceCheckboxProps = {
  email?: string;
  formName: TermsOfServiceForms;
} & Pick<TermsOfServiceCheckboxProps, 'onValid' | 'onInvalid'>;

const FacilityTermsOfServiceCheckbox = ({
  email,
  formName,
  onInvalid,
  onValid,
}: FacilityTermsOfServiceCheckboxProps) => {
  const { trackViewed, trackCompleted } = useTermsOfServiceAnalytics(formName);
  const { isImpersonating } = useAuthContext();
  const isTestEnv = process.env.REACT_APP_ENV === 'test';

  return (
    <TermsOfServiceCheckbox
      groupKey={process.env.REACT_APP_FAC_TOS_CHECKBOX_GROUP_KEY}
      onDisplayed={trackViewed}
      onInvalid={onInvalid}
      onValid={() => {
        trackCompleted();
        onValid();
      }}
      signerId={isImpersonating ? `impersonatingFacility` : `${email}`}
      testMode={isImpersonating || isTestEnv}
    />
  );
};

export default FacilityTermsOfServiceCheckbox;
