import { useMediaQuery } from '@medely/web-components';
import { useTheme } from '@medely/web-components/themes';

type Device = 'mobile' | 'desktop';

const useDevice = (): Device => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return isDesktop ? 'desktop' : 'mobile';
};

export default useDevice;
