import { useQuery } from '@tanstack/react-query';
import { GET_JOBS_AWAITING_APPROVAL } from 'graphql/approvalsQueries';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import { Job } from 'graphql/generated/graphql';
export const useApprovalJobs = (): {
  isLoading: boolean;
  jobs: Job[];
} => {
  const { request } = useGraphQLRequest();
  const getJobsAwaitingApproval = async (): Promise<Job[]> => {
    const { jobsAwaitingApproval } = await request(GET_JOBS_AWAITING_APPROVAL);
    return jobsAwaitingApproval;
  };
  const { isLoading, data } = useQuery(['jobsAwaitingApproval'], getJobsAwaitingApproval);

  return { isLoading, jobs: data ?? [] };
};
