import { useCallback, useMemo, useState } from 'react';
import shortid from 'shortid';

export type TriggerProps = {
  'aria-controls': string;
  'aria-haspopup': boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export type MenuProps = {
  id: string;
  onClose: () => void;
  keepMounted: boolean;
  anchorEl: HTMLElement | null;
  open: boolean;
};

export function useMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const id = useMemo(() => `menu-${shortid()}`, []);
  const buttonId = useMemo(() => `button-${shortid()}`, []);

  const handleClick = useCallback(
    (event?: any) => {
      setAnchorEl(event?.currentTarget || document.getElementById(buttonId));
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const triggerProps = useMemo(
    () => ({
      id: buttonId,
      'aria-controls': id,
      'aria-haspopup': true,
      onClick: handleClick,
    }),
    [id, handleClick],
  );

  const menuProps = useMemo(
    () => ({
      id,
      onClose: handleClose,
      keepMounted: true,
      anchorEl,
      open: Boolean(anchorEl),
    }),
    [id, handleClose, anchorEl],
  );

  return { triggerProps, menuProps };
}
