import React from 'react';
import { required } from 'core/utils/validation';
import useLocationTypes from 'hooks/useLocationTypes';
import { SingleSelectField } from '@medely/web-components/forms';

const LocationTypeIdField = (): React.ReactElement | null => {
  const { locationTypes } = useLocationTypes();
  const options = locationTypes?.map((ft) => ({
    value: ft.id,
    label: ft.label,
  }));
  return (
    <SingleSelectField
      name="location_type_id"
      label="Facility Type*"
      placeholder="Select"
      options={options}
      validate={required}
    />
  );
};

export default LocationTypeIdField;
