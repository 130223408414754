import React from 'react';
import type { Role } from '@medely/types';
import useCurrentUser from 'hooks/useCurrentUser';

interface IProtectedProps {
  children: React.ReactNode | React.ReactNode[];
  roles: Role[];
}

const Protected = ({ children, roles }: IProtectedProps): React.ReactElement => {
  const { currentUser } = useCurrentUser();
  const permission_groups = currentUser?.permission_groups ?? [];
  const userRoles = permission_groups.map((permission_group) => permission_group.role);
  return roles.some((role) => userRoles.includes(role)) ? <>{children}</> : <></>;
};

export default Protected;
