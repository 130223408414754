import { routes } from 'routes';
import useCurrentUser from './useCurrentUser';
import { gateNames } from 'constants/statsig';
import useGate from './useGate';
import { useApprovalGroups } from './useApprovalGroups';

type Route = { name: string; path: string; exact?: boolean };

const AUTHORIZED_ROUTES: Record<string, Route[]> = {
  billing_manager: [routes.billing],
  professional_manager: [routes.workforce],
  scheduler: [
    routes.dashboard,
    routes.scheduler,
    routes.assignments,
    routes.timeTracking,
    routes.analytics,
  ],
  view_only: [routes.scheduler, routes.assignments, routes.timeTracking],
  facility_manager: [
    routes.dashboard,
    routes.scheduler,
    routes.assignments,
    routes.timeTracking,
    routes.workforce,
    routes.billing,
    routes.analytics,
    routes.settings,
  ],
  company_admin: [
    routes.dashboard,
    routes.scheduler,
    routes.assignments,
    routes.timeTracking,
    routes.workforce,
    routes.billing,
    routes.analytics,
    routes.settings,
  ],
};

const useNavbarDesktop = () => {
  const { currentUser } = useCurrentUser();
  const { value: isAdminApprovalsOn } = useGate(gateNames.adminApprovals);
  const { value: featureDashboard } = useGate(gateNames.featureDashboard);
  const { value: analyticsDashboard } = useGate(gateNames.analyticsDashboard);
  const { isLoading, approvalGroups } = useApprovalGroups();
  const shouldShowApprovals = isAdminApprovalsOn && !isLoading && approvalGroups.length > 0;

  const userRoutes: Route[] = [];
  currentUser?.permission_groups?.forEach((permission_group) => {
    userRoutes.push(...AUTHORIZED_ROUTES[permission_group.role]);
  });
  if (shouldShowApprovals && !featureDashboard) {
    userRoutes.push(routes.approvals);
  }
  const availableRoutes: Route[] = [
    routes.scheduler,
    routes.assignments,
    routes.approvals,
    routes.timeTracking,
    routes.workforce,
    routes.billing,
    routes.analytics,
    routes.settings,
  ];

  if (featureDashboard) {
    availableRoutes.unshift(routes.dashboard);
  }

  const navRoutes: Route[] = availableRoutes.filter((route) => {
    if (route === routes.analytics && !analyticsDashboard) {
      return false;
    }
    return userRoutes.some((userRoute) => userRoute.name === route.name);
  });

  return navRoutes;
};

export default useNavbarDesktop;
