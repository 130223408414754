import { gql } from 'graphql-request';

export const GET_COMPANY = gql`
  query Company($id: Int!) {
    company(id: $id) {
      disable_rate_editing
      disabled_at
      onboarding_complete
      verified
    }
  }
`;
