import { TermsOfServiceModal, TermsOfServiceModalProps } from '@medely/web-components';
import { gateNames } from 'constants/statsig';
import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { useGate } from 'statsig-react';
import isDevOrStaging from 'utils/isDevOrStaging';
import useTermsOfServiceAnalytics from './useTermsOfServiceAnalytics';
import { useAuthContext } from '@medely/ui-kit/web';

type FacilityTermsOfServiceModalProps = Pick<TermsOfServiceModalProps, 'onError' | 'onSuccess'>;

const FacilityTermsOfServiceModal = ({ onError, onSuccess }: FacilityTermsOfServiceModalProps) => {
  const { currentUser } = useCurrentUser();
  const { trackViewed, trackCompleted } = useTermsOfServiceAnalytics('Modal');

  const { value: tosOn } = useGate(gateNames.tosOn);
  const { isImpersonating } = useAuthContext();
  const isStaging = isDevOrStaging();
  const dontShowRealContract = isStaging || isImpersonating;

  if (!currentUser) {
    return <></>;
  }

  const isVerified = currentUser?.company?.verified;

  return (
    <>
      {tosOn && !isImpersonating && isVerified && (
        <TermsOfServiceModal
          disabled={currentUser?.status !== 'active'}
          groupKey={
            dontShowRealContract
              ? 'embedded-staging-tos-scroll-container'
              : `${process.env.REACT_APP_FAC_TOS_MODAL_GROUP_KEY}`
          }
          onDisplayed={trackViewed}
          onError={onError}
          onSuccess={() => {
            trackCompleted();
            onSuccess?.call(this);
          }}
          signerId={
            isImpersonating ? `impersonating_${currentUser?.email}` : `${currentUser?.email}`
          }
        />
      )}
    </>
  );
};

export default FacilityTermsOfServiceModal;
