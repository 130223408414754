import {
  Box,
  BoxProps,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  TypographyProps,
} from '@medely/web-components';
import { ArrowDropDown } from '@mui/icons-material';
import React, { useState } from 'react';
import { useMenu } from 'hooks/useMenu';
import { useRouteMatch } from 'react-router-dom';
import { SearchRegular } from '@medely/web-components/icons';
import { BillingGroup } from 'graphql/generated/graphql';
import { useBillingGroupContext } from 'context/billingGroupContext';

type TopBarBillingGroupSelectorProps = BoxProps & {
  textProps?: TypographyProps;
};

const useSelectorVisibility = () => {
  return useRouteMatch('/billing');
};

const TopBarBillingGroupSelector = ({
  textProps = {},
  ...rest
}: TopBarBillingGroupSelectorProps): React.ReactElement | null => {
  const [query, setQuery] = useState('');
  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
  };
  const clearQuery = () => setQuery('');
  const { billingGroup, setBillingGroup, allBillingGroups } = useBillingGroupContext();

  const { menuProps, triggerProps } = useMenu();
  const isVisible = useSelectorVisibility();
  const handleSelect = (billingGroup: BillingGroup) => {
    setBillingGroup?.(billingGroup);
    menuProps.onClose();
  };
  const hasMultipleBillingGroups = allBillingGroups.length >= 2;

  if (!isVisible || allBillingGroups.length === 0) {
    return null;
  }

  const billingGroupText = (
    <Typography variant="body2" weight="medium" {...textProps}>
      {billingGroup?.name}&nbsp;&nbsp;
    </Typography>
  );

  const filteredBillingGroups = allBillingGroups.filter(
    (bg) => !!bg.name?.toLowerCase().includes(query.toLowerCase()),
  );

  return (
    <>
      <Box {...rest} data-testid="billing-group-select">
        {hasMultipleBillingGroups && (
          <Button
            endIcon={<ArrowDropDown />}
            {...triggerProps}
            variant="contained"
            color="secondary"
            sx={{
              paddingLeft: 3,
              width: '350px',
              justifyContent: 'space-between',
            }}
          >
            {billingGroupText}
          </Button>
        )}
        {!hasMultipleBillingGroups && billingGroupText}
      </Box>

      <Menu
        {...menuProps}
        sx={{
          top: '5px',
          '& .MuiPaper-root': {
            width: '350px',
            maxHeight: '544px',
          },
          '& .MuiFormControl-root': {
            width: '100%',
          },
        }}
      >
        <Box width="100%" px="10px" mb={1}>
          <TextField
            size="small"
            placeholder="Find billing group"
            fullWidth={true}
            sx={{ input: { fontSize: '14px' } }}
            value={query}
            onChange={handleQueryChange}
            InputProps={{
              onKeyDown: (e) => e.stopPropagation(),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRegular />
                </InputAdornment>
              ),
              endAdornment: query.length ? (
                <InputAdornment position="end">
                  <Button
                    onClick={clearQuery}
                    sx={{
                      height: '17px',
                      width: '17px',
                      borderRadius: '50%',
                      lineHeight: '17px',
                    }}
                  >
                    <Typography>×</Typography>
                  </Button>
                </InputAdornment>
              ) : null,
            }}
          />
        </Box>

        {filteredBillingGroups.map((billingGroup) => (
          <MenuItem
            key={billingGroup.id}
            aria-label={billingGroup.name}
            onClick={() => handleSelect(billingGroup)}
            data-testid="header-menu-item"
          >
            {billingGroup.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TopBarBillingGroupSelector;
