import React, { useEffect } from 'react';
import { omit } from 'lodash';
import { Button, Dialog, DialogProps } from '@medely/web-components';
import { Form } from '@medely/web-components/forms';
import useCurrentUser from 'hooks/useCurrentUser';
import useAccountUpdate from 'hooks/useAccountUpdate';
import DialogContent from './DialogContent';

const TextNotificationsDialog = ({
  open,
  onClose,
  handleNextAnnouncement,
}: DialogProps & { handleNextAnnouncement: () => void }): React.ReactElement => {
  const { updateAccount } = useAccountUpdate();
  const { currentUser } = useCurrentUser();
  const initialValues =
    { phone_number: currentUser?.phone_number, ...currentUser?.notification_settings?.sms } || {};

  useEffect(() => {
    if (
      currentUser?.phone_number_sms_enabled &&
      Object.values(currentUser?.notification_settings?.sms).some((setting) => setting)
    ) {
      handleNextAnnouncement();
    }
  }, [currentUser]);

  const handleFormSubmit = (values: any) => {
    updateAccount({
      phone_number: values.phone_number,
      notification_settings: {
        email: currentUser?.notification_settings?.email,
        sms: omit(values, ['phone_number']),
      },
    });
  };

  return (
    <Form onSubmit={handleFormSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Dialog
          open={open}
          onClose={onClose}
          title="SMS Shift Notifications"
          content={
            <DialogContent
              handleSubmit={handleSubmit}
              hasMobileNumber={currentUser.phone_number_sms_enabled}
            />
          }
          actions={
            <>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleSubmit}>Save</Button>
            </>
          }
        />
      )}
    </Form>
  );
};

export default TextNotificationsDialog;
