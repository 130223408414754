import { Box, NavLink } from '@medely/web-components';
import { favoritesRoutes, routes } from 'hooks/useNavbarMobile';
import React from 'react';
import { Route } from 'react-router-dom';
import TopBarDesktop from 'containers/TopBar/TopBarDesktop';
import TopBarMobile from 'containers/TopBar/TopBarMobile';
import useDevice from 'hooks/useDevice';

const TopBarDefault = (): React.ReactElement => {
  const isDesktop = useDevice() === 'desktop';
  return (
    <Box
      width="100%"
      id="topbar"
      data-testid="header"
      sx={{ backgroundColor: 'background.default' }}
    >
      {isDesktop ? (
        <TopBarDesktop />
      ) : (
        <>
          <TopBarMobile />
          <Route
            path={routes.professionals.path}
            component={() => (
              <Box display="flex">
                {favoritesRoutes.map(({ name, path, ...rest }) => (
                  <NavLink key={name} to={path} {...rest}>
                    {name}
                  </NavLink>
                ))}
              </Box>
            )}
          />
        </>
      )}
    </Box>
  );
};

export default TopBarDefault;
