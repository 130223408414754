import { Role } from '@medely/types';
import { Job } from 'graphql/generated/graphql';
import config from '../config';
import positionKinds from '../facility/positionKinds';
import useCurrentUser from './useCurrentUser';
import { gateNames } from 'constants/statsig';
import useGate from 'hooks/useGate';

const pathTo = (path: string) => `${config.root}/${path}`;

export const routes: {
  [key: string]: { exact?: boolean; name: string; path: string; permittedRoles?: Role[] };
} = {
  assignments: {
    name: 'Assignments',
    path: pathTo('assignments'),
    permittedRoles: ['company_admin', 'facility_manager', 'scheduler', 'view_only'],
  },
  billing: {
    name: 'Billing',
    path: pathTo('billing'),
    permittedRoles: ['billing_manager', 'company_admin', 'facility_manager'],
  },
  timeTracking: {
    name: 'Time Tracking',
    path: pathTo('time-tracking'),
    permittedRoles: ['company_admin', 'facility_manager', 'scheduler', 'view_only'],
  },
  newJob: {
    name: 'New Shift',
    path: pathTo('shifts/new'),
  },
  workforce: {
    name: 'Workforce',
    path: pathTo('workforce'),
    permittedRoles: ['company_admin', 'facility_manager', 'professional_manager'],
  },
  professionals: {
    name: 'Professionals',
    path: pathTo('professionals'),
    permittedRoles: ['company_admin', 'facility_manager', 'professional_manager'],
  },
  settings: {
    name: 'Settings',
    path: pathTo('settings/team-members'),
    permittedRoles: ['company_admin', 'facility_manager'],
  },
  scheduler: {
    exact: true,
    name: 'Scheduler',
    path: pathTo('scheduler'),
    permittedRoles: ['company_admin', 'facility_manager', 'scheduler', 'view_only'],
  },
  dashboard: {
    exact: true,
    name: 'Dashboard',
    path: pathTo('dashboard'),
    permittedRoles: ['company_admin', 'facility_manager', 'scheduler'],
  },
  analytics: {
    exact: true,
    name: 'Analytics',
    path: pathTo('analytics'),
    permittedRoles: ['company_admin', 'facility_manager', 'scheduler'],
  },
  account_settings: {
    name: 'Account Settings',
    path: pathTo('account-settings'),
  },
};

export const getEditPathById = (id?: number) => (id ? pathTo(`edit/${id}`) : pathTo(''));
const getViewPathById = (id?: number) => (id ? pathTo(`shifts/${id}`) : pathTo(''));

export const getJobPath = (job: Partial<Job>) => {
  switch (job.status) {
    case 'active':
      return getViewPathById(job.id);
    case 'clocked_out':
    case 'booked':
    case 'clocked_in':
    case 'completed':
      return getViewPathById(job.id);
    default:
      return '';
  }
};

const pathToFavorites = (kind: string) => `${pathTo('professionals')}/${kind}`;

export const favoritesRoutes = [
  { name: 'My Favorites', path: pathToFavorites('favorites'), exact: true },
  {
    exact: true,
    name: positionKinds.registered_nurse.title,
    path: pathToFavorites(positionKinds.registered_nurse.kind),
  },
  {
    exact: true,
    name: positionKinds.allied.title,
    path: pathToFavorites(positionKinds.allied.kind),
  },
  { name: 'Do Not Send', path: pathToFavorites('do-not-send'), exact: true },
];

const useNavbarMobile = () => {
  const { currentUser } = useCurrentUser();
  const { value: featureDashboard } = useGate(gateNames.featureDashboard);
  const { value: analyticsDashboard } = useGate(gateNames.analyticsDashboard);
  const navRoutesIds = [
    'scheduler',
    'assignments',
    'timeTracking',
    'workforce',
    'billing',
    'analytics',
    'professionals',
    'settings',
  ];

  if (featureDashboard) {
    navRoutesIds.unshift('dashboard');
  }

  const navRoutes = navRoutesIds.map((id) => routes[id]);
  let mobileNavRoutes = [...navRoutes, routes.account_settings];

  if (!analyticsDashboard) {
    mobileNavRoutes = mobileNavRoutes.filter((route) => route.name !== 'Analytics');
  }

  // @ts-ignore
  if (!currentUser.company.saas_credentialing_enabled) {
    mobileNavRoutes = mobileNavRoutes.filter((route) => route.name !== 'Workforce');
  }

  return mobileNavRoutes;
};

export default useNavbarMobile;
