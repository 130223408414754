import OnboardingWizard from 'routes/onboarding/components/OnboardingWizard';
import React from 'react';
import useShowCompanyOnboarding from 'hooks/useShowCompanyOnboarding';
import { gateNames } from 'constants/statsig';
import useGate from 'hooks/useGate';
import OnboardingWalkthrough from './components/OnboardingWalkthrough';

const Onboarding = (): React.ReactElement | null => {
  const showOnboarding = useShowCompanyOnboarding();
  const { value: isFacilityOnboarding60OnboardingOn } = useGate(
    gateNames.facilityOnboarding60Onboarding,
  );

  if (!showOnboarding) {
    return null;
  }

  return isFacilityOnboarding60OnboardingOn ? <OnboardingWalkthrough /> : <OnboardingWizard />;
};

export default Onboarding;
