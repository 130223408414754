import { createContext, useContext } from 'react';
import { Invoice } from 'graphql/generated/graphql';

type PayInfo = {
  billing_id: null | string | undefined;
  billing_group_uuid: null | string | undefined;
  invoice_ids: null | number[];
  amount_due_cents: number;
  billing_group_id: null | number;
  credit_balance: number;
  billing_group_balance_cents: number;
  invoices: Invoice[] | undefined;
  isHostedBilling?: boolean;
};

const PayContext = createContext<PayInfo>({
  billing_id: null,
  invoice_ids: null,
  amount_due_cents: 0,
  billing_group_id: null,
  billing_group_uuid: null,
  credit_balance: 0,
  billing_group_balance_cents: 0,
  invoices: [],
  isHostedBilling: false,
});

export const usePay = () => {
  const context = useContext(PayContext);
  if (!context) {
    throw new Error('Must be inside PayProvider');
  }
  return context;
};

export const PayProvider = PayContext.Provider;
