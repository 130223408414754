import { Account } from 'graphql/generated/graphql';
import { axios } from 'utils/networkRequests';

export const me = {
  update: (account: Partial<Account>, accountId: number) =>
    axios.put(`/v3/facility/accounts/${accountId}`, { account }),
  actions: {
    updateNotificationSettings: (updatedNotifications: any) =>
      axios.post('/v3/facility/accounts/notifications', {
        account: {
          notification_settings: updatedNotifications,
        },
      }),
  },
};
