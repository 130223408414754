import { BillingGroup } from 'graphql/generated/graphql';
import { axios } from 'utils/networkRequests';

export const billingGroups = {
  create: (values: Partial<BillingGroup>) =>
    axios.post('/v3/facility/billing_groups', { billing_group: values }),
  update: (values: Partial<BillingGroup>, id: number) =>
    axios.put(`/v3/facility/billing_groups/${id}`, { billing_group: values }),
  delete: (id: number) => axios.delete(`/v3/facility/billing_groups/${id}`),
};
