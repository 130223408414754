import { type ResultOf } from '@graphql-typed-document-node/core';
import { Box, IconButton, Typography } from '@medely/web-components';
import { ArrowBack } from '@mui/icons-material';
import { graphql } from 'graphql/generated';
import type { Account, Assignment, Professional } from 'graphql/generated/graphql';
import { useGraphQLQuery } from 'hooks/useGraphQLRequest';
import { toNumber } from 'lodash';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import displayHelpers from 'utils/displayHelpers';
import TopBarJobRightContent from './TopBarJobRightContent';
import { gateNames } from 'constants/statsig';
import { useGate } from 'statsig-react';

const TopBarTitleBackAssignmentGql = graphql(`
  query GetAssignmentForTopBarTitleBack($id: Int!) {
    assignment(id: $id) {
      ends_date
      id
      is_extension
      professional {
        account {
          name
          id
        }
        id
      }
    }
  }
`);

const TopBarTitleBackJobGql = graphql(`
  query GetJobForTopBarTitleBack($id: Int!) {
    job(id: $id) {
      id
      professional {
        account {
          name
        }
      }
    }
  }
`);

const TopBarTitleApplicationBackGql = graphql(`
  query GetApplicationForTopBarTitleBack($id: Int!) {
    assignmentRequest(id: $id) {
      id
      professional {
        account {
          name
        }
      }
    }
  }
`);

const useDataForTopBarTitleBack = (
  jobId: number | string,
  assignmentId: number | string,
  applicationId: number | string,
) => {
  const { data: assignmentData } = useGraphQLQuery<ResultOf<typeof TopBarTitleBackAssignmentGql>>({
    operationName: 'GetAssignmentForTopBarTitleBack',
    query: TopBarTitleBackAssignmentGql,
    variables: { id: toNumber(assignmentId) },
    enabled: !!toNumber(assignmentId) && !applicationId,
  });

  const { data: jobData } = useGraphQLQuery<ResultOf<typeof TopBarTitleBackJobGql>>({
    operationName: 'GetJobForTopBarTitleBack',
    query: TopBarTitleBackJobGql,
    variables: { id: toNumber(jobId) },
    enabled: !!toNumber(jobId) && !applicationId,
  });

  const { data: requestData } = useGraphQLQuery<ResultOf<typeof TopBarTitleApplicationBackGql>>({
    operationName: 'GetApplicationForTopBarTitleBack',
    query: TopBarTitleApplicationBackGql,
    variables: { id: toNumber(applicationId) },
    enabled: !!toNumber(applicationId),
  });

  return {
    assignmentRequest: requestData?.assignmentRequest,
    assignment: assignmentData?.assignment,
    job: jobData?.job,
  };
};

const TopBarTitleBack = ({
  displayEndContent = false,
}: {
  displayEndContent?: boolean;
}): React.ReactElement => {
  const [assignmentId] = useQueryParam('assignment_id', StringParam);
  const { id: jobOrAssignmentId, application_id } = useParams<{
    id: string;
    application_id: string;
  }>();

  const { assignment, job, assignmentRequest } = useDataForTopBarTitleBack(
    jobOrAssignmentId,
    assignmentId ?? jobOrAssignmentId,
    application_id,
  );

  const title = useTopBarTitleText(
    (job ?? assignmentRequest)?.professional?.account?.name,
    assignment,
  );
  const history = useHistory();

  return (
    <Box py={2} pr={2} display="flex" justifyContent="space-between" width="100%">
      <Box justifyContent="flex-start" alignItems="center" display="flex">
        <IconButton onClick={() => history.goBack()} data-testid="top-bar-back-button">
          <ArrowBack />
        </IconButton>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {displayEndContent && (
        <Box justifyContent="flex-start" alignItems="center" display="flex">
          <TopBarJobRightContent />
        </Box>
      )}
    </Box>
  );
};

export default TopBarTitleBack;

const useTopBarTitleText = (
  professionalName: string | undefined,
  assignment: Pick<Assignment, 'ends_date' | 'id' | 'is_extension'> & {
    professional: Pick<Professional, 'id'> & {
      account: Pick<Account, 'name' | 'id'>;
    };
  },
) => {
  const { value: isAssignmentUpdateGateOn } = useGate(gateNames.assignmentsUpdatePhase1);

  const isJobNew = useRouteMatch('/shifts/new');
  const isJobEdit = useRouteMatch('/shifts/:id/edit');
  const isApplicationOffer = useRouteMatch(
    '/assignments/:id/applications/:application_id/offer/:action',
  );
  const isAssignmentNew = useRouteMatch('/assignments/new');
  const isAssignmentJobPost = useRouteMatch('/assignments/shifts/new');
  const isAssignmentEdit = useRouteMatch('/assignments/:id/edit');
  const isAssignmentExtensionNew = useRouteMatch('/assignments/:id/extension/new');
  const isAssignmentApplications = useRouteMatch('/assignments/:id/applications/:status');
  const isTemplateEdit = useRouteMatch('/templates/:id/edit');
  const isTemplateNew = useRouteMatch('/templates/new');
  const isTemplateManager = useRouteMatch('/templates/manager');
  const isProfessionals = useRouteMatch('/workforce/:id');
  const isBilling = useRouteMatch('/billing');

  if (isAssignmentUpdateGateOn) {
    if (isApplicationOffer?.isExact) {
      return !!professionalName ? `Offer for ${professionalName}` : '';
    }

    if (isAssignmentApplications) {
      return 'Assignments';
    }
  }
  if (isJobNew) {
    return 'Post a Per Diem Shift';
  }
  if (isJobEdit) {
    return professionalName ? `Edit a Shift for ${professionalName}` : 'Edit Shift Details';
  }
  if (isApplicationOffer) {
    return !!professionalName ? `Offer for ${professionalName}` : '';
  }
  if (isAssignmentNew) {
    return 'Post an Assignment';
  }
  if (isAssignmentJobPost) {
    return assignment?.professional
      ? `Post a Shift for ${
          assignment?.professional.account.name
        } (Ends on ${displayHelpers.dateTime.date(assignment?.ends_date)} - ID ${assignment?.id})`
      : 'Set Date & Time and Select Professional';
  }
  if (isAssignmentEdit) {
    return `Post an Assignment${assignment?.is_extension ? ' Extension' : ''}`;
  }
  if (isAssignmentExtensionNew) {
    return 'Post an Assignment Extension';
  }
  if (isTemplateEdit) {
    return 'Edit Template';
  }
  if (isTemplateNew) {
    return 'New Template';
  }
  if (isTemplateManager) {
    return 'Shift Templates';
  }
  if (isProfessionals) {
    return 'Professionals';
  }
  if (isBilling) {
    return 'Billing';
  }
};
