import isDevOrStaging from './utils/isDevOrStaging';

const config = {
  apiRoot: process.env.REACT_APP_API_URL,
  professionalUrl: process.env.REACT_APP_PROFESSIONAL_URL,
  adminUrl: process.env.REACT_APP_ADMIN_URL,
  apiSuffix: '',
  breakpoints: {
    extraSmall: 480,
    small: 768,
    medium: 992,
    large: 1200,
  },
  closeClockInModalTime: 1000 * 60 * 10,
  disableCancelWithinHours: 24,
  notificationsLimit: 5,
  help: {
    url: 'https://help.medely.com',
    referral: 'https://help.medely.com/articles/374955-medely-referral-program',
    medely_professionals: 'https://help.medely.com/collections/127398-medely-professionals',
    signing_up: 'https://help.medely.com/collections/127521-signing-up',
    background_check_pending:
      'https://help.medely.com/en/articles/3844099-why-is-my-background-check-still-pending',
    irregular_occurrence:
      'https://help.medely.com/en/articles/2259702-facility-reporting-an-irregular-occurrence',
    facilityCommunityGuidelines:
      'https://help.medely.com/en/articles/8834479-facility-community-guidelines',
  },
  persist: {
    blacklist: [
      'notifications',
      'form',
      'data',
      'browser',
      'constants',
      'admin',
      'person',
      'entities',
      'impersonate',
      'staff',
    ],
    key: 'root',
  },
  professionalApplicationPath: '/professionals/application',
  root: '',
  staffOnboardingPath: '/onboarding',
  testTypeform: 'p4ayvY',
  facilityReferralTypeform: isDevOrStaging()
    ? 'https://medely.typeform.com/to/p4ayvY'
    : 'https://medely.typeform.com/to/YY80I9',
  userServiceAgreement: 'https://medely.com/facility_terms',
  professionalTermsPathname: '/professional_terms',
  calendlyAssignmentURL: isDevOrStaging()
    ? 'https://calendly.com/development-phone-screening/development-phone-screening-2'
    : 'https://calendly.com/phone-screening/assignments',
  calendlyURL: isDevOrStaging()
    ? 'https://calendly.com/development-phone-screening/development-phone-screening'
    : 'https://calendly.com/phone-screening/phone-screening',
  resumeExtensions: ['.doc', '.docx', '.pdf', '.txt'],
  feature: {
    timecards: true,
  },
  adpUrl: 'https://workforcenow.adp.com/workforcenow/login.html',
  resumeServerUrl: process.env.REACT_APP_RESUME_SERVER_URL,
  pdfServerUrl: process.env.REACT_APP_PDF_URL,
  itemUrl: process.env.REACT_APP_ITEM_URL,
  landingsUrl: process.env.REACT_APP_LANDINGS_URL || 'http://localhost:3001',
  localStorage: {
    tokenKey: 'medely-token',
  },
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  tokenExpMs: 86400000, // 24h
  assignmentReferralTerms: 'https://help.medely.com/en/articles/4809046-assignment-referrals',
  helloSignId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyDf0mHckHWJZpHAU20_vKQe57Fc-8zAxI4',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'medely-staging.firebaseapp.com',
  },
  segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY || 'HLA6SeIU8HzIn5zna7KuLuUGAXsCwKk9',
  statsigSdkKey:
    process.env.REACT_APP_STATSIG_KEY || 'client-r9VZ6M6DNDfUWqCsTXYEBDSdo4wLgbtIrpnucXJWZ5a',
  rollbar: {
    postClientItemKey:
      process.env.REACT_APP_ROLLBAR_CLIENT_KEY || '74e9f70c026e416e83e7a499a95653d3',
  },
};

export default config;
