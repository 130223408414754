import { Grid, Typography } from '@medely/web-components';
import React from 'react';
import { composeValidation, email, required } from 'core/utils/validation';
import { TextField } from '@medely/web-components/forms';

const emailValidator = composeValidation(required, email);

const BillingManagerFormDetails = (): React.ReactElement => {
  return (
    <>
      <Typography gutterBottom>
        If someone else in your organization is responsible for finances, you can add them to Medely
        as your billing manager.
      </Typography>

      <Grid item xs={12}>
        <TextField
          name="email"
          type="email"
          validate={emailValidator}
          label="Company email address*"
          rows={1}
          helperText={'Required'}
        />
      </Grid>
    </>
  );
};

export default BillingManagerFormDetails;
