import { gql } from 'graphql-request';

export const GET_LOCATIONS = gql`
  query Locations($input: LocationQueryInput) {
    locations(input: $input) {
      id
      location_type_id
      location_type {
        label
      }
      company_id
      market_id
      created_at
      parking_instructions
      allow_assignments
      allow_per_diem_jobs
      primary_billing_group_id
      primary_billing_group {
        id
        name
        track_unpaid_breaks
        billing
      }
      billing_groups {
        id
        name
      }
      name
      label
      full_address
      account_manager {
        account {
          email
          name
          phone_number
        }
      }
      timezone_lookup
      scrubs_provided
      unit
      allow_reserved_for_favorites
      state {
        abbreviation
      }
      address_zip
      agencies {
        id
      }
      pro_pool_enabled
    }
  }
`;

export const GET_LOCATIONS_ADMIN_PAGE = gql`
  query LocationsAdminPage($input: LocationQueryInput) {
    locations(input: $input) {
      id
      name
      location_type_id
      address_first
      address_second
      address_city
      address_zip
      state {
        id
        abbreviation
        name
      }
      unit
      accounts: manager_accounts {
        first_name
        last_name
        status
      }
      billing_groups {
        id
        name
      }
      phone_number
      scrubs_provided
      address_zip
      address_city
      address_second
      address_first
      state_id
      unit
      location_type_id
      name
      parking_instructions
      parking_free
      parking_validated
      parking_reimbursed
      parking_reimbursement_cents
      parking_cost_cents
    }
  }
`;

export const GET_LOCATION = gql`
  query Location($id: Int!) {
    location(id: $id) {
      id
      phone_number
      scrubs_provided
      address_zip
      address_city
      address_second
      address_first
      state_id
      unit
      location_type_id
      name
      parking_instructions
      parking_free
      parking_validated
      parking_reimbursed
      parking_reimbursement_cents
      parking_cost_cents
      primary_billing_group_id
      primary_billing_group {
        id
        name
        track_unpaid_breaks
      }
    }
  }
`;
