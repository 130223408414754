import { useSnackbar as useNotistackSnackbar } from 'notistack';

const useSnackbar = () => {
  const { enqueueSnackbar } = useNotistackSnackbar();
  return {
    success: (message) => enqueueSnackbar(message, { variant: 'success' }),
    warning: (message) => enqueueSnackbar(message, { variant: 'warning' }),
    error: (error) =>
      enqueueSnackbar(error.response?.data?.error || error.message, { variant: 'error' }),
  };
};

export default useSnackbar;
