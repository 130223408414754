import PaymentFirstPastDueReminder from './PaymentFirstPastDueReminder';
import React from 'react';
import useShowPastDueReminders from 'hooks/useShowPastDueReminders';

const Reminders = () => {
  const { showFirstReminder } = useShowPastDueReminders();
  if (showFirstReminder) {
    return <PaymentFirstPastDueReminder />;
  }
  return null;
};

export default Reminders;
