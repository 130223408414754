import { useGraphQLContext } from '@medely/ui-kit/web';
import { DocumentNode } from 'graphql';
import {
  type QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import gqlRequest from 'utils/networkRequests/gqlRequest';

type RequestFn<TResult, TRequestParams> = (
  query: string | DocumentNode,
  variables?: TRequestParams,
) => Promise<TResult>;

type UseGraphqlRequestResult<TResult, TRequestParams> = {
  gqlRequest: RequestFn<TResult, TRequestParams>;
  request: RequestFn<TResult, TRequestParams>;
};

export const useGraphQLRequest = <TResult = any, TRequestParams = any>(): UseGraphqlRequestResult<
  TResult,
  TRequestParams
> => {
  const { transform } = useGraphQLContext();

  const request: RequestFn<TResult, TRequestParams> = async (query, variables) => {
    return gqlRequest<TResult>(transform(query), variables);
  };

  return { gqlRequest: request, request };
};

export default useGraphQLRequest;

type UseGraphQLQueryOptions<TVariables, TRequestParams> = UseQueryOptions & {
  operationName: string;
  paramsFn?: (variables: TVariables) => TRequestParams;
  query: string | DocumentNode;
  variables: TVariables;
};

export const useGraphQLQuery = <TResult = unknown, TVariables = unknown, TRequestParams = unknown>(
  options: UseGraphQLQueryOptions<TVariables, TRequestParams>,
) => {
  const { request } = useGraphQLRequest<TResult, TRequestParams>();
  const { paramsFn, query, variables, ...queryOptions } = options;

  const queryFn = async ({
    queryKey: [, queryFnParams],
  }: QueryFunctionContext<(QueryKey | TVariables | undefined)[]>) => {
    const params = paramsFn
      ? paramsFn(queryFnParams as TVariables)
      : (queryFnParams as TRequestParams);

    return request(query, params);
  };

  return useQuery(
    [options.operationName as unknown as QueryKey, variables],
    queryFn,
    queryOptions as any,
  );
};

type UseGraphQLInfiniteQueryOptions<TVariables, TPageParams, TRequestParams> = UseQueryOptions & {
  operationName: string;
  paramsFn?: (variables: TVariables, pageParams: TPageParams) => TRequestParams;
  query: string | DocumentNode;
  variables: TVariables;
};

export const useGraphQLInfiniteQuery = <
  TResult = unknown,
  TVariables = unknown,
  TPageParams = unknown,
  TRequestParams = unknown,
>(
  options: UseGraphQLInfiniteQueryOptions<TVariables, TPageParams, TRequestParams>,
) => {
  const { request } = useGraphQLRequest<TResult, TRequestParams>();
  const { paramsFn, query, variables, ...queryOptions } = options;

  const queryFn = async ({
    queryKey: [, queryFnParams],
    pageParam,
  }: QueryFunctionContext<(QueryKey | TVariables | undefined)[]>) => {
    const params = paramsFn
      ? paramsFn(queryFnParams as TVariables, pageParam as TPageParams)
      : (queryFnParams as TRequestParams);

    return request(query, params);
  };

  return useInfiniteQuery(
    [options.operationName as unknown as QueryKey, variables],
    queryFn,
    queryOptions as any,
  );
};
