import { gql } from 'graphql-request';

export const GET_ASSIGNMENT_REQUESTS = gql`
  query AssignmentRequests($input: AssignmentRequestQueryInput) {
    assignmentRequests(input: $input) {
      id
      assignment_id
      created_at
      favorited_by
      is_favorite
      phone_screening_request {
        id
        status
      }
      professional {
        status
        account {
          first_name
          last_name
        }
      }
      status
      viewed
    }
  }
`;

export const GET_NEW_ASSIGNMENT_REQUESTS = gql`
  query NewAssignmentRequests($input: AssignmentRequestQueryInput) {
    assignmentRequests(input: $input) {
      id
      assignment_id
      assignment {
        starts_date
        positions {
          display_name
        }
      }
    }
  }
`;

export const GET_ASSIGNMENT_REQUEST = gql`
  query AssignmentRequest($id: Int!, $assignmentId: Int!) {
    assignmentRequest(id: $id) {
      id
      assignment_id
      created_at
      is_favorite
      phone_screening_request {
        id
        status
      }
      professional {
        account {
          email
          first_name
          last_name
          name
          phone_number
          ssn_last_4
        }
        can_accept_assignment_offer(assignment_id: $assignmentId)
        dob
        id
        positions {
          id
          kind
          display_name
        }
        shifts_completed
        shifts_completed_here(location_ids: [$assignmentId])
        specialties {
          id
          active
          name
          label
          positions {
            id
          }
        }
        years_experience
      }
      assignment {
        number_of_shifts_per_week
        booked_at
        schedule_type
        guaranteed_minimum_hours
        starts_date
        ends_date
        starts_time
        ends_time
        locations {
          id
          name
          timezone_lookup
        }
      }
      status
      viewed
      offer_sent_date
    }
  }
`;
