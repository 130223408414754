import React, { useRef } from 'react';
import useDialog from 'hooks/useDialog';
import { StripeForm } from '@medely/web-components/contexts';
import BillingGroupModal from 'routes/settings/billingGroups/BillingGroupModal';
import { Form } from 'react-final-form';
import {
  InitialOnboardingBillingValues,
  InviteBillingManagerFormValues,
  PaymentTypeFormValues,
} from 'routes/onboarding/InitialOnboardingBillingValues';
import useBillingUpsert from 'routes/settings/billingGroups/BillingGroupModal/useBillingUpsert';
import useCurrentUser from 'hooks/useCurrentUser';
import ConnectedModal from 'components/ConnectedModal';
import PaymentTypeFormDetails from 'components/PaymentTypeFormDetails';
import useLocationValues, {
  LocationFormValues,
} from 'routes/settings/locations/LocationWizard/useLocationValues';
import useLocationUpsert from 'routes/settings/locations/LocationWizard/useLocationUpsert';
import { useLocationContext } from 'context/locationContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useTeamMemberActions from 'routes/settings/teamMembers/components/FormComponents/useTeamMemberActions';
import WelcomeFormDetails from './WelcomeFormDetails';
import BillingManagerFormDetails from './BillingManagerFormDetails';
import { Box, Typography } from '@medely/web-components';
import { useTheme } from '@medely/web-components/themes';
import { omit } from 'lodash';
import { useAnalytics } from '@medely/ui-kit/web';

const BILLING_MANAGER_PERMISSION_GROUP_OPTION = {
  label: 'billing_manager',
  value: 4,
};

const STEPS_BY_NAME = {
  welcome: 1,
  paymentType: 2,
  billingGroup: 3,
  billingManager: 4,
};

const OnboardingWalkthrough = (): React.ReactElement => {
  const WelcomeDialog = useDialog();
  const PaymentTypeDialog = useDialog();
  const BillingGroupDialog = useDialog();
  const BillingManagerDialog = useDialog();
  const initialBillingValues = InitialOnboardingBillingValues();
  const createBillingGroup = useBillingUpsert();
  const { currentUser } = useCurrentUser();
  const upsertLocation = useLocationUpsert();
  const { location } = useLocationContext();
  const initialLocationValues = useLocationValues(location);
  const queryClient = useQueryClient();
  const theme = useTheme();
  const analytics = useAnalytics();

  const initialModalShown = useRef(false);

  const hidePaymentTypeDialog = !!currentUser?.company?.hide_invoicing_during_onboarding;
  const companyHasOnboardedLocation = !!currentUser?.company?.has_onboarded_location;
  const companyHasBillingGroup = !!currentUser?.company?.billing_groups?.length;
  const companyHasBillingManager = !!currentUser?.company?.has_billing_manager;
  const billingManagerIsFirstStep =
    companyHasOnboardedLocation && companyHasBillingGroup && initialModalShown.current === false;

  interface OnboardingStep {
    shouldSkip: boolean;
    dialog: ReturnType<typeof useDialog>;
  }

  const STEPS: Record<number, OnboardingStep> = {
    1: {
      shouldSkip: companyHasOnboardedLocation,
      dialog: WelcomeDialog,
    },
    2: {
      shouldSkip: companyHasBillingGroup || hidePaymentTypeDialog,
      dialog: PaymentTypeDialog,
    },
    3: {
      shouldSkip: companyHasBillingGroup,
      dialog: BillingGroupDialog,
    },
    4: {
      shouldSkip: companyHasBillingManager || billingManagerIsFirstStep,
      dialog: BillingManagerDialog,
    },
  };

  const moveToNextStep = (stepNumber: number) => {
    let nextStepNumber = stepNumber;
    let searching = true;
    while (searching) {
      if (STEPS[nextStepNumber]?.shouldSkip) {
        nextStepNumber += 1;
      } else {
        searching = false;
      }
    }
    const nextStep = STEPS[nextStepNumber];
    if (nextStep) {
      nextStep.dialog.openDialog();
    }
  };

  const { invite } = useTeamMemberActions();

  const { mutate: updateLocation } = useMutation(upsertLocation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['currentUser']);
      WelcomeDialog.closeDialog();
      moveToNextStep(STEPS_BY_NAME.paymentType);
    },
  });

  const billingGroupName = !!currentUser?.company?.locations?.length
    ? `${currentUser?.company?.locations[0]?.name} Billing Group`
    : 'Default';

  const handleWelcomeSubmit = async (values: LocationFormValues) => {
    updateLocation(values);
    return;
  };

  const handlePaymentTypeSubmit = async (values: PaymentTypeFormValues) => {
    if (values.payment_type !== 'weekly') {
      PaymentTypeDialog.closeDialog();
      moveToNextStep(STEPS_BY_NAME.billingGroup);
    } else {
      const submitValues = omit(values, ['payment_type']);
      await createBillingGroup(
        {
          ...submitValues,
          name: billingGroupName,
          location_id: location?.id || undefined,
        },
        {
          onSuccess: () => {
            PaymentTypeDialog.closeDialog();
            moveToNextStep(STEPS_BY_NAME.billingManager);
          },
        },
      );
    }
    return;
  };

  const handleBillingManagerSubmit = async (values: InviteBillingManagerFormValues) => {
    invite(
      {
        email: values.email,
        permission_groups: [BILLING_MANAGER_PERMISSION_GROUP_OPTION],
      },
      {
        onSuccess: () => {
          BillingManagerDialog.closeDialog();
        },
      },
    );
  };

  if (!initialModalShown.current && currentUser) {
    moveToNextStep(STEPS_BY_NAME.welcome);
    initialModalShown.current = true;
  }

  const trackDismissPaymentMethod = () =>
    analytics.track({
      context: 'Facility Onboarding',
      subcontext: 'Add payment method - Dismissed',
      action: 'Clicked',
      location_type: location?.location_type?.label,
      location_id: location?.id,
    });

  return (
    <>
      <Form initialValues={initialLocationValues} onSubmit={handleWelcomeSubmit}>
        {({ submitting, handleSubmit }) => (
          <ConnectedModal
            open={WelcomeDialog.open}
            onClose={WelcomeDialog.closeDialog}
            submitting={submitting}
            handleSubmit={(values) => {
              analytics.track({
                context: 'Facility Onboarding',
                subcontext: 'Welcome to Medely - Next',
                action: 'Clicked',
                location_type: location?.location_type?.label,
                location_id: location?.id,
              });
              return handleSubmit(values);
            }}
            showSecondButton={false}
            submitButtonText="Next"
            allowClose={false}
            title="Welcome to Medely!"
          >
            <WelcomeFormDetails />
          </ConnectedModal>
        )}
      </Form>

      <Form initialValues={initialBillingValues} onSubmit={handlePaymentTypeSubmit}>
        {({ submitting, handleSubmit }) => (
          <ConnectedModal
            open={PaymentTypeDialog.open}
            onClose={() => {
              trackDismissPaymentMethod();
              return PaymentTypeDialog.closeDialog();
            }}
            submitting={submitting}
            handleSubmit={(values) => {
              analytics.track({
                context: 'Facility Onboarding',
                subcontext: 'Add payment method - Next',
                action: 'Clicked',
                payment_method_type: values.billing === 'weekly_billing' ? 'weekly' : 'autopay',
                location_type: location?.location_type?.label,
                location_id: location?.id,
              });
              return handleSubmit(values);
            }}
            secondButtonText="Add later"
            handleSecondButtonClick={() => {
              trackDismissPaymentMethod();
              return moveToNextStep(STEPS_BY_NAME.billingManager);
            }}
            submitButtonText="Next"
            title="Add a payment method"
          >
            <PaymentTypeFormDetails />
          </ConnectedModal>
        )}
      </Form>

      <StripeForm>
        <BillingGroupModal
          open={BillingGroupDialog.open}
          onClose={() => {
            trackDismissPaymentMethod();
            return BillingGroupDialog.closeDialog();
          }}
          handleBackButton={hidePaymentTypeDialog ? undefined : PaymentTypeDialog.openDialog}
          handleNextButton={() => moveToNextStep(STEPS_BY_NAME.billingManager)}
          location_id={location?.id}
          title="Add a payment method"
          showNameField={false}
        >
          <Box bgcolor={theme.palette.grey[100]} borderRadius={2} p={1} mb={3}>
            <Typography weight="medium" color="textPrimary" fontSize={13}>
              Autopay
            </Typography>
            <Box ml={-2}>
              <ul>
                <li>
                  <Typography fontSize={13}>Completed shifts are charged next day</Typography>
                </li>

                <li>
                  <Typography fontSize={13}>
                    {'Pay with credit or debit card (fees may apply)'}
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
        </BillingGroupModal>
      </StripeForm>

      <Form onSubmit={handleBillingManagerSubmit}>
        {({ submitting, handleSubmit }) => (
          <ConnectedModal
            open={BillingManagerDialog.open}
            onClose={BillingManagerDialog.closeDialog}
            submitting={submitting}
            handleSubmit={() => {
              analytics.track({
                context: 'Facility Onboarding',
                subcontext: 'Add billing manager - Invite',
                action: 'Clicked',
                location_type: location?.location_type?.label,
                location_id: location?.id,
              });
              return handleSubmit();
            }}
            secondButtonText="Skip"
            submitButtonText="Invite"
            title="Add a billing manager"
          >
            <BillingManagerFormDetails />
          </ConnectedModal>
        )}
      </Form>
    </>
  );
};

export default OnboardingWalkthrough;
