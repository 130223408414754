import { Location } from 'graphql/generated/graphql';
import { axios } from 'utils/networkRequests';

export const locations = {
  delete: (location: Location) => axios.delete(`/v3/facility/locations/${location.id}`),
  actions: {
    updateBillingGroups: (billing_group_ids: number[], locationId: number) =>
      axios.put(`/v3/facility/locations/${locationId}`, { location: { billing_group_ids } }),
  },
};
