import { Grid, Typography, RadioGroup, FormControlLabel, Radio, Box } from '@medely/web-components';
import React, { useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { useFormState } from 'react-final-form';
import { PaymentTypeFormValues } from 'routes/onboarding/InitialOnboardingBillingValues';
import { useTheme } from '@medely/web-components/themes';

const PaymentTypeFormDetails = (): React.ReactElement => {
  const { values } = useFormState<PaymentTypeFormValues>();
  const [paymentTypeSelection, setPaymentTypeSelection] = useState(values.payment_type || 'weekly');
  const { currentUser } = useCurrentUser();
  const theme = useTheme();
  const billingGroupName = !!currentUser?.company?.locations?.length
    ? `${currentUser?.company?.locations[0]?.name} Billing Group`
    : 'Default';

  values.name = billingGroupName;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentTypeSelection(event.target.value);
    values.payment_type = event.target.value;
  };

  return (
    <Grid container spacing={2} data-testid="parking_instructions">
      <Grid item xs={12} data-testid="parking">
        <Typography variant="body2" color="textPrimary" pb={2} mt={-1}>
          You will only be charged after a shift is completed by a healthcare professional.
        </Typography>
        <RadioGroup name="parking_selection" onChange={handleChange} value={paymentTypeSelection}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box bgcolor={theme.palette.grey[100]} borderRadius={2} p={1} width="49%">
              <FormControlLabel
                value="weekly"
                control={<Radio />}
                label={
                  <>
                    <Typography
                      display="inline"
                      weight="medium"
                      color="textPrimary"
                      pr={1}
                      fontSize={13}
                    >
                      Weekly invoice
                    </Typography>
                    <Typography
                      display="inline"
                      fontSize={13}
                      weight="medium"
                      color="primary"
                      bgcolor="primary.light"
                      borderRadius={1}
                      p={1}
                      sx={{ textWrap: 'nowrap' }}
                    >
                      Most popular
                    </Typography>
                  </>
                }
              />
              <Box ml={-2}>
                <ul>
                  <li>
                    <Typography fontSize={13}>
                      Shifts are invoiced weekly based on their completed date
                    </Typography>
                  </li>

                  <li>
                    <Typography fontSize={13}>30 day net terms</Typography>
                  </li>

                  <li>
                    <Typography fontSize={13}>4% invoicing fee</Typography>
                  </li>
                </ul>
              </Box>
            </Box>
            <Box bgcolor={theme.palette.grey[100]} borderRadius={2} p={1} width="49%">
              <FormControlLabel
                value="autopay"
                control={<Radio />}
                label={
                  <Typography weight="medium" color="textPrimary" fontSize={13}>
                    Autopay
                  </Typography>
                }
              />

              <Box ml={-2}>
                <ul>
                  <li>
                    <Typography fontSize={13}>Completed shifts are charged next day</Typography>
                  </li>

                  <li>
                    <Typography fontSize={13}>
                      {'Pay with credit or debit card (fees may apply)'}
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default PaymentTypeFormDetails;
