import { Location } from 'graphql/generated/graphql';

const useLocationValues = (location?: Location) => ({
  id: location?.id,
  phone_number: undefined,
  scrubs_provided: undefined,
  address_zip: location?.address_zip,
  address_city: location?.address_city,
  address_second: location?.address_second,
  address_state: undefined,
  address_first: location?.address_first,
  state_id: undefined,
  unit: undefined,
  location_type_id: location?.location_type,
  name: location?.name,
  parking_instructions: location?.parking_instructions?.toString(),
  stripe_token: undefined,
  primary_billing_group_id: undefined,
  ...location,
  parking_free: location?.parking_free?.toString(),
  parking_validated: location?.parking_validated?.toString(),
  parking_reimbursement_cents: location?.parking_reimbursement_cents
    ? (location.parking_reimbursement_cents / 100).toFixed(2)
    : undefined,
  parking_cost_cents: location?.parking_cost_cents
    ? (location.parking_cost_cents / 100).toFixed(2)
    : undefined,
  parking_reimbursed: location?.parking_reimbursed?.toString(),
});

export type LocationFormValues = {
  id: number;
  phone_number?: string;
  scrubs_provided: boolean;
  address_zip: string;
  address_city: string;
  address_second: string;
  address_state?: string;
  address_first: string;
  state_id?: number;
  unit?: string;
  location_type_id: number;
  name: string;
  parking_instructions: string;
  stripe_token: string;
  primary_billing_group_id: number;
  parking_free?: string;
  parking_validated?: string;
  parking_reimbursement_cents?: number;
  parking_cost_cents?: number;
  parking_reimbursed?: string;
};

export default useLocationValues;
