import { Checkbox, FormControlLabel, Grid, Typography } from '@medely/web-components';
import {
  CardNumberField,
  ExpirationDateField,
  CVCField,
  TextField,
} from '@medely/web-components/forms';
import React, { useState } from 'react';
import { BillingGroup } from 'graphql/generated/graphql';
import { required } from 'core/utils/validation';

type BillingGroupFormProps = {
  error?: string;
  billingGroup?: BillingGroup;
  showNameField?: boolean;
};

const BillingGroupForm = ({
  error,
  billingGroup,
  showNameField = true,
}: BillingGroupFormProps): React.ReactElement => {
  const isPaymentMethodRequired = billingGroup ? billingGroup.billing === 'standard_billing' : true;
  const [showPaymentFields, setShowPaymentFields] = useState(isPaymentMethodRequired);
  const handleShowPaymentFields = () => {
    setShowPaymentFields((prevState) => !prevState);
  };
  return (
    <>
      <Grid container spacing={2} data-testid="new-billing-group-form">
        {showNameField && (
          <Grid item xs={12} sx={{ mb: 1 }}>
            <TextField label="Billing Group Name" name="name" validate={required} />
          </Grid>
        )}
        {!isPaymentMethodRequired && (
          <Grid item xs={12} sx={{ mb: 1 }}>
            <FormControlLabel
              control={<Checkbox value={showPaymentFields} onChange={handleShowPaymentFields} />}
              label="Update Payment Method"
            />
          </Grid>
        )}
        {showPaymentFields && (
          <>
            <Grid item xs={12} data-testid="new-billing-group-form_card_number">
              <CardNumberField name="card_number" label="Credit Card Number" />
            </Grid>
            <Grid item xs={12} md={6}>
              <ExpirationDateField name="expiration_date" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CVCField name="cvc" />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default BillingGroupForm;
