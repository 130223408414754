import { MedelyDateTime } from '@medely/date-time-tools';

export default function getExpectedHoursPerShift(
  starts_time: string,
  ends_time: string,
  tz: string,
) {
  const start = new MedelyDateTime(starts_time, { tz });
  const end = new MedelyDateTime(ends_time, { tz });

  return start.difference(end, 'hours', 2);
}
