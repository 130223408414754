import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { api } from 'api/index';

export type BillingType =
  | 'standard_billing'
  | 'monthly_billing'
  | 'bi_monthly_billing'
  | 'weekly_billing'
  | 'vms_billing';

type BillingUpsertValues = {
  id?: number;
  name: string;
  stripe_token?: string;
  setup_intent_id?: string;
  billing?: BillingType;
  location_id?: number;
};

const useBillingUpsert = () => {
  const snackbar = useSnackbar();

  const { mutateAsync: upsert } = useMutation(
    ({ id, ...values }: BillingUpsertValues) => {
      if (id) {
        return api.billingGroups.update(values, id);
      } else {
        return api.billingGroups.create(values);
      }
    },
    {
      onSuccess: (_response, variables) => {
        const message = variables.id
          ? 'Billing group has been successfully updated'
          : 'Billing group has been successfully created';
        snackbar.success(message);
      },
    },
  );

  return upsert;
};

export default useBillingUpsert;
