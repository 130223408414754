import { useAnalytics } from '@medely/ui-kit/web';
import useCurrentUser from 'hooks/useCurrentUser';

export type TermsOfServiceForms = 'Account Setup' | 'Location Wizard' | 'Modal';

const useTermsOfServiceAnalytics = (formName: TermsOfServiceForms) => {
  const { currentUser } = useCurrentUser();
  const analytics = useAnalytics();

  const metadata = {
    form: formName,
    professional_id: currentUser?.professional?.id,
  };

  return {
    trackCompleted: () => analytics.track('Facility Terms of Service Completed', metadata),
    trackViewed: () => analytics.track('Facility Terms of Service Viewed', metadata),
  };
};

export default useTermsOfServiceAnalytics;
