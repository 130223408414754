import { FormRenderProps } from 'react-final-form';
import { Grid, InputAdornment, Typography } from '@medely/web-components';
import { RadioField, TextField } from '@medely/web-components/forms';
import { composeValidation, required } from 'core/utils/validation';
import React from 'react';

const greaterThanZero =
  (message = 'Must be more than $0') =>
  (v) =>
    v > 0 ? undefined : message;
const costValidation = composeValidation(required, greaterThanZero('Amount must be more than $0'));

const YN_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const LocationWizardParkingForm = ({ values }: FormRenderProps): React.ReactElement => {
  const notFree = values.parking_free === 'false';
  const notValidated = values.parking_validated === 'false';
  return (
    <Grid container spacing={2} data-testid="parking_instructions">
      <Grid item xs={12}>
        <TextField
          name="parking_instructions"
          validate={required}
          label="Parking Instructions"
          placeholder="Provide parking instructions for your facility to ensure that your Professional is on time and ready to work."
          multiline
          rows={4}
          helperText={'Required'}
        />
      </Grid>
      <Grid item xs={12} data-testid="parking_free">
        <RadioField
          validate={required}
          name="parking_free"
          label={
            <Typography variant="body2" weight="medium" color="textPrimary">
              Is parking free or validated?
            </Typography>
          }
          options={YN_OPTIONS}
          inline
        />
      </Grid>
      {notFree && (
        <Grid item xs={12} data-testid="parking_validated">
          <RadioField
            validate={required}
            name="parking_validated"
            label={
              <Typography variant="body2" weight="medium" color="textPrimary">
                Do you validate parking?
              </Typography>
            }
            options={YN_OPTIONS}
            inline
          />
        </Grid>
      )}
      {notFree && notValidated && (
        <Grid item xs={12} data-testid="parking_reimbursed">
          <RadioField
            validate={required}
            name="parking_reimbursed"
            label={
              <Typography variant="body2" weight="medium" color="textPrimary">
                Do you reimburse for parking?
              </Typography>
            }
            options={YN_OPTIONS}
            inline
          />
        </Grid>
      )}
      {notFree && notValidated && values.parking_reimbursed === 'true' ? (
        <Grid item xs={12} data-testid="parking_reimbursed_cents">
          <TextField
            name="parking_reimbursement_cents"
            validate={costValidation}
            label="Parking Reimbursement"
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />
        </Grid>
      ) : (
        notFree &&
        notValidated &&
        !!values.parking_reimbursed && (
          <Grid item xs={12}>
            <TextField
              name="parking_cost_cents"
              validate={costValidation}
              label="Parking Cost"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default LocationWizardParkingForm;
