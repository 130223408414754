import { Link } from 'react-router-dom';
import React from 'react';
import RemindersBanner from 'components/Reminders/RemindersBanner';
import { Typography } from '@medely/web-components';

const PaymentFirstPastDueReminder = () => (
  <RemindersBanner
    action={
      <Link to="/billing" style={{ color: 'white' }}>
        <Typography weight="bold" variant="body2">
          Please make a payment
        </Typography>
      </Link>
    }
  >
    Your account is past due.
  </RemindersBanner>
);

export default PaymentFirstPastDueReminder;
