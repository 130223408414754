import { BillingType } from 'routes/settings/billingGroups/BillingGroupModal/useBillingUpsert';

export const InitialOnboardingBillingValues = () => ({
  billing: 'weekly_billing',
  payment_type: 'weekly',
});

export type PaymentTypeFormValues = {
  billing: BillingType;
  name: string;
  stripe_token: string;
  payment_type: string;
  email: string;
};

export type InviteBillingManagerFormValues = {
  email: string;
};
