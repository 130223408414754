import { OptionType } from '@medely/web-components';
import { axios } from 'utils/networkRequests';
import { TeamMemberFormValues } from 'routes/settings/teamMembers/components/TeamMemberForm';

export const teamMembers = {
  update: (values: Partial<TeamMemberFormValues>) =>
    axios.put(`/v3/facility/accounts/${values.id}`, {
      account: {
        billing_group_ids: [
          ...values.existingUnauthorizedBillingGroupIds,
          ...values.billing_groups.map((o: OptionType) => o.value),
        ],
        location_ids: [
          ...values.existingUnauthorizedLocationIds,
          ...values.locations.map((o: OptionType) => o.value),
        ],
        permission_group_ids: values.permission_groups?.map((o: OptionType) => o.value) ?? [],
        group_node_ids: values.location_groups?.map((o: OptionType) => o.value) ?? [],
      },
    }),
  delete: (id: number) => axios.delete(`/v3/facility/accounts/${id}`),
  actions: {
    invite: (values: Partial<TeamMemberFormValues>) =>
      axios.post('/v3/facility/accounts/invites', {
        account: {
          email: values.email,
          billing_group_ids: values.billing_groups?.map((o: OptionType) => o.value) ?? [],
          location_ids: values.locations?.map((o: OptionType) => o.value) ?? [],
          permission_group_ids: values.permission_groups?.map((o: OptionType) => o.value) ?? [],
          group_node_ids: values.location_groups?.map((o: OptionType) => o.value) ?? [],
        },
      }),
    resendInvite: (values: Partial<TeamMemberFormValues>) =>
      axios.post('/v3/facility/accounts/invites', {
        account: {
          id: values.id,
        },
      }),
  },
};
