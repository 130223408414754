import { GET_LOCATION_TYPES } from 'graphql/locationTypesQueries';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import useHandleError from 'hooks/useHandleError';
import { useQuery } from '@tanstack/react-query';

const useLocationTypes = (): {
  loading: boolean;
  locationTypes: Array<{ id: number; label: string }>;
} => {
  const { request } = useGraphQLRequest();
  const onError = useHandleError();
  const getLocationTypes = async () => {
    const { locationTypes } = await request(GET_LOCATION_TYPES, {
      input: {
        orderBy: {
          label: true,
        },
      },
    });
    return locationTypes;
  };
  const { isLoading: loading, data: locationTypes = [] } = useQuery(
    ['location_types'],
    getLocationTypes,
    {
      onError,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );
  return { loading, locationTypes };
};

export default useLocationTypes;
