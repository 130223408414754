import { GET_NEEDS_REVIEW_JOB_COUNT } from 'graphql/jobsQueries';
import { gqlRequest } from 'utils/networkRequests';
import { useQuery } from '@tanstack/react-query';
import { Location } from 'graphql/generated/graphql';

const fetchJobs = async ({
  location_ids,
  statuses,
}: {
  location_ids: number;
  statuses: string[];
}): Promise<{
  _jobsMeta: { count: number };
}> => {
  const { _jobsMeta } = await gqlRequest(GET_NEEDS_REVIEW_JOB_COUNT, {
    input: {
      search: {
        location_ids,
        statuses,
      },
    },
  });
  return { _jobsMeta };
};

interface IUseNeedsReviewJobCountReturn {
  count: number | undefined;
}

const useNeedsReviewJobCount = (location: Location): IUseNeedsReviewJobCountReturn => {
  const enabled = !!location?.id;
  const statuses = ['held_for_dispute_review'];

  const { data } = useQuery(
    ['needsReviewCount', location?.id],
    () => fetchJobs({ location_ids: location?.id, statuses }),
    {
      enabled,
    },
  );

  return { count: data?._jobsMeta?.count };
};

export default useNeedsReviewJobCount;
