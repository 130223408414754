import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { GET_BILLING_GROUPS } from 'graphql/billingGroupsQueries';
import { BillingGroup } from 'graphql/generated/graphql';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import { AxiosResponse } from 'utils/networkRequests';
import useHandleError from './useHandleError';
import useSnackbar from 'hooks/useSnackbar';
import { api } from 'api/index';

const useBillingGroups = (): {
  isLoading: boolean;
  billingGroups: BillingGroup[];
  archive: UseMutateFunction<AxiosResponse<void>, unknown, number, unknown>;
} => {
  const onError = useHandleError();
  const snackbar = useSnackbar();
  const { request } = useGraphQLRequest();

  const getBillingGroups = async (): Promise<BillingGroup[]> => {
    const { billingGroups } = await request(GET_BILLING_GROUPS, {
      input: {
        filter: { status: 'active' },
        orderBy: { name: true },
      },
    });
    return billingGroups;
  };
  const { isLoading, data } = useQuery(['billingGroups'], getBillingGroups, {
    onError,
  });

  const { mutate: archive } = useMutation(api.billingGroups.delete, {
    onSuccess: () => {
      snackbar.success('Billing group archived');
    },
    onError,
  });

  return { isLoading, billingGroups: data ?? [], archive };
};

export default useBillingGroups;
