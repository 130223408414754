import LocationWizardFacilityForm from 'routes/settings/locations/LocationWizard/LocationWizardFacilityForm';
import LocationWizardModal from 'routes/settings/locations/LocationWizard/LocationWizardModal';
import LocationWizardParkingForm from 'routes/settings/locations/LocationWizard/LocationWizardParkingForm';
import LocationWizardPaymentForm from 'routes/settings/locations/LocationWizard/LocationWizardPaymentForm';
import React from 'react';
import { WizardForm } from '@medely/web-components/forms';
import useLocationUpsert from 'routes/settings/locations/LocationWizard/useLocationUpsert';
import useLocationValues from 'routes/settings/locations/LocationWizard/useLocationValues';
import { useQueryClient } from '@tanstack/react-query';
import { StripeForm } from '@medely/web-components/contexts';

const OnboardingWizard = (): React.ReactElement => {
  const initialValues = useLocationValues();
  const createLocation = useLocationUpsert();
  const queryClient = useQueryClient();
  const handleSubmit = async (values: any) => {
    await createLocation(values);
    queryClient.invalidateQueries(['currentUser']);
  };
  return (
    <StripeForm>
      <WizardForm initialValues={initialValues} onSubmit={handleSubmit}>
        <WizardForm.Step>
          {(props) => (
            <LocationWizardModal title="Welcome to Medely" open canGoBack={false} {...props}>
              <LocationWizardFacilityForm {...props} />
            </LocationWizardModal>
          )}
        </WizardForm.Step>
        <WizardForm.Step>
          {(props) => (
            <LocationWizardModal title="Facility Parking" open {...props}>
              <LocationWizardParkingForm {...props} />
            </LocationWizardModal>
          )}
        </WizardForm.Step>
        <WizardForm.Step>
          {(props) => (
            <LocationWizardModal
              title="Payment Method"
              submitForm="payment-form"
              nextLabel="Save"
              open
              {...props}
            >
              <LocationWizardPaymentForm {...props} />
            </LocationWizardModal>
          )}
        </WizardForm.Step>
      </WizardForm>
    </StripeForm>
  );
};

export default OnboardingWizard;
