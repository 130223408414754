import { Box, Grid, IconButton, Typography } from '@medely/web-components';
import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import { useHistory, useParams } from 'react-router';

const TopBarShiftDetails = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  return (
    <Box py={2} px={[1, 0]}>
      <Grid container justifyContent="flex-start">
        <Grid item justifyContent="flex-start" alignItems="center" display="flex">
          <IconButton onClick={() => history.goBack()}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6">{`Shift ID ${id}`}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopBarShiftDetails;
