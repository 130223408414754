import useBillingGroups from './useBillingGroups';
import { useRouteMatch } from 'react-router-dom';

const useShowPastDueReminders = () => {
  const isBilling = useRouteMatch('/billing');
  const { billingGroups } = useBillingGroups();
  return {
    showFirstReminder:
      !isBilling && billingGroups.some((billingGroup) => billingGroup.has_past_due),
  };
};

export default useShowPastDueReminders;
