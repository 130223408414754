import { Job } from 'graphql/generated/graphql';
import { Moment } from 'moment';
import { axios } from 'utils/networkRequests';

export type JobPayload = {
  assignment_id?: number;
  charge_base_hourly_rate_cents?: number;
  clinical_contact_account_id?: number;
  covid_care?: boolean;
  detail?: string;
  ends_date?: string;
  ends_time?: string;
  job_type?: string;
  parking_reimbursement_cents?: number;
  patient_population?: string;
  release_to_platform?: number;
  reserved_for_favorites_only?: boolean;
  scrubs_provided?: boolean;
  starts_date?: string;
  starts_time?: string;
  marketplace?: boolean;
  professional_id?: number;
  favorite_ids?: number[];
  override_rates?: boolean;
  position_ids?: number[];
  posting_field_contents?: unknown;
  specialty_ids?: number[];
  status?: string;
  surgery_type_ids?: number[];
  approved_clock_in?: string;
  approved_clock_out?: string;
  on_call_starts_time?: string;
  on_call_ends_time?: string;
  location_id?: number;
  release_audience?: string;
  release_to_marketplace_strategy?: string;
  bonus_one_time_cents?: number;
};

export const jobs = {
  create: ({
    job,
    locationId,
  }: {
    job: { job: Partial<Job>; job_count: number };
    locationId: number;
  }) => axios.post(`/v3/facility/locations/${locationId}/jobs`, job),
  createMultiple: ({ jobs, locationId }: { jobs: JobPayload[]; locationId: number }) =>
    axios.post(`/v3/facility/locations/${locationId}/jobs`, { jobs }),
  update: ({
    params,
    jobId,
    locationId,
  }: {
    params: { job: Partial<Job> };
    jobId: number;
    locationId: number;
  }) => axios.put(`/v3/facility/locations/${locationId}/jobs/${jobId}`, params),
  actions: {
    addClockIn: (clockInAt: Moment, jobId: number) =>
      axios.post(`/v3/facility/jobs/${jobId}/clock_in`, {
        job: {
          approved_clock_in: clockInAt.format(),
        },
      }),
    updateShiftTimes: (values: any, jobId: number) =>
      axios.put(`/v3/facility/jobs/${jobId}/times`, values),
  },
};
