import { Box, SideNavButton } from '@medely/web-components';
import React from 'react';
import { useHistory, useRouteMatch, matchPath } from 'react-router-dom';
import useDevice from 'hooks/useDevice';
import useNavbarDesktop from 'hooks/useNavbarDesktop';
import {
  BriefcaseMedical,
  Calendar,
  CircleDollar,
  Clock,
  Gear,
  MedelyIcon,
  User,
  ClipboardCheck,
} from '@medely/web-components/icons';
import { DashboardOutlined } from '@mui/icons-material';
import { BsGraphUpArrow } from 'react-icons/bs';
import useNeedsReviewJobCount from 'hooks/useNeedsReviewJobCount';
import { useLocationContext } from 'context/locationContext';
import { useApprovalJobs } from 'hooks/useApprovalJobs';
import { gateNames } from 'constants/statsig';
import useGate from 'hooks/useGate';

export const hiddenSideBarPaths = [
  '/billing/invoices/:id/:table',
  '/billing/payments/:id',
  '/billing/credit_memos/:id',
  '/billing/debit_memos/:id/:table',
  '/shifts/new',
  '/shifts/:id/edit',
  '/shifts/:id',
  '/workforce/:id(\\d+)',
  '/workforce/:id(\\d+)/experience',
  '/workforce/:id(\\d+)/credentials',
  '/workforce/:id(\\d+)/skills',
  '/workforce/:id(\\d+)/shifts',
  '/assignments/new',
  '/assignments/shifts/new',
  '/assignments/:id/applications/:application_id/offer/:action',
  '/assignments/:id/edit',
  '/assignments/:id/extension/new',
  '/templates/:id/edit',
  '/templates/manager',
  '/templates/new',
  '/thank-you',
];

const iconLookup: Record<string, any> = {
  Dashboard: <DashboardOutlined />,
  Scheduler: <Calendar />,
  Assignments: <BriefcaseMedical />,
  Approvals: <ClipboardCheck />,
  'Time Tracking': <Clock />,
  Workforce: <User />,
  Billing: <CircleDollar />,
  Analytics: <BsGraphUpArrow />,
  Settings: <Gear />,
};

type Props = {
  isImpersonateBannerVisible: boolean;
};

const SideBar = ({ isImpersonateBannerVisible }: Props): React.ReactElement | null => {
  const { value: isAssignmentUpdateGateOn } = useGate(gateNames.assignmentsUpdatePhase1);
  const gatedRoutes = [
    {
      path: '/assignments/:id/applications/:status',
      isOn: isAssignmentUpdateGateOn,
    },
  ];

  const hiddenGatedRoutes = gatedRoutes.flatMap(({ path, isOn }) => (isOn ? [path] : []));

  const hideSideBar = [...hiddenSideBarPaths, ...hiddenGatedRoutes].some((path) => {
    return useRouteMatch(path);
  });
  const navRoutes = useNavbarDesktop();
  const isDesktop = useDevice() === 'desktop';
  const history = useHistory();
  const { location } = useLocationContext();
  const { jobs: jobsAwaitingApproval } = useApprovalJobs();
  const { count: needsReviewJobCount } = useNeedsReviewJobCount(location);

  const { value: featureDashboard } = useGate(gateNames.featureDashboard);

  if (!isDesktop || hideSideBar) {
    return null;
  }

  return (
    <Box
      position="fixed"
      top={isImpersonateBannerVisible ? '42px' : 0}
      left={0}
      width="88px"
      height="100vh"
      id="sidebar"
      data-testid="sidebar"
      zIndex={50}
      sx={{ backgroundColor: 'background.default' }}
      display="flex"
      flexDirection="column"
    >
      <Box
        width="100%"
        height="64px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        my="8px"
        data-testid="sidebar-medely-icon"
      >
        <MedelyIcon />
      </Box>

      <Box display="flex" flexDirection="column" mt={3} gap={2}>
        {navRoutes.map((r) => {
          let badgeContent = undefined;
          if (r.name === 'Time Tracking' && needsReviewJobCount) {
            badgeContent = needsReviewJobCount;
          }
          if (r.name === 'Approvals' && jobsAwaitingApproval.length > 0 && !featureDashboard) {
            badgeContent = jobsAwaitingApproval.length;
          }

          const isSelected = !!matchPath(window.location.pathname, r.path);

          return (
            <Box key={r.name} data-testid={`nav-button-${r.name}`}>
              <SideNavButton
                key={r.name}
                isSelected={isSelected}
                onClick={() => history.push(r.path)}
                label={r.name}
                icon={iconLookup[r.name]}
                badgeContent={badgeContent}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SideBar;
