import useCurrentUser from 'hooks/useCurrentUser';

const useLandingUrl = () => {
  const { currentUser } = useCurrentUser();
  const roles = currentUser?.permission_groups?.map((p) => p.role) ?? [];

  if (roles.includes('billing_manager')) {
    return '/billing';
  }
  if (roles.includes('professional_manager')) {
    return '/workforce';
  }
  return '/scheduler';
};

export default useLandingUrl;
