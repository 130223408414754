import { Box, Button, CircularProgress, Dialog } from '@medely/web-components';
import BillingGroupForm from './BillingGroupForm';
import { Form } from 'react-final-form';
import { BillingGroup } from 'graphql/generated/graphql';
import React from 'react';
import useBillingUpsert, { BillingType } from './useBillingUpsert';
import useBillingValues from './useBillingValues';
import useDevice from 'hooks/useDevice';
import { useStripeCard } from '@medely/web-components/hooks';
import { NewBillingGroupForm } from './NewBillingGroupForm';
import { StripePaymentMethodType } from 'routes/billing/types';
import { useSetupIntent } from './useSetupIntent';
import { Token } from '@stripe/stripe-js';

type BillingGroupModalProps = {
  open?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onUpsert?: (id: number) => void;
  billingGroup?: BillingGroup;
  location_id?: number;
  handleNextButton?: () => void;
  handleBackButton?: () => void;
  title?: string;
  children?: React.ReactNode;
  showNameField?: boolean;
  newBgFormProps?: {
    isEdit: boolean;
    stripePaymentMethod: StripePaymentMethodType | null;
    setStripePaymentMethod: (paymentMethod: StripePaymentMethodType | null) => void;
  };
};

type BillingGroupModalFormFields = {
  id: number;
  name: string;
  card_number: string;
  expiration_date: string;
  cvc: string;
  payment_method?: string;
};

const BillingGroupModal = ({
  billingGroup,
  location_id,
  open = false,
  isLoading = false,
  onClose,
  onUpsert,
  handleNextButton,
  handleBackButton,
  title,
  children,
  showNameField = true,
  newBgFormProps,
}: BillingGroupModalProps): React.ReactElement => {
  const { authorize, error } = useStripeCard();
  const isMobile = useDevice() === 'mobile';
  const initialValues = useBillingValues(billingGroup);
  const upsert = useBillingUpsert();
  const createSetupIntent = useSetupIntent();

  const handleSubmit = async (formValues: BillingGroupModalFormFields) => {
    // Do not automatically set billing to standard_billing if the billing group is already set to something else.
    const isCreate = !billingGroup;
    const newBillingValue = isCreate ? { billing: 'standard_billing' as BillingType } : {};
    // exclude payment_method from values, because we do not want to submit it.
    const { payment_method: _, ...values } = formValues;

    const paymentMethodType = newBgFormProps?.stripePaymentMethod;
    let stripeSetupIntentId: string | undefined;
    let legacyToken: Token | undefined | null;
    if (!!paymentMethodType) {
      stripeSetupIntentId = await createSetupIntent({ id: values.id, paymentMethodType });
    } else {
      legacyToken = await authorize();
    }
    if (legacyToken?.id || stripeSetupIntentId) {
      const relevantToken = stripeSetupIntentId
        ? { setup_intent_id: stripeSetupIntentId }
        : { stripe_token: legacyToken?.id };
      await upsert(
        {
          id: values.id,
          location_id: !!location_id ? location_id : undefined,
          name: values.name,
          ...relevantToken,
          ...newBillingValue,
        },
        {
          onSuccess: (response) => {
            onClose?.();
            onUpsert?.(response.data.payload.id);
            handleNextButton?.();
          },
        },
      );
    } else if (billingGroup?.billing !== 'standard_billing') {
      await upsert(
        { ...values },
        {
          onSuccess: (response) => {
            onClose?.();
            onUpsert?.(response.data.payload.id);
            handleNextButton?.();
          },
        },
      );
    }
    return;
  };
  const handleClose = () => {
    handleNextButton?.();
    onClose?.();
  };

  const handleBack = () => {
    handleBackButton?.();
    onClose?.();
  };
  const modalTitle = title ? title : initialValues.id ? 'Edit Billing Group' : 'New Billing Group';

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {({ form, submitting, handleSubmit }) => (
        <Dialog
          open={open}
          onClose={() => {
            onClose?.();
            form.reset();
          }}
          title={modalTitle}
          fullWidth={!isMobile}
          fullScreen={isMobile}
          content={
            isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                {children}
                {!!newBgFormProps ? (
                  <NewBillingGroupForm {...newBgFormProps} billingGroup={billingGroup} />
                ) : (
                  <BillingGroupForm
                    error={error}
                    billingGroup={billingGroup}
                    showNameField={showNameField}
                  />
                )}
              </>
            )
          }
          actions={
            <>
              {!!handleBackButton && (
                <Button
                  color="primary"
                  variant="text"
                  onClick={handleBack}
                  data-testid="back_button"
                >
                  Back
                </Button>
              )}
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  handleClose();
                  form.reset();
                }}
                data-testid="cancel_button"
              >
                {!!handleNextButton ? 'Add Later' : 'Close'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={submitting}
                loading={submitting}
                type="submit"
                onClick={handleSubmit}
              >
                {!!handleNextButton ? 'Next' : 'Save'}
              </Button>
            </>
          }
        />
      )}
    </Form>
  );
};

export default BillingGroupModal;
