import { useElements, useStripe } from '@stripe/react-stripe-js';
import { axios } from 'utils/networkRequests';
import { StripePaymentMethodType } from 'routes/billing/types';
import useSnackbar from 'hooks/useSnackbar';
import useCurrentUser from 'hooks/useCurrentUser';

type SetupIntentValues = {
  id: number;
  paymentMethodType: StripePaymentMethodType;
};

const createStripeSetupIntent = async ({ id, paymentMethodType }: SetupIntentValues) => {
  const { data } = await axios
    .post(`/v3/facility/billing_groups/${id}/setup_intent`, {
      billing_group: {
        payment_method_type: paymentMethodType,
      },
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message || err.message);
    });
  return data.client_secret;
};

export const useSetupIntent = () => {
  const snackbar = useSnackbar();
  const elements = useElements();
  const stripe = useStripe();
  const { currentUser } = useCurrentUser();

  const createSetupIntent = async ({ id, paymentMethodType }: SetupIntentValues) => {
    try {
      if (!elements || !stripe) {
        throw new Error('Stripe Elements not found');
      }

      const { error: elementsError } = await elements?.submit();
      if (elementsError) {
        throw new Error(elementsError.message);
      }
      const clientSecret = await createStripeSetupIntent({
        id,
        paymentMethodType,
      });
      const setupConfirmation = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: currentUser?.name,
              email: currentUser?.email,
              address: {
                country: 'US',
              },
            },
          },
        },
        redirect: 'if_required',
      });
      return setupConfirmation.setupIntent?.id;
    } catch (error) {
      snackbar.error(error);
    }
  };

  return createSetupIntent;
};
