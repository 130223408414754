import { Box, Button, List, ListItem, Typography } from '@medely/web-components';
import { AssignmentRequest } from 'graphql/generated/graphql';
import React from 'react';
import { UserGroupSolid } from '@medely/web-components/icons';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';

interface INewApplicantsProps {
  assignmentRequests: AssignmentRequest[];
  handleClose: () => void;
}

const NewApplicants = ({
  assignmentRequests,
  handleClose,
}: INewApplicantsProps): React.ReactElement => {
  const history = useHistory();
  return (
    <Box mb={6} mx={3} overflow="hidden">
      <Box display="flex" alignItems="center" sx={{ bgcolor: 'grey.200', px: 2, py: 1 }}>
        <UserGroupSolid color="primary" />
        &nbsp;&nbsp;
        <Typography variant="body2" weight="bold">
          New Applicants
        </Typography>
      </Box>
      <List>
        {assignmentRequests.map((a) => (
          <ListItem key={a.id}>
            <Box>
              <Typography weight="medium">
                {a.assignment.positions.map((p) => p.display_name).join(', ')}
              </Typography>
              <Typography variant="body2">Assignment ID: {a.assignment_id}</Typography>
              <Typography variant="body2">
                Starts in {moment(a.assignment.starts_date).diff(moment(), 'day')} days
              </Typography>
              <Button
                variant="link"
                onClick={() => {
                  handleClose();
                  history.push(`/assignments/${a.assignment_id}/applications`);
                }}
              >
                Review Applicants
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default NewApplicants;
