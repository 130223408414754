import { Role } from '@medely/types';
import useCurrentUser from 'hooks/useCurrentUser';
import { useMemo } from 'react';

const usePermissions = (rules: Array<Role>): boolean => {
  const { currentUser } = useCurrentUser();
  return useMemo(() => {
    const userRoles =
      currentUser?.permission_groups?.map((permission_group) => permission_group.role) ?? [];
    return rules.some((rule) => userRoles.includes(rule));
  }, [currentUser?.permission_groups, rules]);
};

export default usePermissions;
