import { ProKind } from '@medely/types';
import { Account, EmergencyContact } from 'graphql/generated/graphql';
import { axios } from 'utils/networkRequests';

export type InviteProfessionalPayload = {
  professional: {
    kinds: ProKind[];
    position_ids: number[];
    specialty_ids?: number[];
    account_attributes: {
      location_ids: number[];
      first_name: Account['first_name'];
      last_name: Account['last_name'];
      email: Account['email'];
      primary_location_id?: number;
    };
    professional_saas_detail_attributes: {
      id?: number;
      hourly_rate_cents?: number;
      max_weekly_hours: number;
      shift_types: string[];
      employment_type: string;
      payout_kind: string;
    };
  };
};

export type CreateProfessionalPayload = {
  professional: InviteProfessionalPayload['professional'] & {
    account_attributes: InviteProfessionalPayload['professional']['account_attributes'] & {
      phone_number: Account['phone_number'];
    };
    emergency_contact_attributes?: EmergencyContact;
  };
};

export type UpdateProfessionalPayload = {
  professional: CreateProfessionalPayload['professional'] & {
    id: number;
  };
};

export type OffboardProfessionalPayload = {
  professional_saas_detail: {
    offboarded_reason: string;
  };
};

export const professionals = {
  actions: {
    invite: (payload: InviteProfessionalPayload) =>
      axios.post(`/v3/facility/professionals/invite`, payload),
    create: (payload: CreateProfessionalPayload) =>
      axios.post('/v3/facility/professionals', payload),
    update: ({ professional: { id, ...payload } }: UpdateProfessionalPayload) =>
      axios.put(`/v3/facility/professionals/${id}`, { professional: payload }),
    offboard: (id: number, payload: OffboardProfessionalPayload) =>
      axios.put(`/v3/facility/professionals/${id}/offboard`, payload),
    reOnboard: (id: number) => axios.put(`/v3/facility/professionals/${id}/reonboard`, {}),
  },
};
