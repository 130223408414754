import { Grid, Typography } from '@medely/web-components';
import { FormRenderProps } from 'react-final-form';
import React from 'react';
import { useStripeCard } from '@medely/web-components/hooks';
import { CardNumberField, ExpirationDateField, CVCField } from '@medely/web-components/forms';
import useCurrentUser from 'hooks/useCurrentUser';

const LocationWizardPaymentForm = ({ form, handleSubmit }: FormRenderProps): React.ReactElement => {
  const { authorize, error } = useStripeCard();
  const { currentUser } = useCurrentUser();

  const hasBillingGroup = currentUser?.company?.billing_groups?.length > 0;

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (hasBillingGroup) {
      form.change('primary_billing_group_id', currentUser.company.billing_groups[0].id);
      handleSubmit(event);
    } else {
      const token = await authorize();
      if (token?.id) {
        form.change('stripe_token', token?.id);
        handleSubmit(event);
      }
    }
  };

  return (
    <form id="payment-form" onSubmit={handleFormSubmit}>
      {hasBillingGroup ? (
        <Typography>You are set up with invoice billing. No further action is required.</Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              To get started and post your first shift please enter a payment method below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CardNumberField name="credit_card_number" label="Credit Card Number" />
          </Grid>
          <Grid item xs={12} md={6}>
            <ExpirationDateField name="expiration_date" />
          </Grid>
          <Grid item xs={12} md={6}>
            <CVCField name="cvc" />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </form>
  );
};

export default LocationWizardPaymentForm;
