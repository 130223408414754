import { gateNames } from 'constants/statsig';
import useCurrentUser from './useCurrentUser';
import useGate from './useGate';

const useShowCompanyOnboarding = () => {
  const { currentUser, isLoading } = useCurrentUser();
  const { value: isFacilityOnboarding60OnboardingOn } = useGate(
    gateNames.facilityOnboarding60Onboarding,
  );
  const companyIsOnboarded = isFacilityOnboarding60OnboardingOn
    ? !!currentUser?.company?.has_onboarded_location &&
      !!currentUser?.company?.billing_groups?.length &&
      !!currentUser?.company?.has_billing_manager
    : !!currentUser?.company?.locations?.length;
  return !isLoading && currentUser?.company?.verified && !companyIsOnboarded;
};

export default useShowCompanyOnboarding;
