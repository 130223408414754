/* eslint-disable deprecation/deprecation */
import { MedelyDateTime } from '@medely/date-time-tools';
import type { ResumeItem } from '@medely/types';
import { InfoBadgeColor } from '@medely/ui-kit/web';
import { facilityLightTheme as theme } from '@medely/web-components/themes';
import { centsToCurrency } from 'core/utils/currency';
import {
  JobStatus,
  type Account,
  type ApprovalStatus,
  type Assignment,
  type AssignmentRequest,
  type BillingGroup,
  type BillingGroupCreditLimitCalculation,
  type Invoice,
  type Job,
  type JobProfile,
  type Location,
  type Professional,
  type RevenueAdjustment,
} from 'graphql/generated/graphql';
import { Optional } from 'graphql/utils';
import capitalize from 'lodash/capitalize';
import moment, { MomentInput, tz } from 'moment-timezone';
import getExpectedHoursPerShift from 'routes/assignments/utils/getExpectedHoursPerShift';
import titleize from 'utils/titleize';
import { isLate, isPastClockInTime, isPastEndTime } from './schedulerHelpers';
import isNil from 'lodash/isNil';
import replace from 'lodash/replace';
import { formatHours } from './formatHours';

const PACIFIC_TIMEZONE = 'America/Los_Angeles';

// type ApprovalStatus = 'pending' | 'approved' | 'rejected' | 'expired';

export const dateTime = {
  date: (
    date: Date | string | undefined,
    { tz, format = 'MM/DD/YY' }: { tz?: string; format?: string } = {},
  ) => (date ? formatDateTime(date, format, tz) : ''),
  time: (date: string, tz?: string) => formatDateTime(date, 'h:mma', tz),
  custom: (date: string, format: string, tz?: string) => formatDateTime(date, format, tz),
};

function jobPatientPopulation(job: Partial<Job>) {
  return titleize(job.patient_population || '–');
}

function jobSpecialties(job: Partial<Job>) {
  return job.specialties?.map((s) => s.label).join(', ') || '–';
}

function jobProcedures(job: Partial<Job>) {
  return job.surgery_types?.map((s) => s.display_name).join(', ') || '–';
}

function jobDate(job: Job) {
  const date = job.starts_date || job.starts_time;
  if (!date) {
    return null;
  }
  return dateTime.custom(date, 'ddd, MM/DD/YY', job.location?.timezone_lookup);
}

/**
 * @deprecated use job.timeRange
 */
function jobTime({
  job: { current_starts_time, current_ends_time, location },
  format = 'h:mm A',
  includeTotalHours = true,
  timezone,
}: {
  job: Pick<Job, 'current_ends_time' | 'current_starts_time'> & {
    location: Pick<Location, 'timezone_lookup'>;
  };
  format?: string;
  includeTotalHours?: boolean;
  timezone?: string;
}): string {
  const timezoneLookup = timezone || location?.timezone_lookup || tz.guess();
  const start = tz(current_starts_time, timezoneLookup);
  const end = tz(current_ends_time, timezoneLookup);
  const hours = end.diff(start, 'hours', true).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return `${start.format(format)} - ${end.format(format)} ${
    includeTotalHours ? ` | ${hours} hrs` : ''
  }`;
}

function formatDateTime(dateTime: MomentInput, format: string, timezone?: string): string {
  if (!dateTime) {
    return '';
  }
  if (timezone) {
    return tz(dateTime, timezone).format(format);
  }
  return moment.parseZone(dateTime).format(format);
}

const clockDiff = (scheduled: Date | string, actual: Date | string): string => {
  const totalMinutes = moment(actual).diff(moment(scheduled), 'minutes');
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours ? ` ${hours} h` : ''} ${minutes ? ` ${minutes} min` : ''}`;
};

function displayCreditLimitWarning(
  billingGroup: Pick<BillingGroup, 'billing'> | undefined,
  billingGroupCreditCalculations:
    | Pick<BillingGroupCreditLimitCalculation, 'remaining_credit_cents'>
    | undefined,
  isSaas: boolean,
) {
  if (!billingGroup) {
    return false;
  }
  const billing = billingGroup?.billing;
  const remaining_credit_cents = billingGroupCreditCalculations?.remaining_credit_cents ?? 0;
  return !isSaas && billing !== 'standard_billing' && remaining_credit_cents < 0;
}

const mapPatientPopulationToString: {
  [P in Assignment['patient_population']]: string;
} = {
  adults: 'Adults',
  pediatrics: 'Peds',
  pediatrics_adults: 'Adults & Peds',
};

function patientPopulation(value: Assignment['patient_population']) {
  return mapPatientPopulationToString[value];
}

function scrubs(value: Assignment['scrubs_provided']) {
  return value ? 'Scrubs provided' : 'Bring your own scrubs';
}

type KindProfessional = Pick<Professional, 'kind'>;

// TD: This needs an enum in the schema to properly restrict options
const mapKindToString: { [K in KindProfessional['kind']]: string } = {
  lvn: 'LVN',
  registered_nurse: 'Registered Nurse',
  allied: 'Allied',
  imaging_services: 'Imaging Services',
};
function kind(value: Professional['kind'] | Professional['kinds']): string {
  if (Array.isArray(value)) {
    return value.map((kind: string) => mapKindToString[kind]).join(', ');
  }
  return mapKindToString[value];
}

function assignmentRequestStage(ar: AssignmentRequest): string {
  if (ar.status === 'offer_sent') {
    return 'Offer Sent';
  }
  if (!ar.phone_screening_request) {
    return 'Applied';
  }
  switch (ar.phone_screening_request.status) {
    case 'scheduled':
      return 'Applied';
    case 'completed':
      return 'Completed Interview ';
    case 'no_show':
      return 'Missed Interview';
    case 'pending':
      return 'Applied';
    default:
      return 'error';
  }
}

function workHistoryDates(
  wh: Pick<ResumeItem, 'start_date' | 'end_date' | 'currently_work_here'>,
  options = {
    format: 'MMM YYYY',
  },
): string {
  const start = dateTime.custom(wh.start_date, options.format);
  if (wh.currently_work_here) {
    return `${start} - Current`;
  }
  return `${start} - ${dateTime.custom(wh.end_date, options.format)}`;
}

function parking(
  location: Pick<
    Location,
    | 'parking_free'
    | 'parking_reimbursed'
    | 'parking_validated'
    | 'parking_cost_cents'
    | 'parking_reimbursement_cents'
  >,
): string {
  if (location.parking_free) {
    return 'Free Parking';
  }
  if (location.parking_reimbursed) {
    return `Reimbursed - ${centsToCurrency(location.parking_reimbursement_cents || 0)}`;
  }
  if (location.parking_validated) {
    return 'Validated';
  }
  return `Not Reimbursed - ${centsToCurrency(location.parking_cost_cents || 0)}`;
}

const bookedOnCallCallBack = (
  shift_type: Optional<string>,
  current_ends_time: Date | string | undefined,
): boolean =>
  !!shift_type &&
  !!current_ends_time &&
  ['on_call', 'call_back'].includes(shift_type) &&
  moment(current_ends_time).isAfter(moment());

const isAwaitingApproval = (
  status: Optional<JobStatus | string>,
  approval_status: Optional<ApprovalStatus | string>,
): boolean => status === 'draft' && approval_status === 'pending';

const isRejected = (
  status: Optional<JobStatus | string>,
  approval_status: Optional<ApprovalStatus | string>,
): boolean =>
  status === 'draft' && (approval_status === 'rejected' || approval_status === 'expired');

// 'approved' approval_status defaults back to job status, thus is excluded here.
const isInApprovalState = (
  status: Optional<JobStatus | string>,
  approval_status: Optional<ApprovalStatus>,
): boolean => isAwaitingApproval(status, approval_status) || isRejected(status, approval_status);

export type jobColorPalette = {
  textColor: string;
  bgColor: string;
  bgStyle: string;
  borderColor: string;
  iconColor: string;
};

function jobOpacity({
  approval_status,
  current_ends_time,
  shift_type,
  starts_time,
  status,
}: Pick<
  Job,
  'approval_status' | 'current_ends_time' | 'shift_type' | 'starts_time' | 'status'
>): number {
  switch (status) {
    case JobStatus.Completed:
    case JobStatus.Canceled:
    case JobStatus.LateCancellation:
    case JobStatus.CanceledWithFee:
    case JobStatus.Unfilled:
    case JobStatus.MissedShift:
    case JobStatus.NoShow:
    case JobStatus.Unavailable:
    case JobStatus.Disputed:
      return 0.5;
    case JobStatus.ClockedOut:
      return bookedOnCallCallBack(shift_type, current_ends_time) ? 1 : 0.5;
    case JobStatus.Booked:
      if (isPastClockInTime(starts_time)) {
        return 0.8;
      }
      return 1;

    case JobStatus.HeldForDisputeReview:
    case JobStatus.ClockedIn:
      if (isPastEndTime(current_ends_time)) {
        return 0.8;
      }
      return 1;
    case JobStatus.Draft:
      if (isRejected(status, approval_status)) {
        return 0.5;
      }
      return 1;
    case JobStatus.Unavailable:
    default:
      return 1;
  }
}

function jobColor({
  status,
  approval_status,
  starts_time,
  current_ends_time,
}: Pick<Job, 'approval_status' | 'current_ends_time' | 'status' | 'starts_time'>): jobColorPalette {
  const { palette } = theme;
  switch (status) {
    case JobStatus.Booked:
      if (isPastClockInTime(starts_time)) {
        /**
         * Better late than ugly, better never late!
         * You're late, you're late, and you're ugly!
         * Better late than never, better never late!
         */
        return {
          textColor: palette.text.primary,
          bgColor: palette.warning.light,
          borderColor: 'none',
          bgStyle: 'solid',
          iconColor: palette.text.primary,
        };
      }
      return {
        textColor: palette.text.primary,
        bgColor: palette.primary.light,
        bgStyle: 'solid',
        borderColor: 'none',
        iconColor: palette.text.secondary,
      };

    case JobStatus.Completed:
    case JobStatus.ClockedOut:
      return {
        textColor: palette.text.primary,
        bgColor: palette.primary.light,
        bgStyle: 'solid',
        borderColor: 'none',
        iconColor: palette.text.secondary,
      };

    case JobStatus.ClockedIn:
      if (isPastEndTime(current_ends_time)) {
        return {
          textColor: palette.text.primary,
          bgColor: palette.warning.light,
          bgStyle: 'solid',
          borderColor: 'none',
          iconColor: palette.text.primary,
        };
      }
      return {
        textColor: palette.text.primary,
        bgColor: palette.primary.light,
        bgStyle: 'solid',
        borderColor: 'none',
        iconColor: palette.text.secondary,
      };

    case JobStatus.Draft:
      if (isAwaitingApproval(status, approval_status)) {
        return {
          textColor: palette.text.primary,
          bgColor: 'transparent',
          bgStyle: 'dashed',
          borderColor: palette.warning.dark,
          iconColor: palette.warning.dark,
        };
      } else if (isRejected(status, approval_status)) {
        return {
          textColor: palette.text.primary,
          bgColor: palette.grey[200],
          bgStyle: 'solid',
          borderColor: 'none',
          iconColor: 'none',
        };
      }
      return {
        textColor: palette.text.primary,
        bgColor: palette.grey[50],
        bgStyle: 'dashed',
        borderColor: palette.grey[300],
        iconColor: palette.grey[400],
      };

    case JobStatus.Canceled:
    case JobStatus.LateCancellation:
    case JobStatus.CanceledWithFee:
    case JobStatus.Unfilled:
    case JobStatus.Unavailable:
      return {
        textColor: palette.text.primary,
        bgColor: palette.grey[100],
        bgStyle: 'solid',
        borderColor: palette.grey[300], // TD: replace with palette.action.outline when available
        iconColor: 'none',
      };

    case JobStatus.MissedShift:
    case JobStatus.NoShow:
    case JobStatus.Disputed:
      return {
        textColor: palette.text.primary,
        bgColor: palette.warning.light,
        bgStyle: 'solid',
        borderColor: 'none',
        iconColor: palette.warning.main,
      };

    case JobStatus.Unavailable:
      return {
        textColor: palette.text.primary,
        bgColor: palette.grey[100],
        bgStyle: 'solid',
        borderColor: palette.grey[300], // TD: replace with palette.action.outline when available
        iconColor: 'none',
      };
    case JobStatus.HeldForDisputeReview:
      return {
        textColor: palette.error.main,
        bgColor: palette.error.light,
        bgStyle: 'solid',
        borderColor: 'none',
        iconColor: palette.error.main,
      };

    default:
      return {
        textColor: palette.text.primary,
        bgColor: palette.grey[100],
        bgStyle: 'solid',
        borderColor: 'none',
        iconColor: 'none',
      };
  }
}

const shiftCardBadgeColor = (job: Job): InfoBadgeColor => {
  switch (job.status) {
    case JobStatus.Booked:
      if (isPastClockInTime(job.starts_time)) {
        return 'warning';
      }
      return 'default';
    case JobStatus.Disputed:
    case JobStatus.HeldForDisputeReview:
      return 'warning';
    case JobStatus.Completed:
    case JobStatus.ClockedOut:
      return 'success';
    case JobStatus.ClockedIn:
      if (isPastEndTime(job.current_ends_time)) {
        return 'warning';
      }
      return 'info';
    case JobStatus.Draft:
      if (isAwaitingApproval(job.status, job.approval_status)) {
        return 'warning';
      }
      return 'default';
    default:
      return 'default';
  }
};

const jobStatus = (
  job: Pick<Job, 'status' | 'approval_status' | 'starts_time' | 'current_ends_time' | 'shift_type'>,
): string => {
  switch (job.status) {
    case JobStatus.Active:
      return 'Open';
    case JobStatus.Booked:
      if (isLate(job.starts_time)) {
        return 'Not clocked in';
      }
      return 'Booked';
    case JobStatus.ClockedOut:
      // Booked on call shifts are created as clocked out since the pro
      // may not get called back and may not clock in and clock out.
      // This is to ensure pro will get payed for on call regardless
      if (job.shift_type === 'on_call') {
        return bookedOnCallCallBack(job.shift_type, job.current_ends_time)
          ? 'Booked (On Call)'
          : 'Completed (On Call)';
      }
      return 'Completed';
    case JobStatus.Completed:
      if (job.shift_type === 'call_back') {
        return 'Completed (Callback)';
      } else if (job.shift_type === 'on_call') {
        return 'Completed (On Call)';
      }
      return 'Completed';
    case JobStatus.LateCancellation:
      return 'Late cancellation';
    case JobStatus.Canceled:
      return 'Canceled by Facility';
    case JobStatus.CanceledWithFee:
      return 'Canceled with fee';
    case JobStatus.MissedShift:
      return 'Missed shift';
    case JobStatus.Disputed:
      return 'Completed (Disputed)';
    case JobStatus.NoShow:
      return 'No show';
    case JobStatus.HeldForDisputeReview:
      return 'Needs Review';
    case JobStatus.ClockedIn:
      if (isPastEndTime(job.current_ends_time)) {
        return 'Click to extend time';
      }
      return 'Clocked In';
    case JobStatus.Draft:
      if (isAwaitingApproval(job.status, job.approval_status)) {
        return 'Awaiting approval';
      } else if (isRejected(job.status, job.approval_status)) {
        return 'Rejected';
      }
      return 'Draft';
    default:
      return titleize(job.status);
  }
};

const jobSchedulePanelDisplayStatus = (
  job: Pick<Job, 'status' | 'approval_status' | 'starts_time' | 'current_ends_time' | 'shift_type'>,
): string | null => {
  switch (job.status) {
    case JobStatus.Active:
      return 'Open';
    case JobStatus.Booked:
      if (isPastClockInTime(job.starts_time)) {
        return 'Not clocked in';
      }
      return '';
    case JobStatus.HeldForDisputeReview:
      return 'Needs review';
    case JobStatus.Completed:
    case JobStatus.ClockedOut:
      return 'Completed';
    case JobStatus.ClockedIn:
      if (isPastEndTime(job.current_ends_time)) {
        return 'Not clocked out';
      }
      return 'Clocked in';
    case JobStatus.Disputed:
      return 'Disputed';
    case JobStatus.Draft:
      if (isAwaitingApproval(job.status, job.approval_status)) {
        return 'Awaiting approval';
      }
      return '';
    default:
      return null;
  }
};

type JobDetailsDisplayStatusInput = Pick<Job, 'status' | 'approval_status'> & {
  professional?: Optional<
    Pick<Professional, 'kind' | 'nickname'> & {
      account?: Optional<Pick<Account, 'first_name' | 'last_name'>>;
    }
  >;
};

const jobDetailsDisplayStatus = ({
  professional,
  status,
  approval_status,
}: JobDetailsDisplayStatusInput): { title: string; subTitle?: string } => {
  switch (status) {
    case JobStatus.Active:
      return { title: 'Open shift' };
    case JobStatus.Draft:
      return { title: isRejected(status, approval_status) ? 'Rejected shift' : 'Draft shift' };
    case JobStatus.CanceledWithFee:
      return { title: 'Canceled with fee' };
    case JobStatus.Canceled:
      return { title: 'Canceled' };
    case JobStatus.LateCancellation:
      return { title: 'Canceled by professional' };
    case JobStatus.Unfilled:
      return { title: 'Unfilled' };
    case JobStatus.Disputed:
      return { title: 'Disputed' };
    case JobStatus.ClockedIn:
    case JobStatus.Completed:
    case JobStatus.ClockedOut:
    case JobStatus.NoShow:
    case JobStatus.MissedShift:
    case JobStatus.Booked:
    case JobStatus.HeldForDisputeReview:
      return {
        title: `${professional?.account?.first_name} ${professional?.account?.last_name}`,
        subTitle: professional?.kind ? titleize(professional?.kind) : '',
      };
    default:
      return { title: 'Other shift' };
  }
};

type JobBillRateInput = Pick<
  Job,
  | 'bonus_one_time_cents'
  | 'charge_base_hourly_rate_cents'
  | 'marketplace_bonus_one_time_cents'
  | 'marketplace_charge_base_hourly_rate_cents'
  | 'release_audience'
  | 'professional_id'
>;

const jobBillRate = (job: JobBillRateInput): string => {
  const {
    professional_id,
    charge_base_hourly_rate_cents,
    bonus_one_time_cents,
    marketplace_charge_base_hourly_rate_cents,
    marketplace_bonus_one_time_cents,
    release_audience,
  } = job;

  const shouldShowMarketplaceRate = !professional_id;
  const billRate = centsToCurrency(
    shouldShowMarketplaceRate
      ? marketplace_charge_base_hourly_rate_cents
      : charge_base_hourly_rate_cents,
  );
  const bonus = centsToCurrency(
    (shouldShowMarketplaceRate ? marketplace_bonus_one_time_cents : bonus_one_time_cents) || 0,
  );

  if (release_audience === 'saas') {
    return 'Internal Posting Only';
  }

  if (isNil(billRate)) {
    return null;
  }

  if (bonus !== '$0.00') {
    return `${billRate} /hr + ${bonus} bonus`;
  }

  return `${billRate} /hr`;
};

const onCallJobBillRate = (
  job: Pick<Job, 'charge_on_call_hourly_rate_cents'>,
): string | undefined => {
  const onCallBillRate = centsToCurrency(job.charge_on_call_hourly_rate_cents);
  return onCallBillRate ? `${onCallBillRate} /hr` : undefined;
};

type JobTimeRangeJobInput = Pick<Job, 'current_ends_time' | 'current_starts_time'> & {
  location?: Optional<Pick<Location, 'timezone_lookup'>>;
};

type JobTimeRangeOptionsInput = {
  showDuration?: boolean;
  showTimezoneStamp?: boolean;
};

const jobTimeRange = (job: JobTimeRangeJobInput, options?: JobTimeRangeOptionsInput): string => {
  const { current_ends_time, current_starts_time, location } = job;
  const showDuration = options?.showDuration ?? false;
  const showTimezoneStamp = options?.showTimezoneStamp ?? false;

  const endTimeFormat = showTimezoneStamp ? 'h:mma z' : 'h:mma';
  const timezoneLookup = location?.timezone_lookup || tz.guess();
  const displayStart = tz(current_starts_time, timezoneLookup).format('h:mma').slice(0, -1);
  const displayEnd = tz(current_ends_time, timezoneLookup)
    .format(endTimeFormat)
    .replace('am', 'a')
    .replace('pm', 'p');
  const totalMinutes = moment(current_ends_time).diff(moment(current_starts_time), 'minutes');
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${displayStart} - ${displayEnd}${
    showDuration ? ` (${hours} h${minutes ? ` ${minutes} min` : ''})` : ''
  }`;
};

type OriginalTimeRangeJobInput = Pick<Job, 'ends_time' | 'starts_time'> & {
  location?: Optional<Pick<Location, 'timezone_lookup'>>;
};

type OriginalTimeRangeOptions = {
  showDuration?: boolean;
};

const originalJobTimeRange = (
  job: OriginalTimeRangeJobInput,
  { showDuration = false }: OriginalTimeRangeOptions,
): string => {
  const { starts_time, ends_time, location } = job;

  const timezoneLookup = location?.timezone_lookup || tz.guess();
  const displayStart = tz(starts_time, timezoneLookup).format('h:mma').slice(0, -1);
  const displayEnd = tz(ends_time, timezoneLookup).format('h:mma').slice(0, -1);
  const totalMinutes = moment(ends_time).diff(moment(starts_time), 'minutes');
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${displayStart} - ${displayEnd}${
    showDuration ? ` (${hours} h${minutes ? ` ${minutes} min` : ''})` : ''
  }`;
};

const jobTotal = (chargeAmountCents?: number): string => {
  return chargeAmountCents ? `(${centsToCurrency(chargeAmountCents)} total)` : '';
};

const jobDurationAndTotal = (totalHours: number, chargeAmountCents?: number): string => {
  return `${formatHours(totalHours)} ${jobTotal(chargeAmountCents)}`;
};

export type JobClockInInput = Pick<Job, 'approved_clock_in'> & {
  location?: Optional<Pick<Location, 'timezone_lookup'>>;
};

const jobClockIn = ({ approved_clock_in, location }: JobClockInInput): string => {
  const timezoneLookup = location?.timezone_lookup || tz.guess();
  return tz(approved_clock_in, timezoneLookup).format('h:mma').slice(0, -1);
};

export type JobClockOutInput = Pick<Job, 'approved_clock_out'> & {
  location?: Optional<Pick<Location, 'timezone_lookup'>>;
};

const jobClockOut = ({ approved_clock_out, location }: JobClockOutInput): string => {
  const timezoneLookup = location?.timezone_lookup || tz.guess();
  return tz(approved_clock_out, timezoneLookup).format('h:mma').slice(0, -1);
};

type ApprovalReasons = {
  approval_violated_parameters?: Optional<Job['approval_violated_parameters']>;
  marketplace_job_profile?: Optional<Pick<JobProfile, 'charge_base_hourly_rate_cents'>>;
};

const jobApprovalsReason = ({
  approval_violated_parameters,
  marketplace_job_profile,
}: ApprovalReasons): string => {
  if (!approval_violated_parameters || !approval_violated_parameters.charge_rate_cents_exception) {
    return '';
  }
  const { charge_rate_cents_exception } = approval_violated_parameters;
  const { charge_base_hourly_rate_cents } = marketplace_job_profile || {};

  return `exceeds base rate by ${centsToCurrency(
    charge_base_hourly_rate_cents - charge_rate_cents_exception,
  )}`;
};

const schedulerPopoverJobStatus = (job: Job): string => {
  switch (job.shift_type) {
    case 'on_call':
      return `${jobStatus(job)} (On Call)`;
    case 'call_back':
      return `${jobStatus(job)} (Call Back)`;
    default:
      return jobStatus(job);
  }
};

type Formats = {
  time?: string;
  date?: string;
};

export const formats = (overrides: Formats = {}): any => {
  const formats = {
    time: 'h:mm A',
    date: 'MM/DD/YYYY',
    ...overrides,
  };

  const dateTime = (format: string) => (input: MomentInput) => moment(input).format(format);
  const dateTimeTz = (format: string) => (timezone: string) => (input: MomentInput) =>
    moment.tz(input, timezone).format(format);

  return {
    ampm: /a/i.test(formats.time),
    formats,
    display: {
      dateTime,
      dateTimeTz,
      time: dateTime(formats.time),
      timeTz: dateTimeTz(formats.time),
      date: dateTime(formats.date),
      dateTz: dateTimeTz(formats.date),
    },
  };
};
export const formatsTz = (timezone: string, overrides: Formats = {}): any => {
  const formatsData = formats(overrides);
  const { display } = formatsData;
  const displayTz = {
    dateTime: (format: string) => (input: MomentInput) =>
      display.dateTimeTz(format)(timezone)(input),
    time: display.timeTz(timezone),
    date: display.dateTz(timezone),
  };
  return {
    ...formatsData,
    display: displayTz,
  };
};

export const assignmentDates = ({ starts_date, ends_date }: Assignment): string => {
  const start = moment(starts_date);
  const end = moment(ends_date);
  const weeks = Math.floor((end.diff(start, 'days') + 1) / 7);
  return `${dateTime.date(starts_date)} - ${dateTime.date(ends_date)} (${weeks} wks)`;
};

export const locationHasValidBillingGroup = (location: Location): boolean => {
  return !!location?.billing_groups?.length;
};

export const assignmentShiftTimes = ({ starts_time, ends_time, locations }: Assignment): string => {
  const timezoneLookup = locations[0].timezone_lookup;
  const { display } = formatsTz(timezoneLookup);
  const startTime = display.time(starts_time);
  const endTime = display.time(ends_time);

  const hoursDiff = getExpectedHoursPerShift(starts_time, ends_time, timezoneLookup);
  const hours = new Intl.NumberFormat('en-us', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(hoursDiff);

  return `${startTime} - ${endTime} (${hours} hrs)`;
};

export const schedulerItemStaffName = (
  { professional, shift_type }: Partial<Job>,
  surpressOnCall = false,
): string | null => {
  const name = professional?.account?.name || null;
  if (surpressOnCall) {
    return name;
  }
  switch (shift_type) {
    case 'on_call':
      return 'On Call'; //`(On Call) ${name}`; //MED-4372
    case 'call_back':
      return 'Call Back'; //`(Call Back) ${name}`; //MED-4372
    default:
      return name;
  }
};

export const getErrorMessage = (error?: any): string => {
  let message = 'Sorry, something went wrong';
  if (error.response?.status === 401) {
    message = 'You do not have permission to perform this action';
  }
  if (error.response?.status < 500) {
    const railsMultipleErrors = error.response?.data?.failures
      ?.map((failure: any) => failure.errors?.join(', '))
      .join(', ');
    const railsSingleError = error.response?.data?.message;
    const gqlError = error.response?.errors?.map(({ message }: any) => message)?.join(', ');
    const jsError = error.message;
    message = railsMultipleErrors || railsSingleError || gqlError || jsError;
  }
  return message;
};

export const shiftType = (job: Job) => {
  if (job.shift_type === 'call_back') {
    return 'Callback';
  }
  return titleize(job.shift_type);
};

const formatToPacificTime = ({ time, showHour }: { time: string; showHour: boolean }) => {
  const format = showHour ? 'MM/dd/y h:mm a ZZZZ' : 'MM/dd/y';
  return new MedelyDateTime(time, { tz: PACIFIC_TIMEZONE }).format(format);
};

const invoiceDateRangeInPacificTime = ({
  invoice,
  showHour = false,
}: {
  invoice: Invoice;
  showHour?: boolean;
}): string => {
  const formattedStartDatetime = formatToPacificTime({ time: invoice.starts_time, showHour });
  const formattedEndDatetime = formatToPacificTime({ time: invoice.ends_time, showHour });
  return `${formattedStartDatetime} - ${formattedEndDatetime}`;
};

const invoiceStatusDate = ({ due_date, paid_date, status }: Invoice): string => {
  if (status === 'paid') {
    return paid_date ? formatToPacificTime({ time: paid_date, showHour: true }) : '';
  }
  return dateTime.date(due_date);
};

const adjustmentType = (adjustment: RevenueAdjustment) =>
  capitalize(adjustment.adjustment_type.split('_').join(' '));

const currencyPerHour = (cents: Optional<number>) => {
  const currency = centsToCurrency(cents);
  return currency ? `${currency}/hr` : '';
};

const humanReadableStatus = (status: string): string => capitalize(replace(status, '_', ' '));

export default {
  applications: {
    humanReadableStatus,
  },
  formats,
  formatsTz,
  assignmentDates,
  assignmentShiftTimes,
  currencyPerHour,
  parking,
  dateTime,
  patientPopulation,
  locationHasValidBillingGroup,
  displayCreditLimitWarning,
  scrubs,
  clockDiff,
  getErrorMessage,
  // credential: {
  // kind: credentialKindText,
  // When redoing credentials this should use @medely/web-components/icons
  // iconName: credentialKindIcon,
  // status: credentialStatus,
  //   expirationDate: renderExpirationDate,
  // },
  kind,
  assignmentRequestStage,
  workHistory: {
    dates: workHistoryDates,
  },
  invoice: {
    dateRangeInPacificTimeWithHour: ({ invoice }: { invoice: Invoice }) =>
      invoiceDateRangeInPacificTime({ invoice, showHour: true }),
    dateRangeInPacificTime: invoiceDateRangeInPacificTime,
    statusDate: invoiceStatusDate,
  },
  job: {
    date: jobDate,
    billRate: jobBillRate,
    onCallJobBillRate,
    time: jobTime,
    jobClockIn,
    jobClockOut,
    status: jobStatus,
    jobSchedulePanelDisplayStatus,
    timeRange: jobTimeRange,
    originalTimeRange: originalJobTimeRange,
    total: jobTotal,
    durationAndTotal: jobDurationAndTotal,
    schedulerPopoverJobStatus,
    schedulerItemStaffName,
    color: jobColor,
    shiftCardBadgeColor,
    opacity: jobOpacity,
    isLate,
    isPastClockInTime,
    isAwaitingApproval,
    isRejected,
    isInApprovalState,
    specialties: jobSpecialties,
    procedures: jobProcedures,
    patientPopulation: jobPatientPopulation,
    bookedOnCallCallBack,
    shiftType,
    jobDetailsDisplayStatus,
    approvalsReason: jobApprovalsReason,
  },
  adjustmentType,
};
