import React, { useState } from 'react';
import { BillingGroup } from 'graphql/generated/graphql';
import { Box, Checkbox, FormControlLabel, Typography } from '@medely/web-components';
import { TextField, useForm } from '@medely/web-components/forms';
import { required } from 'core/utils/validation';
import { StripePaymentField } from 'components/Payments/StripePaymentField';
import { PaymentMethodField, PaymentMethodOption } from 'components/Payments/PaymentMethodField';
import { StripePaymentMethodType } from 'routes/billing/types';
import { getFundingSources } from 'hooks/useFundingSources';

type NewBillingGroupFormProps = {
  billingGroup?: BillingGroup;
  isEdit: boolean;
  stripePaymentMethod: StripePaymentMethodType | null;
  setStripePaymentMethod: (paymentMethod: StripePaymentMethodType | null) => void;
};

const getIsPaymentMethodRequired = (isEdit: boolean, billingGroup: BillingGroup | undefined) => {
  if (isEdit) {
    return false;
  }
  return billingGroup ? billingGroup.billing === 'standard_billing' : true;
};

const getPaymentMethodHelpText = (
  billingGroup: BillingGroup | undefined,
  stripePaymentMethod: StripePaymentMethodType | null,
) => {
  if (!stripePaymentMethod) {
    return null;
  }

  const { savedCC, savedBankAccount } = getFundingSources(billingGroup);
  switch (stripePaymentMethod) {
    case 'card':
      return !!savedCC ? 'Saving this credit card will replace the previously saved card' : null;
    case 'us_bank_account':
      return !!savedBankAccount
        ? 'Saving this bank account will replace the previously saved account'
        : null;
    default:
      return null;
  }
};

export const NewBillingGroupForm = ({
  billingGroup,
  isEdit,
  stripePaymentMethod,
  setStripePaymentMethod,
}: NewBillingGroupFormProps): React.ReactElement => {
  const form = useForm();
  const isPaymentMethodRequired = getIsPaymentMethodRequired(isEdit, billingGroup);
  const [showPaymentFields, setShowPaymentFields] = useState(isPaymentMethodRequired);

  const paymentMethodHelpText = getPaymentMethodHelpText(billingGroup, stripePaymentMethod);
  const onPaymentMethodChange = (option: PaymentMethodOption | null) => {
    const optionValue = option?.value;
    if (!optionValue) {
      setStripePaymentMethod(null);
    }

    switch (optionValue) {
      case 'card':
      case 'us_bank_account':
        setStripePaymentMethod(optionValue);
        break;
      default:
        setStripePaymentMethod(null);
        break;
    }
  };

  return (
    <Box display="flex" flexDirection="column" pt={2} gap={3}>
      <TextField label="Billing Group Name" name="name" validate={required} />
      {!isPaymentMethodRequired && (
        <FormControlLabel
          control={
            <Checkbox
              value={showPaymentFields}
              onChange={(_e, checked) => {
                if (!checked) {
                  form.change('payment_method', null);
                  setStripePaymentMethod(null);
                }
                setShowPaymentFields(checked);
              }}
            />
          }
          label="Update payment method"
        />
      )}
      {showPaymentFields && (
        <>
          <Box>
            <PaymentMethodField showOnlyNewOptions={true} onChange={onPaymentMethodChange} />
            {paymentMethodHelpText && (
              <Typography variant="caption" color="error" mx="14px">
                {paymentMethodHelpText}
              </Typography>
            )}
          </Box>
          {!!stripePaymentMethod && <StripePaymentField isHostedBilling={false} />}
        </>
      )}
    </Box>
  );
};
