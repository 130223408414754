import { Box, Button, Divider, Drawer, NavLink, styled } from '@medely/web-components';
import { MedelyLogo, Menu } from '@medely/web-components/icons';
import React from 'react';
import TopBarLocationSelector from 'containers/TopBar/TopBarLocationSelector';
import useNavbarMobile from 'hooks/useNavbarMobile';
import useCurrentUser from 'hooks/useCurrentUser';
import useDialog from 'hooks/useDialog';
import { useAuthContext } from '@medely/ui-kit/web';
import { useHistory } from 'react-router';
import { useTheme } from '@medely/web-components/themes';
import TopBarBillingGroupSelector from '../TopBarBillingGroupSelector';

const TopBarMobileNavigationLink = styled(NavLink)({ py: 2, px: 0 });

const TopBarMobileNavigation = (): React.ReactElement => {
  const dialog = useDialog();
  const theme = useTheme();
  const history = useHistory();
  const mobileNavRoutes = useNavbarMobile();
  const { logout } = useAuthContext();
  const handleNavigation = (path: string) => {
    history.push(path);
    dialog.closeDialog();
  };

  const handleLogout = () => {
    dialog.closeDialog();
    logout();
  };

  const { currentUser } = useCurrentUser();
  return (
    <>
      <Button sx={{ mr: 1 }} onClick={dialog.openDialog}>
        <Menu />
      </Button>
      <Drawer open={dialog.open} onClose={dialog.closeDialog}>
        <Box sx={{ width: 320 }}>
          <Box
            p={3}
            pt={4}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            bgcolor={theme.palette.primary.main}
            minHeight={170}
          >
            <MedelyLogo color="white" width="170" height="56" />
            <TopBarLocationSelector color="white" />
            <TopBarBillingGroupSelector />
          </Box>
          <Box py={2} px={3}>
            {mobileNavRoutes
              .filter((route) =>
                route.permittedRoles
                  ? route.permittedRoles.some((roleName) =>
                      currentUser.permission_groups.map(({ role }) => role).includes(roleName),
                    )
                  : route,
              )
              .map((link) => (
                <TopBarMobileNavigationLink
                  key={link.name}
                  to={link.path}
                  onClick={() => handleNavigation(link.path)}
                >
                  {link.name}
                </TopBarMobileNavigationLink>
              ))}
          </Box>
          <Box px={2}>
            <Divider />
          </Box>
          <Box py={2} px={3}>
            <TopBarMobileNavigationLink to="/logout" onClick={handleLogout}>
              Log out
            </TopBarMobileNavigationLink>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default TopBarMobileNavigation;
