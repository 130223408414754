import { NotificationItem } from 'routes/account_settings/components/NotificationsForm/types';

export const notificationItems: NotificationItem[] = [
  {
    key: 'booked_job_confirmation',
    label: 'Booked Shifts',
    description: 'Shifts starting within 24 hours',
  },
  {
    key: 'unapproved_shifts_summary',
    label: 'Unapproved Shifts Summary',
    description: 'Daily summary of jobs that need to be reviewed and approved',
    permittedRoles: ['company_admin', 'facility_manager', 'scheduler'],
  },
  {
    key: 'pro_late_canceled_a_job',
    label: 'Canceled Per Diem Shifts',
    description: 'Shifts starting within 24 hours',
  },
  {
    key: 'pro_canceled_a_1099_assignment_job',
    label: 'Canceled Assignment Shifts',
    description: 'Shifts starting within 24 hours',
  },
  {
    key: 'pro_hasnt_clocked_in_for_a_shift',
    label: 'Missed Clock In',
    description: '30 minutes after start',
  },
  {
    key: 'pro_booked_an_assignment',
    label: 'Accepted Assignment offer',
    description: 'When an offer is accepted by a Professional',
  },
  {
    key: 'yesterdays_assignment_applications',
    label: 'Assignment Application Summary',
    description: "A summary of applications from yesterday that haven't been viewed",
  },
];
