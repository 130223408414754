import { Box, Button, List, ListItem, Typography } from '@medely/web-components';
import { CircleExclamation } from '@medely/web-components/icons';
import { Assignment } from 'graphql/generated/graphql';
import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';

interface IExpiringAssignmentsProps {
  assignments: Assignment[];
  handleClose: () => void;
}

const ExpiringAssignments = ({
  assignments,
  handleClose,
}: IExpiringAssignmentsProps): React.ReactElement => {
  const history = useHistory();
  return (
    <Box mb={6} mx={3} overflow="hidden">
      <Box display="flex" alignItems="center" sx={{ bgcolor: 'grey.200', px: 2, py: 1 }}>
        <CircleExclamation color="primary" />
        &nbsp;&nbsp;
        <Typography variant="body2" weight="bold">
          Expiring Assignments
        </Typography>
      </Box>
      <List>
        {assignments.map((a) => (
          <ListItem key={a.id}>
            <Box>
              <Typography weight="medium">
                {a.positions.map((p) => p.display_name).join(', ')}
              </Typography>
              <Typography variant="body2">Assignment ID: {a.id}</Typography>
              <Typography variant="body2">
                Ends in {moment(a.ends_date).diff(moment(), 'day')} days
              </Typography>
              <Button
                variant="link"
                onClick={() => {
                  handleClose();
                  history.push(`/assignments/${a.id}/extension/new`);
                }}
              >
                Extend Assignment
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ExpiringAssignments;
