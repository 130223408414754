import { Assignment, AssignmentRequest, PhoneScreeningRequest } from 'graphql/generated/graphql';
import { GET_EXPIRING_ASSIGNMENTS } from 'graphql/assignmentsQueries';
import { GET_NEW_ASSIGNMENT_REQUESTS } from 'graphql/assignmentRequestsQueries';
import { GET_UPCOMING_PHONE_SCREENINGS } from 'graphql/phoneScreeningRequestsQueries';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import moment from 'moment';
import useHandleError from './useHandleError';
import { useLocationContext } from 'context/locationContext';
import { type QueryFunctionContext, useQuery } from '@tanstack/react-query';

interface IUseNotificationsReturn {
  count: number;
  newApplications: AssignmentRequest[];
  newApplicantsCount: number;
  upcomingPhoneScreenings: PhoneScreeningRequest[];
  expiringAssignments: Assignment[];
  isLoading: boolean;
}

type FetchExpiringAssignmentsInput = { location_id: number };
type FetchExpiringAssignments = QueryFunctionContext<
  [_entity: unknown, input: FetchExpiringAssignmentsInput]
>;

const useNotifications = (): IUseNotificationsReturn => {
  const { location } = useLocationContext();
  const onError = useHandleError();
  const { request } = useGraphQLRequest();

  // newApplicants - assignment requests w viewed = false
  const fetchNewApplicants = async () => {
    const { assignmentRequests } = await request(GET_NEW_ASSIGNMENT_REQUESTS, {
      input: {
        search: {
          assignment_status: 'matched',
        },
        filter: {
          status: 'applied',
          viewed: false,
        },
      },
    });
    return assignmentRequests;
  };

  const { data: newApplications = [], isLoading: newApplicantsLoading } = useQuery(
    ['newApplicants'],
    fetchNewApplicants,
    { onError },
  );

  // upcoming phone screenings - confirmed phone screening time within 7 days (?) - needs some work on gql server still -
  // add location_id and confirmed_time_to searches
  const fetchUpcomingPhoneScreenings = async () => {
    const { phoneScreeningRequests } = await request(GET_UPCOMING_PHONE_SCREENINGS, {
      input: {
        search: {
          date_from: moment().format('YYYY-MM-DD'),
          date_to: moment().add(6, 'days').format('YYYY-MM-DD'),
        },
      },
    });
    return phoneScreeningRequests;
  };

  const { data: upcomingPhoneScreenings = [], isLoading: phoneScreeningsLoading } = useQuery(
    ['upcomingPhoneScreenings'],
    fetchUpcomingPhoneScreenings,
    { onError },
  );

  // expiring assignments - assignments in progress at any available location (?) that are expiring within 7 days (?)
  const fetchExpiringAssignments = async ({
    queryKey: [_entity, { location_id }],
  }: FetchExpiringAssignments) => {
    const { assignments } = await request(GET_EXPIRING_ASSIGNMENTS, {
      input: {
        filter: {
          status: 'in_progress',
        },
        search: {
          ends_date_from: moment().format('YYYY-MM-DD'),
          ends_date_to: moment().add(6, 'days').format('YYYY-MM-DD'),
          location_id,
          has_extension: false,
        },
      },
    });
    return assignments;
  };

  const enabled = !!location?.id;
  const {
    data: expiringAssignments = [],
    isLoading: expiringAssignmentsLoading,
    isInitialLoading,
  } = useQuery(['expiringAssignments', { location_id: location?.id }], fetchExpiringAssignments, {
    enabled,
    onError,
  });

  return {
    count: newApplications.length + expiringAssignments.length + upcomingPhoneScreenings.length,
    newApplicantsCount: newApplications.length,
    newApplications,
    upcomingPhoneScreenings,
    expiringAssignments,
    isLoading:
      newApplicantsLoading ||
      (enabled ? expiringAssignmentsLoading : isInitialLoading) ||
      phoneScreeningsLoading,
  };
};

export default useNotifications;
