import { JobTemplate } from '@medely/types';
import { axios } from 'utils/networkRequests';

export const jobTemplates = {
  create: ({
    template,
    locationId,
  }: {
    template: { job_template: Partial<JobTemplate> };
    locationId: number;
  }) => axios.post(`/v3/facility/locations/${locationId}/job_templates`, template),
  update: ({ job_template: { id, ...rest } }: any, locationId: number) =>
    axios.patch(`/v3/facility/locations/${locationId}/job_templates/${id}`, {
      job_template: rest,
    }),
  delete: (templateId: number, locationId: number) =>
    axios.delete(`/v3/facility/locations/${locationId}/job_templates/${templateId}`),
};
