import { axios } from 'utils/networkRequests';

export const favorites = {
  create: (professional_id: number, location_id: number) =>
    axios.post(`/v3/facility/locations/${location_id}/favorites`, {
      professional_id,
    }),
  delete: (professional_id: number, location_id: number) =>
    axios.delete(`/v3/facility/locations/${location_id}/favorites/${professional_id}`, {
      data: {
        professional_id,
      },
    }),
};
