import { gql } from 'graphql-request';

export const GET_BILLING_GROUPS = gql`
  query BillingGroups($input: BillingGroupQueryInput) {
    billingGroups(input: $input) {
      id
      has_past_due
      track_unpaid_breaks
      funding_sources {
        id
        brand
        last4
        bank_name
        active
        status
        hosted_verification_url
      }
      locations {
        id
        name
      }
      name
      plan
      billing
      track_unpaid_breaks
    }
  }
`;

export const GET_BILLING_GROUP_ACH_DETAILS = gql`
  query BillingGroupACHDetails($id: Int!) {
    billingGroup(id: $id) {
      id
      ach_source_detail
    }
  }
`;

export const GET_HOSTED_BILLING_ACH_DETAILS = gql`
  query GatewayBillingGroup($uid: String!) {
    gatewayBillingGroup(uid: $uid) {
      ach_source_detail
    }
  }
`;

export const GET_BILLING_GROUP_SUMMARY = gql`
  query BillingGroup($id: Int!) {
    billingGroup(id: $id) {
      balance_cents
      total_due_cents
    }
  }
`;

export const GET_ITEMIZED_TRANSACTIONS = gql`
  query GetItemizedTransactions($input: ItemizedTransactionQueryInput) {
    itemizedTransactions(input: $input) {
      id
      invoice_id
      created_at
      from_source
      to_source
      amount_cents
      notes
      description
      labels
    }
    _itemizedTransactionsMeta(input: $input) {
      count
    }
  }
`;

export const GET_ITEMIZED_TRANSACTION = gql`
  query GetItemizedTransaction($id: Int!) {
    itemizedTransaction(id: $id) {
      id
      created_at
      invoice_id
      amount_cents
      labels
      financial_transaction {
        kind
        facility_notes
      }
    }
  }
`;
