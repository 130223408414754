import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { api } from 'api/index';

const useTeamMemberActions = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: inviteTeamMember } = useMutation(api.teamMembers.actions.invite, {
    onSuccess: () => {
      enqueueSnackbar('Account has been invited', { variant: 'success' });
    },
  });

  const { mutate: editTeamMember } = useMutation(api.teamMembers.update, {
    onSuccess: () => {
      enqueueSnackbar('Account has been updated', { variant: 'success' });
    },
  });

  const { mutate: resendInviteTeamMember } = useMutation(api.teamMembers.actions.resendInvite, {
    onSuccess: () => {
      enqueueSnackbar('Account invite has been re-sent', { variant: 'success' });
    },
  });

  return { invite: inviteTeamMember, edit: editTeamMember, resendInvite: resendInviteTeamMember };
};

export default useTeamMemberActions;
