import config from './config';

const pathTo = (path: string) => `${config.root}/${path}`;

export const routes = {
  dashboard: {
    name: 'Dashboard',
    path: pathTo('dashboard'),
  },
  scheduler: {
    name: 'Scheduler',
    path: pathTo('scheduler'),
  },
  assignments: {
    name: 'Assignments',
    path: pathTo('assignments'),
  },
  approvals: {
    name: 'Approvals',
    path: pathTo('approvals'),
  },
  timeTracking: {
    name: 'Time Tracking',
    path: pathTo('time-tracking'),
  },
  billing: {
    name: 'Billing',
    path: pathTo('billing'),
  },
  analytics: {
    name: 'Analytics',
    path: pathTo('analytics'),
  },
  workforce: {
    name: 'Workforce',
    path: pathTo('workforce'),
  },
  account_settings: {
    name: 'Account Settings',
    path: pathTo('account_settings'),
  },
  settings: {
    name: 'Settings',
    path: pathTo('settings'),
  },
  billRates: {
    name: 'Bill Rates',
    path: pathTo('settings/bill-rates'),
  },
};

export default routes;
