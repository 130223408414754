import { gql } from 'graphql-request';

export const GET_STATES = gql`
  query States($input: StateQueryInput) {
    states(input: $input) {
      id
      name
    }
  }
`;

export const GET_STATE = gql`
  query State($id: Int!) {
    state(id: $id) {
      id
      name
    }
  }
`;
