import { currencyFormat } from 'core/constants/formats';
import { Optional } from 'graphql/utils';
import isNil from 'lodash/isNil';
import numeral from 'numeral';

export const centsToCurrency = (cents: Optional<number>, format = currencyFormat, floor = false): string | null => {
  if (isNil(cents)) {
    return null;
  }

  const value = cents / 100;
  return numeral(floor ? Math.floor(value) : value).format(format);
};
