import moment, { Moment, MomentInput } from 'moment-timezone';
import chunk from 'lodash/chunk';
import times from 'lodash/times';
import { Optional } from 'graphql/utils';
import { type Job, type Location } from 'graphql/generated/graphql';

export const setDateToTimeMoment = (
  date: MomentInput,
  time: MomentInput,
  ignoreDateTz = true,
): Moment => {
  const dateMoment = ignoreDateTz ? moment.utc(date) : moment(date);
  const timeMoment = moment(time);

  const dateTimeMoment = timeMoment.clone();
  const timezone = dateMoment.tz() ?? moment.tz.guess();
  dateTimeMoment.tz(timezone);
  dateTimeMoment.set('year', dateMoment.year());
  dateTimeMoment.set('month', dateMoment.month());
  dateTimeMoment.set('date', dateMoment.date());

  if (dateTimeMoment.isValid()) {
    return dateTimeMoment;
  } else {
    throw new Error('Invalid datetime');
  }
};

export const clearTimezone = (date: Moment): Moment => moment(date.toArray().slice(0, -1));

export const hoursUntil = (date: Moment, ignoreDateTz = true): number => {
  const timeNow = ignoreDateTz ? clearTimezone(moment()) : moment();
  const targetDate = ignoreDateTz ? clearTimezone(date) : date;
  const minutesUntil = targetDate.diff(timeNow, 'minutes');

  return minutesUntil / 60;
};

export const jobLeadTime = ({
  timezone,
  startsDate,
  startsTime,
}: {
  timezone: string;
  startsDate: Moment;
  startsTime: Moment;
}): number => {
  const timeNowOnTimezone = moment.tz(timezone);
  const jobStart = moment.tz(timezone).set({
    year: startsDate.year(),
    month: startsDate.month(),
    date: startsDate.date(),
    hours: startsTime.hours(),
    minutes: startsTime.minutes(),
    seconds: 0,
  });

  const leadTimeInMinutes = jobStart.diff(timeNowOnTimezone, 'minutes');
  const leadTimeInHours = leadTimeInMinutes / 60;

  return parseFloat(leadTimeInHours.toFixed(1));
};

export const isNotNewUser = (created_at: string) => moment().diff(moment(created_at), 'days') >= 1;

type DeriveDateJob = Pick<Job, 'current_ends_time' | 'current_starts_time'> & {
  location?: Optional<Pick<Location, 'timezone_lookup'>>;
};

export const deriveDate = (
  job: DeriveDateJob,
  time: string,
  mode: 'clock_in' | 'clock_out',
): string | null => {
  const locationTimezone = job.location?.timezone_lookup ?? moment.tz.guess();
  const startMoment = moment.tz(job.current_starts_time, locationTimezone);
  const endMoment = moment.tz(job.current_ends_time, locationTimezone);

  if (startMoment.isAfter(endMoment)) {
    throw new Error('current_starts_time is after current_ends_time');
  } else if (endMoment.diff(startMoment, 'days') > 1) {
    throw new Error('current_starts_time and current_ends_time are more than one day apart');
  }

  const timeAtStartDate = setDateToTimeMoment(startMoment, time, false);
  const timeAtEndDate = setDateToTimeMoment(endMoment, time, false);

  if (mode === 'clock_out') {
    if (timeAtStartDate.isBefore(startMoment, 'minute')) {
      return startMoment.clone().add(1, 'day').format('YYYY-MM-DD');
    }
  } else {
    if (timeAtEndDate.isAfter(endMoment, 'minute')) {
      return endMoment.clone().subtract(1, 'day').format('YYYY-MM-DD');
    }
  }

  if (timeAtStartDate.isBetween(startMoment, endMoment)) {
    return timeAtStartDate.format('YYYY-MM-DD');
  }
  if (timeAtEndDate.isBetween(startMoment, endMoment)) {
    return timeAtEndDate.format('YYYY-MM-DD');
  }

  return mode === 'clock_in' ? startMoment.format('YYYY-MM-DD') : endMoment.format('YYYY-MM-DD');
};

export type MonthBreakdown = {
  startOfMonth: Moment;
  endOfMonth: Moment;
  weeks: Moment[][];
};

export function getMonthBreakdown(date: Moment): MonthBreakdown {
  // Use clones to avoid modifying the original date reference
  const startOfMonth = date.clone().startOf('month');
  const endOfMonth = date.clone().endOf('month');
  // TODO: Depends on locale, we should enforce a locale where start of week is Sunday
  const startDate = startOfMonth.clone().startOf('week');
  const endDate = endOfMonth.clone().endOf('week');
  const dayCount = endDate.diff(startDate, 'day') + 1;
  const getDay = (i: number) => startDate.clone().add(i, 'day');
  const days = times(dayCount, getDay);
  const weeks = chunk(days, 7);

  return {
    startOfMonth,
    endOfMonth,
    weeks,
  };
}

export type WeekBreakdown = {
  startOfWeek: Moment;
  endOfWeek: Moment;
  dstStarts: boolean;
  dstEnds: boolean;
  days: Moment[];
};

export function getWeekBreakdown(date: Moment): WeekBreakdown {
  const startOfWeek = date.clone().startOf('week');
  const endOfWeek = date.clone().endOf('week');

  const startOffset = startOfWeek.utcOffset();
  const endOffset = endOfWeek.utcOffset();

  const dstStarts = startOffset < endOffset;
  const dstEnds = startOffset > endOffset;

  const days = times(7, (i) => startOfWeek.clone().add(i, 'day'));

  return {
    startOfWeek,
    endOfWeek,
    dstStarts,
    dstEnds,
    days,
  };
}

export type DayBreakdown = {
  startOfDay: Moment;
  endOfDay: Moment;
  dstStarts: boolean;
  dstEnds: boolean;
  hours: Moment[];
};

export function getDayBreakdown(date: Moment): DayBreakdown {
  const hours = [];
  const startOfDay = date.clone().startOf('day');
  const endOfDay = date.clone().endOf('day');

  // Iterate over each hour upto but not including the next day
  // NOTE: We do this instead of a 24 iteration loop because daylight savings time (DST) may require 23 or 25 iterations
  let currentDate = startOfDay.clone();
  while (currentDate.isBefore(endOfDay)) {
    hours.push(currentDate);
    currentDate = currentDate.clone().add(1, 'hour');
  }

  return {
    startOfDay,
    endOfDay,
    hours,
    dstStarts: hours.length < 24,
    dstEnds: hours.length > 24,
  };
}
export const getJobStartsWithin24hrs = (values: any) => {
  const { starts_date, starts_time } = values;
  if (!starts_date || !starts_time) {
    throw new Error('missing values in getJobStartsWithin24hrs');
  }
  const dateTimeMoment = setDateToTimeMoment(starts_date, starts_time);
  const startsWithin24Hrs = hoursUntil(dateTimeMoment) <= 24;
  return startsWithin24Hrs;
};

export const assignmentMinDate = ({
  isExtension,
  isNewExtension,
  initialEndsDate,
  originalAssignmentEndsDate,
}: {
  isExtension: boolean;
  isNewExtension: unknown;
  initialEndsDate: unknown;
  originalAssignmentEndsDate: unknown;
}) => {
  if (isExtension && isNewExtension) {
    return initialEndsDate;
  } else if (isExtension && !isNewExtension) {
    return originalAssignmentEndsDate;
  }
  return new Date();
};
