import { GraphQLProvider, GraphQLProviderInput, useAuthContext } from '@medely/ui-kit/web';
import { useQuery } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import React, { createContext, useContext, type ReactNode } from 'react';
import { notifyRollbar } from 'utils/Rollbar/notifyRollbar';
import { gql } from 'graphql-request';
import gqlRequest from 'utils/networkRequests/gqlRequest';

export const PERMISSIONS_QUERY = gql`
  query GetSchemaPermissionsForRequests {
    accountRequestPermissions
  }
`;

type RequestPermissions = Record<string, Record<string, boolean | string>>;

export const useGraphQLPermissions = () => {
  const { user } = useAuthContext();

  const enabled = !(window as any)?.CypressSuppressUser && !!user;

  const { data, isLoading, isInitialLoading, ...props } = useQuery<RequestPermissions>(
    ['GetSchemaPermissionsForRequests'],
    async () => {
      const response = await gqlRequest(PERMISSIONS_QUERY);
      return response?.accountRequestPermissions;
    },
    { enabled },
  );

  return { ...props, permissions: data, isLoading: enabled ? isLoading : isInitialLoading };
};

type GraphQLPermissionsContext = {
  isLoading: boolean;
  permissions: unknown;
};

const graphqlPermissionsContext = createContext<GraphQLPermissionsContext>({
  isLoading: true,
  permissions: undefined,
});

const GraphQLPermissionsContextProvider = ({ children }: { children: ReactNode }) => {
  const snackbar = useSnackbar();
  const { isLoading, permissions } = useGraphQLPermissions();

  const graphQLProviderProps: Omit<GraphQLProviderInput, 'children'> = {
    enableTransform: true,
    permissionsTransformer: {
      permissions,
      onTransform: (message: string) => {
        const warning = `GraphQL Query transformed: ${message}`;

        if (process.env.NODE_ENV !== 'production') {
          snackbar.warning(warning);
          console.warn(warning);
        }

        notifyRollbar({
          errorLabel: warning,
          args: { message, level: 'WARNING' },
        });
      },
    },
  };

  return (
    <graphqlPermissionsContext.Provider value={{ isLoading: isLoading, permissions }}>
      <GraphQLProvider {...graphQLProviderProps}>{children}</GraphQLProvider>
    </graphqlPermissionsContext.Provider>
  );
};

export const useGraphQLPermissionsContext = (): GraphQLPermissionsContext =>
  useContext(graphqlPermissionsContext);

export default GraphQLPermissionsContextProvider;
