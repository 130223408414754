import { Field, useFormState } from 'react-final-form';
import {
  Grid,
  InputAdornment,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from '@medely/web-components';
import { RadioField, TextField } from '@medely/web-components/forms';
import { composeValidation, required } from 'core/utils/validation';
import React, { useState } from 'react';

const greaterThanZero =
  (message = 'Must be more than $0') =>
  (v) =>
    v > 0 ? undefined : message;
const costValidation = composeValidation(required, greaterThanZero('Amount must be more than $0'));

const SCRUBS_OPTIONS = [
  { label: 'The facility will provide scrubs', value: 'true' },
  { label: 'Professionals should bring their own scrubs', value: 'false' },
];

const SHIFT_LENGTH_OPTIONS = [
  { label: '8 hours', value: '8' },
  { label: '12 hours', value: '12' },
];

const WelcomeFormDetails = (): React.ReactElement => {
  const { values, errors, touched } = useFormState();
  const [parkingSelection, setParkingSelection] = useState(values.parking_selection || null);

  const handleChange = (value: string) => {
    setParkingSelection(value);
    values.parking_selection = value;
    values.parking_free = value === 'parking_free' ? 'true' : 'false';
    values.parking_reimbursed = value === 'parking_reimbursed' ? 'true' : 'false';
    values.parking_validated = value === 'parking_validated' ? 'true' : 'false';
  };

  return (
    <Grid container spacing={2} data-testid="parking_instructions" maxWidth="sm">
      <Grid item xs={12}>
        <Typography fontSize={14}>
          Fill out this form to help Medely professionals know what to expect when working at your
          facility.
        </Typography>
      </Grid>
      <Grid item xs={12} data-testid="common_shift_length_hours">
        <RadioField
          validate={required}
          name="common_shift_length_hours"
          label={
            <Typography variant="body2" fontSize={18} weight="medium" color="textPrimary">
              Most commons shift length
            </Typography>
          }
          options={SHIFT_LENGTH_OPTIONS}
        />
      </Grid>

      <Grid item xs={12} data-testid="scrubs_provided">
        <RadioField
          validate={required}
          name="scrubs_provided"
          label={
            <Typography variant="body2" fontSize={18} weight="medium" color="textPrimary">
              Scrubs
            </Typography>
          }
          options={SCRUBS_OPTIONS}
        />
      </Grid>

      <Grid item xs={12} data-testid="parking">
        <Typography variant="body2" weight="medium" fontSize={18} color="textPrimary">
          Parking
        </Typography>

        <Field name="parking" subscription={{ touched: true, error: true }}>
          {({ meta }) => (
            <FormControl component="fieldset" fullWidth error={meta.touched && meta.error}>
              <RadioGroup name="parking_selection" value={parkingSelection}>
                <Field
                  key="parking_free"
                  name="parking_selection"
                  validate={required}
                  component="input"
                  type="radio"
                  value="parking_free"
                >
                  {({ input }) => (
                    <FormControlLabel
                      value="parking_free"
                      control={<Radio />}
                      label="Free parking"
                      checked={input.checked}
                      onChange={() => {
                        input.onChange('parking_free');
                        handleChange('parking_free');
                      }}
                    />
                  )}
                </Field>
                <Field
                  key="parking_validated"
                  name="parking_selection"
                  validate={required}
                  component="input"
                  type="radio"
                  value="parking_validated"
                >
                  {({ input }) => (
                    <FormControlLabel
                      value="parking_validated"
                      control={<Radio />}
                      label="Facility will validate parking"
                      checked={input.checked}
                      onChange={() => {
                        input.onChange('parking_validated');
                        handleChange('parking_validated');
                      }}
                    />
                  )}
                </Field>

                <Field
                  key="parking_reimbursed"
                  name="parking_selection"
                  validate={required}
                  component="input"
                  type="radio"
                  value="parking_reimbursed"
                >
                  {({ input }) => (
                    <FormControlLabel
                      value="parking_reimbursed"
                      control={<Radio />}
                      label="Facility will reimburse parking"
                      checked={input.checked}
                      onChange={() => {
                        input.onChange('parking_reimbursed');
                        handleChange('parking_reimbursed');
                      }}
                    />
                  )}
                </Field>
                {parkingSelection === 'parking_reimbursed' && (
                  <Grid item xs={12} data-testid="parking_reimbursed_cents" md={6} sx={{ ml: 4 }}>
                    <TextField
                      name="parking_reimbursement_cents"
                      validate={costValidation}
                      label="Daily reimbursable amount*"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}

                <Field
                  key="professionals_responsible"
                  name="parking_selection"
                  validate={required}
                  component="input"
                  type="radio"
                  value="professionals_responsible"
                >
                  {({ input }) => (
                    <FormControlLabel
                      value="professionals_responsible"
                      control={<Radio />}
                      label="Professionals are responsible for parking costs"
                      checked={input.checked}
                      onChange={() => {
                        input.onChange('professionals_responsible');
                        handleChange('professionals_responsible');
                      }}
                    />
                  )}
                </Field>

                {parkingSelection === 'professionals_responsible' && (
                  <Grid item xs={12} data-testid="parking_reimbursed_cents" md={6} sx={{ ml: 4 }}>
                    <TextField
                      name="parking_cost_cents"
                      validate={costValidation}
                      label="Daily parking cost*"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
              </RadioGroup>
              {touched?.parking_selection && errors?.parking_selection && (
                <Typography fontSize={12} ml={2} color="error">
                  Required
                </Typography>
              )}
            </FormControl>
          )}
        </Field>
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="parking_instructions"
          validate={required}
          placeholder="Parking Instructions*"
          multiline
          rows={4}
          helperText={
            'Provide parking instructions to ensure professionals are on time and ready to work.'
          }
        />
      </Grid>
    </Grid>
  );
};

export default WelcomeFormDetails;
