import { GET_STATES } from 'graphql/statesQueries';
import { State } from 'graphql/generated/graphql';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import { useQuery } from '@tanstack/react-query';

const useStates = (): { states: State[]; isLoading: boolean } => {
  const { request } = useGraphQLRequest();

  const getStates = async () => {
    const { states } = await request(GET_STATES, { input: { orderBy: { name: true } } });
    return states;
  };

  const { data, isLoading } = useQuery(['states'], getStates);

  return {
    states: data ?? [],
    isLoading,
  };
};

export default useStates;
