import { Box, Typography } from '@medely/web-components';
import { PresentBox } from '@medely/web-components/icons';
import React /*, { useCallback }*/ from 'react';
// import config from 'config';
// import qs from 'qs';

//interface ReferralButtonProps extends DispatchProp, UserProps {}

const ReferralButton: React.FunctionComponent /*<ReferralButtonProps>*/ = (
  {
    // dispatch,
    // user
  },
) => {
  const handleClick = () => null;
  // const handleClick = useCallback(() => {
  //   dispatch(UserActions.typeform.referral.open());
  //   import('@typeform/embed').then((typeform) => {
  //     typeform.makePopup(
  //       `${config.facilityReferralTypeform}?${qs.stringify({
  //         user_id: user.id,
  //       })}`,
  //       {
  //         autoClose: 0,
  //         autoOpen: true,
  //         hideFooter: false,
  //         mode: 'popup',
  //         onSubmit: () => {
  //           dispatch(UserActions.typeform.referral.submit());
  //         },
  //       },
  //     );
  //   });
  // }, [dispatch]);
  return (
    <Box
      borderRadius={1}
      sx={{
        cursor: 'pointer',
        border: 1,
        borderColor: 'primary.main',
        bgcolor: 'primary.light',
        mx: 3,
        mb: 2,
        p: 2,
      }}
      role="button"
      onClick={handleClick}
    >
      <Box mb={2}>
        <Typography variant="body2" color="primary.dark">
          Know a facility that needs staff? Refer them to Medely! You&apos;ll both get a $200 Amazon
          gift card.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" color="primary" weight="bold">
          <PresentBox /> Get my gift card
        </Typography>
      </Box>
    </Box>
  );
};

export default ReferralButton;
