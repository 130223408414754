import { gql } from 'graphql-request';

export const GET_PER_DIEM_JOBS = gql`
  query PerDiemJobs($input: JobQueryInput) {
    jobs(input: $input) {
      id
      direct_post
      charge_base_hourly_rate_cents
      max_possible_charge_base_hourly_rate_cents
      location_id
      status
      approval_status
      starts_date
      starts_time
      ends_time
      positions {
        id
        name
        display_name
        kind
        specialties {
          id
        }
      }
      surgery_types {
        id
      }
      professional {
        id
        nickname
        account {
          name
          first_name
          last_name
          agency_id
        }
        professional_saas_details {
          employment_type
        }
      }
      professional_number
      current_starts_time
      current_ends_time
      approved_clock_in
      approved_clock_out
      release_audience
      release_to_marketplace_strategy
      cancellation_fee_charge_hours
      job_profiles {
        professional_type
        charge_base_hourly_rate_cents
      }
      bonus_one_time_cents
      job_billing_sums {
        total_hours
        charge_regular_hours
        charge_overtime_hours
        charge_double_hours
        total_breaks_hours
        total_on_call_hours
        charge_callback_hours
      }
      location {
        timezone_lookup
      }
      track_unpaid_breaks
      bonus_one_time_cents
      marketplace_bonus_one_time_cents
    }
  }
`;

export const GET_ASSIGNMENT_JOBS = gql`
  query AssignmentJobs($input: JobQueryInput) {
    jobs(input: $input) {
      id
      charge_base_hourly_rate_cents
      max_possible_charge_base_hourly_rate_cents
      assignment_id
      assignment {
        id
        ends_date
      }
      location_id
      status
      approval_status
      starts_date
      starts_time
      ends_date
      ends_time
      shift_type
      positions {
        id
        name
        display_name
        kind
      }
      professional {
        id
        nickname
        account {
          name
          first_name
          last_name
          agency_id
        }
        professional_saas_details {
          employment_type
        }
      }
      professional_number
      current_starts_time
      current_ends_time
      approved_clock_in
      approved_clock_out
      cancellation_fee_charge_hours
      job_billing_sums {
        total_hours
        charge_regular_hours
        charge_overtime_hours
        charge_double_hours
        total_breaks_hours
        total_on_call_hours
        charge_callback_hours
      }
      location_id
      location {
        timezone_lookup
      }
      track_unpaid_breaks
    }
  }
`;

export const GET_MONTH_JOBS = gql`
  query MonthJobs($input: JobQueryInput) {
    jobs(input: $input) {
      id
      assignment_id
      location_id
      status
      approval_status
      starts_date
      starts_time
      shift_type
      positions {
        id
        name
        display_name
        kind
      }
      professional {
        id
        nickname
        account {
          name
          first_name
          last_name
        }
      }
      professional_number
      current_starts_time
      current_ends_time
      approved_clock_in
      approved_clock_out
      job_billing_sums {
        total_hours
        charge_regular_hours
        charge_overtime_hours
        charge_double_hours
        total_breaks_hours
        total_on_call_hours
        charge_callback_hours
      }
      location {
        timezone_lookup
      }
    }
  }
`;

export const GET_JOBS = gql`
  query Jobs($input: JobQueryInput) {
    jobs(input: $input) {
      approval_status
      approved_clock_in
      approved_clock_out
      assignment_id
      attachment
      current_ends_time
      charge_base_hourly_rate_cents
      current_starts_time
      dispute_review_approval_deadline
      ends_date
      ends_time
      id
      location_id
      missed_clock_out
      professional_number
      shift_type
      starts_date
      starts_time
      is_w2
      status
      timecard_id
      track_unpaid_breaks
      disputes {
        id
        created_at
      }
      job_billing_sums {
        total_hours
        charge_regular_hours
        total_breaks_hours
        charge_overtime_hours
        charge_double_hours
        total_on_call_hours
        charge_callback_hours
        charge_total_amount_cents
      }
      track_unpaid_breaks
      location {
        unit
        timezone_lookup
        name
        external_id
      }
      invoices {
        id
        status
      }
      job_profiles {
        professional_type
      }
      disputes {
        id
        created_at
      }
      job_irregularities {
        id
        label
        approved_at
        status
        irregularity_type
        approved_by_account_id
      }
      positions {
        id
        name
        display_name
        kind
      }
      professional {
        id
        nickname
        account {
          name
          first_name
          last_name
        }
      }
    }
    jobsTotals(input: $input) {
      filtered_total_hours
      filtered_regular_hours
      filtered_overtime_hours
      filtered_double_hours
      filtered_break_hours
    }
    _jobsMeta(input: $input) {
      count
    }
  }
`;

//gets job count from every location, not just the ones connected to this facility
export const GET_JOB_COUNT = gql`
  query JobCount($input: JobQueryInput) {
    jobCount(input: $input) {
      count
    }
  }
`;

export const GET_DRAFT_JOB_IDS = gql`
  query DraftJobs($input: JobQueryInput) {
    jobs(input: $input) {
      id
      current_starts_time
    }
  }
`;

export const GET_JOB = gql`
  query Job($id: Int!) {
    job(id: $id) {
      id
      assignment_id
      timecard_id
      location_id
      location {
        company_id
        name
        company_id
        enable_geo_fence
        timezone_lookup
        require_upload_on_clock_out
        coordinates {
          latitude
          longitude
        }
      }
      last_cancellation {
        created_at
        note
        reason
        other_reason
      }
      status
      approval_status
      starts_date
      current_starts_time
      current_ends_time
      shift_type
      is_w2
      approved_clock_in
      track_unpaid_breaks
      charge_callback_multiplier
      charge_overtime_multiplier
      charge_double_overtime_multiplier
      charge_base_hourly_rate_cents
      check_outs {
        id
        accepted
        manual_override
        clock_event_attachments {
          id
          file
          reasons
          metadata
          clock_event_type
        }
      }
      check_ins {
        id
        accepted
        manual_override
        clock_event_attachments {
          id
          file
          reasons
          metadata
          clock_event_type
        }
      }
      approved_clock_out
      job_billing_sums {
        charge_callback_hours
        charge_double_hours
        charge_overtime_hours
        charge_regular_amount_cents
        charge_regular_hours
        charge_overtime_amount_cents
        charge_total_amount_cents
        total_breaks_hours
        total_breaks_minutes
        total_hours
        total_on_call_hours
      }
      positions {
        id
        kind
        display_name
        name
      }
      review {
        id
        comments
        rating
        blocked
      }
      professional {
        id
        nickname
        status
        is_any_favorite
        account {
          name
          first_name
          last_name
        }
        kind
        positions {
          id
          display_name
        }
        specialties {
          id
          label
        }
      }
      professional_number
      surgery_types {
        id
        display_name
      }
      specialties {
        id
        label
      }
      created_at
      patient_population
      detail
      starts_time
      ends_time
      clinical_contact {
        id
      }
      created_by {
        id
        name
        account_type
      }
      job_irregularities {
        id
        label
        approved_at
        status
        irregularity_type
      }
      covid_care
      charge_base_hourly_rate_cents
      charge_on_call_hourly_rate_cents
      cancellation_fee_window
      bonus_one_time_cents
      favorite_ids
      release_to_platform
      posting_field_contents
      attachment
      marketplace_charge_base_hourly_rate_cents
      marketplace_bonus_one_time_cents
      max_possible_charge_base_hourly_rate_cents
      max_possible_bonus_one_time_cents
      release_audience
      release_to_marketplace_strategy
      job_price_differentials {
        id
        slug
        recommendation_used
      }
      approval_requestee_notes
      approval_violated_parameters
      approvals {
        id
        status
        approval_rule_id
        tier
        rejection_reason
        approver_account {
          id
          name
        }
        approval_groups {
          id
          accounts {
            id
            name
          }
        }
      }
    }
  }
`;

export const VALIDATE_JOB_TIMES = gql`
  query ValidateTimes($input: ValidateJobTimesInput!) {
    validateJobTimes(input: $input)
  }
`;

export const GET_JOB_DISPUTES = gql`
  query JobDisputes($id: Int!) {
    job(id: $id) {
      id
      disputes {
        id
        invoice_id
        job_id
        original_clock_in
        original_clock_out
        adjusted_clock_in
        adjusted_clock_out
        reason
        status
        category
        facility_notes
        admin_notes
        professional_notes
        resolution
        assigned_to_id
        original_meal_break_minutes
        adjusted_meal_break_minutes
        total_adjusted_minutes
        original_total_payout_cents
        adjusted_total_payout_cents
        attachment
        created_at
        updated_at
      }
    }
  }
`;

export const GET_NEEDS_REVIEW_JOB_COUNT = gql`
  query NeedsReviewJobCount($input: JobQueryInput) {
    _jobsMeta(input: $input) {
      count
    }
  }
`;

export const GET_JOB_STATUS_LOCATION_COUNTS = gql`
  query JobStatusLocationCounts($input: JobStatusLocationCountsInput!) {
    jobStatusLocationCounts(input: $input) {
      job_count
      location_id
    }
  }
`;
