import { Box, IconButton } from '@medely/web-components';
import { ChatSolid } from '@medely/web-components/icons';
import React, { useRef } from 'react';
import show from 'utils/Intercom/show';

const ChatMenu = () => {
  const anchor = useRef();
  return (
    <>
      <IconButton onClick={show}>
        <Box
          display="inline-block"
          position="relative"
          mx="auto"
          ref={anchor}
          data-testid="header-menu-chat"
        >
          <ChatSolid color="primary" />
        </Box>
      </IconButton>
    </>
  );
};

export default ChatMenu;
