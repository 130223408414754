import { pick } from 'lodash';
import { dollarToCents } from 'utils/dollarToCents';
import { useQueryClient } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { axios } from 'utils/networkRequests';

const BILLING_GROUP_NAME = 'Default';

const LOCATION_PARAMS = [
  'address_city',
  'address_first',
  'address_second',
  'address_zip',
  'billing_group_ids',
  'id',
  'location_type_id',
  'name',
  'parking_cost_cents',
  'parking_free',
  'parking_instructions',
  'parking_reimbursed',
  'parking_reimbursement_cents',
  'parking_validated',
  'phone_number',
  'primary_billing_group_id',
  'scrubs_provided',
  'state_id',
  'stripe_token',
  'unit',
  'common_shift_length_hours',
];

const useLocationUpsert = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  return async ({ id, primary_billing_group_id, stripe_token, ...values }) => {
    try {
      let billingGroupId = primary_billing_group_id;
      if (stripe_token) {
        const {
          data: {
            payload: { id: groupId },
          },
        } = await axios.post(`/v3/facility/billing_groups`, {
          billing_group: {
            name: BILLING_GROUP_NAME,
            stripe_token,
          },
        });
        billingGroupId = groupId;
      }
      if (id) {
        await axios.put(`/v3/facility/locations/${id}`, {
          location: {
            ...pick(values, LOCATION_PARAMS),
            onboarded: true,
            parking_reimbursement_cents: values.parking_reimbursement_cents
              ? dollarToCents(values.parking_reimbursement_cents)
              : undefined,
            parking_cost_cents: values.parking_cost_cents
              ? dollarToCents(values.parking_cost_cents)
              : undefined,
          },
        });
        snackbar.success('Facility has been successfully updated');
      } else {
        await axios.post('/v3/facility/locations', {
          location: {
            ...pick(values, LOCATION_PARAMS),
            billing_group_ids: [billingGroupId],
            primary_billing_group_id: billingGroupId,
            parking_reimbursement_cents: values.parking_reimbursement_cents
              ? dollarToCents(values.parking_reimbursement_cents)
              : undefined,
            parking_cost_cents: values.parking_cost_cents
              ? dollarToCents(values.parking_cost_cents)
              : undefined,
            enable_geo_fence: true,
            geo_fence_radius: 500,
          },
        });
        snackbar.success('Facility has been successfully created');
      }
      queryClient.invalidateQueries(['locations']);
      queryClient.invalidateQueries(['billingGroups']);
    } catch (e) {
      snackbar.error(e);
      throw e;
    }
  };
};

export default useLocationUpsert;
