import { Field, FormRenderProps } from 'react-final-form';
import { Box, Grid, MaskedInput } from '@medely/web-components';
import useCurrentUser from 'hooks/useCurrentUser';
import { PhoneField, SingleSelectField, TextField } from '@medely/web-components/forms';
import { composeValidation, phone, required, zip } from 'core/utils/validation';
import React from 'react';
import LocationTypeIdField from './LocationTypeIdField';
import useStates from 'hooks/useStates';
import FacilityTermsOfServiceCheckbox from 'components/TermsOfService/FacilityTermsOfServiceCheckbox';

const SCRUBS_OPTIONS = [
  { value: true, label: 'Scrubs provided' },
  { value: false, label: 'Bring your own scrubs' },
];

const phoneValidation = composeValidation(required, phone);
const zipValidation = composeValidation(required, zip);

const LocationWizardFacilityForm = ({}: FormRenderProps): React.ReactElement => {
  const { states } = useStates();
  const { currentUser } = useCurrentUser();
  const STATE_OPTIONS = states.map(({ id, name }) => ({ value: id, label: name }));
  return (
    <Grid container spacing={2} data-testid="add/edit-facility-form">
      <Grid item xs={12} data-testid="add/edit-facility-form-name">
        <TextField name="name" label="Facility Name" validate={required} />
      </Grid>
      <Grid item xs={12} data-testid="add/edit-facility-form-type">
        <LocationTypeIdField />
      </Grid>
      <Grid item xs={12} data-testid="add/edit-facility-form-unit">
        <TextField name="unit" label="Unit/Department" />
      </Grid>
      <Grid item xs={12} data-testid="add/edit-facility-form-address1">
        <TextField
          name="address_first"
          label="Address 1"
          placeholder="Street Address"
          validate={required}
        />
      </Grid>
      <Grid item xs={12} data-testid="add/edit-facility-form-address2">
        <TextField
          name="address_second"
          label="Address 2"
          placeholder="Unit Number, Suite, Building No."
        />
      </Grid>
      <Grid item xs={12} sm={6} data-testid="add/edit-facility-form-city">
        <TextField name="address_city" label="City" validate={required} />
      </Grid>
      <Grid item xs={12} sm={6} data-testid="add/edit-facility-form-zip">
        <Field name="address_zip" validate={zipValidation}>
          {({ input, meta }) => (
            <MaskedInput
              {...input}
              mask="zip"
              // @ts-ignore
              lazy
              InputLabelProps={{ shrink: true }}
              error={meta.touched && meta.invalid}
              helperText={meta.touched && meta.error}
              label="Zip Code"
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} sm={6} data-testid="add/edit-facility-form-state">
        {!!STATE_OPTIONS.length && (
          <SingleSelectField
            name="state_id"
            label="State"
            options={STATE_OPTIONS}
            validate={required}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} data-testid="add/edit-facility-form-phone">
        <PhoneField name="phone_number" validate={phoneValidation} />
      </Grid>
      <Grid item xs={12} data-testid="add/edit-facility-form-scrubs">
        <SingleSelectField
          name="scrubs_provided"
          label="Scrubs"
          options={SCRUBS_OPTIONS}
          validate={required}
          helperText="Does your facility provide scrubs?"
        />
      </Grid>

      <Grid item xs={12} data-testid="add/terms-of-service-checkbox">
        <Box height="48px">
          <Field name="terms_of_service" validate={required}>
            {({ input }) => (
              <FacilityTermsOfServiceCheckbox
                formName="Location Wizard"
                email={currentUser?.email}
                onInvalid={() => {
                  input.onChange(undefined);
                }}
                onValid={() => {
                  input.onChange('valid');
                }}
              />
            )}
          </Field>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LocationWizardFacilityForm;
