/* eslint-disable medely/noUseCallbackUseMemo */
import { useAnalytics } from '@medely/ui-kit/web';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

export type Payload = { [key: string]: any };

export enum CONTEXTS {
  APPLY_TEMPLATE_BUTTON = 'Apply Template Button',
  BUTTON = 'Button',
  CANCEL_BUTTON = 'Cancel Button',
  CANCEL_SHIFT_BUTTON = 'Cancel Shift Button',
  COMPANY_SIGN_UP = 'Company Sign Up',
  EDIT_SHIFT_BUTTON = 'Edit Shift Button',
  FACILITY_ANALYTICS = 'Facility Analytics',
  FACILITY_ASSIGNMENT = 'Facility Assignment',
  // This is a tmeporary event. This will eventually be
  // deprecated and replaced by a backend tracking event
  // - per Tim Schweichler
  TEMP_FACILITY_ASSIGNMENT = 'TEMP Facility Assignment',
  FACILITY_ASSIGNMENT_LIST = 'Facility Assignment List',
  FACILITY_ASSIGNMENT_SHIFT = 'Facility Assignment Shift',
  FACILITY_CANCEL_JOB = 'Facility Cancel Job',
  FACILITY_DASHBOARD = 'Facility Dashboard',
  FACILITY_JOB_DETAILS = 'Facility Job Details',
  FACILITY_PER_DIEM = 'Facility Per Diem',
  FACILITY_SCHEDULER = 'Facility Scheduler',
  LOCATION_FIELD = 'Location Field',
  POST_ASSIGNMENT_BUTTON = 'Post Assignment Button',
  POST_ASSIGNMENT_SHIFT_BUTTON = 'Post Assignment Shift Button',
  POST_BUTTON = 'Post Button',
  POST_PER_DIEM_BUTTON = 'Post Per Diem Button',
  POST_WITH_TEMPLATE_BUTTON = 'Post With Template Button',
  QUICK_ADD_BUTTON = 'Quick Add Button',
  REPEAT_TOGGLE = 'Repeat Toggle',
  RESCHEDULE_SHIFT_BUTTON = 'Reschedule Shift Button',
}

export enum ACTIONS {
  CLICKED = 'Clicked',
  VIEWED = 'Viewed',
  ENTERED = 'Entered',
}

const DEFAULT_DEBOUNCE_TIME = 500;

type IUseAnalyticsEvent = {
  context: CONTEXTS;
  subcontext?: CONTEXTS | null;
  action: ACTIONS;
};

export const useAnalyticsEvent = ({
  context,
  subcontext = null,
  action,
}: IUseAnalyticsEvent): ((payload?: Payload) => void) => {
  const analytics = useAnalytics();

  return useCallback(
    debounce((payload = {}) => {
      analytics.track({
        context,
        subcontext,
        action,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );
};

export default useAnalyticsEvent;
