import { useGate as useStatsigGate } from 'statsig-react';
import { StringParam, useQueryParams } from 'use-query-params';

const useGate = (gateName: string): { value: boolean } => {
  const { value } = useStatsigGate(gateName);
  const [{ flagsOn }] = useQueryParams({
    flagsOn: StringParam,
  });

  return { value: flagsOn ? flagsOn === 'true' : value };
};

export default useGate;
