import { gql } from 'graphql-request';

export const GET_UPCOMING_PHONE_SCREENINGS = gql`
  query UpcomingPhoneScreenings($input: PhoneScreeningRequestQueryInput) {
    phoneScreeningRequests(input: $input) {
      id
      confirmed_phone_screening_time
      masked_professional_phone_number
      professional {
        id
        account {
          name
        }
      }
      assignment_request {
        assignment_id
        assignment {
          id
          starts_date
          ends_date
          starts_time
          ends_time
          hours_per_week
          primary_location {
            timezone_lookup
          }
          positions {
            display_name
          }
          pay_per_week_cents
        }
      }
    }
  }
`;

export const GET_PHONE_SCREENING_REQUEST = gql`
  query PhoneScreeningRequest($id: Int!) {
    phoneScreeningRequest(id: $id) {
      id
      masked_professional_phone_number
      status
      professional {
        id
        account {
          name
        }
      }
    }
  }
`;
