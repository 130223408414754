export const configs = {
  shortLeadTimePrice: 'short_lead_time_snfs_dynamic_config',
};

export const gateNames = {
  assignmentsUpdatePhase1: 'assignments_update_phase_1',
  floatAssignments: 'float_assignments',
  repeatingJobs: 'repeating_jobs',
  tosOn: 'terms_of_service',
  draftsAndPlanning: 'drafts_and_planning',
  viewClockOutSignature: 'facility_clock_out_signature',
  dontReleaseIrpOption: 'dont_release_irp_option',
  facilityBillingPayments: 'facility_billing_payments',
  facilityOnboarding60Onboarding: 'facility_onboarding_6_0_onboarding',
  adminApprovals: 'admin_approvals',
  timeTracking: 'time_tracking',
  facilityOnboarding60: 'facility_onboarding_6_0',
  locationGrouping: 'location_grouping',
  featureDashboard: 'feature_dashboard',
  analyticsDashboard: 'analytics_dashboard',
  contextualFields: 'contextual_fields',
  fgProPool: 'fg_pro_pool',
  credentialPdfs: 'fg_credential_pdfs',
};

export const experiments = {
  cancellationReductionXP: {
    name: 'xp_cancellation_reduction',
    parameters: {
      newFlow: 'new_flow',
    },
  },
  contextualFieldsXP: {
    name: 'xp_contextual_fields',
    parameters: {
      showFields: 'show_fields',
    },
  },
};
