// https://www.saintsatplay.com/blog/2014-08-02-handling-floating-point-numbers-in-javascript?s=2014/08/handling-floating-point-numbers-in-javascript
// This method fixes the inaccurate float math of JavaScript

export const dollarToCents = (dollar: any): number => {
  if (['string', 'undefined'].includes(typeof dollar)) {
    const parsedNum = Math.round(parseFloat((parseFloat(dollar) * 100).toFixed(2)));
    return Number.isNaN(parsedNum) ? 0 : parsedNum;
  } else {
    return Math.round(parseFloat((dollar * 100).toFixed(2)));
  }
};
