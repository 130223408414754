import { omit } from 'lodash';
import { Account } from 'graphql/generated/graphql';
import { axios } from 'utils/networkRequests';
import { SignUpValues } from 'components/Authorization/SignUp60/useSignUp';

export const auth = {
  acceptInvite: (values: { account: Partial<Account> }) =>
    axios.post(`/v3/facility/accounts/accept_invite`, {
      account: omit(values.account, ['email']),
    }),
  requestResetPassword: (values: unknown) =>
    axios.post('/v3/facility/accounts/request_reset_password', values, {
      headers: {
        accept: 'application/json',
      },
      withCredentials: true,
    }),
  resetPassword: (password: string, token: string) =>
    axios.post(`/v3/facility/accounts/reset_password`, {
      account: {
        password,
        token,
      },
    }),
  signUp: (
    values: SignUpValues,
    { utm_source, utm_campaign }: { utm_source?: string; utm_campaign?: string } = {},
  ) =>
    axios.post('/v3/facility/accounts/sign_ups', {
      account: { ...values, utm_source, utm_campaign },
    }),
};
