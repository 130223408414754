import { me } from './me';
import { approvals } from './approvals';
import { assignmentRequests } from './assignmentRequests';
import { assignments } from './assignments';
import { auth } from './auth';
import { billingGroups } from './billingGroups';
import { blocks } from './blocks';
import { disputes } from './disputes';
import { favorites } from './favorites';
import { jobs } from './jobs';
import { jobTemplates } from './jobTemplates';
import { locations } from './locations';
import { professionals } from './professionals';
import { scheduler } from './scheduler';
import { reviews } from './reviews';
import { teamMembers } from './teamMembers';

export const api = {
  me,
  approvals,
  assignmentRequests,
  assignments,
  auth,
  billingGroups,
  blocks,
  disputes,
  favorites,
  jobs,
  jobTemplates,
  locations,
  professionals,
  scheduler,
  reviews,
  teamMembers,
};
