import { GET_CURRENT_USER } from 'graphql/accountsQueries';
import { Account } from 'graphql/generated/graphql';
import config from 'config';
import { AxiosResponse } from 'utils/networkRequests';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import { useAuthContext } from '@medely/ui-kit/web';
import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import useHandleError from './useHandleError';
import { api } from 'api/index';

const REDIRECT_URLS = {
  administrator: config.adminUrl,
  professional: config.professionalUrl,
};

const useCurrentUser = (): {
  isLoading: boolean;
  currentUser: Account | undefined;
  updateNotifications: UseMutateFunction<AxiosResponse<any>, any, any, unknown>;
} => {
  const { request } = useGraphQLRequest();

  const { enqueueSnackbar } = useSnackbar();
  const onError = useHandleError();
  const { user, logout } = useAuthContext();

  const getCurrentUser = async () => {
    const { me } = await request(GET_CURRENT_USER);
    return me;
  };

  const enabled = !!user?.email && !(window as any)?.CypressSuppressUser;
  const { isLoading, data, isInitialLoading } = useQuery(
    ['currentUser', user?.email],
    getCurrentUser,
    {
      enabled,
      onSuccess: (account: Account) => {
        if (account.account_type !== 'facility') {
          logout().then(() => window.location.replace(REDIRECT_URLS[account.account_type]));
        }
      },
      onError: async (err: any) => {
        if (err.response?.errors.some((e) => e.extensions?.code === 'UNAUTHENTICATED')) {
          // logout();
          console.error(err);
        }
      },
    },
  );

  const { mutate: updateNotifications, isLoading: updating } = useMutation(
    api.me.actions.updateNotificationSettings,
    {
      onSuccess: () => {
        enqueueSnackbar('Notification setting(s) updated', { variant: 'success' });
      },
      onError,
    },
  );

  return {
    isLoading: updating || (enabled ? isLoading : isInitialLoading),
    currentUser: data,
    updateNotifications,
  };
};

export default useCurrentUser;
