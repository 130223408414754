import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import times from 'lodash/times';
import moment, { tz, Moment } from 'moment-timezone';
import { Job, Assignment, JobStatus } from 'graphql/generated/graphql';
import { getDayBreakdown, getWeekBreakdown, getMonthBreakdown } from 'utils/datetime';
import DisplayHelpers from 'utils/displayHelpers';
import getExpectedHoursPerShift from 'routes/assignments/utils/getExpectedHoursPerShift';

const TOTAL_NUMBER_OF_WEEKS = 52;
export const DAY_DISPLAYED_JOB_STATUSES: JobStatus[] = [
  JobStatus.Active,
  JobStatus.Booked,
  JobStatus.CanceledWithFee,
  JobStatus.ClockedIn,
  JobStatus.ClockedOut,
  JobStatus.Completed,
  JobStatus.Disputed,
  JobStatus.Draft,
  JobStatus.HeldForDisputeReview,
  JobStatus.MissedShift,
  JobStatus.NoShow,
];

export const WEEK_DISPLAYED_JOB_STATUSES: JobStatus[] = [
  JobStatus.Active,
  JobStatus.Booked,
  JobStatus.Canceled,
  JobStatus.CanceledWithFee,
  JobStatus.ClockedIn,
  JobStatus.ClockedOut,
  JobStatus.Completed,
  JobStatus.Disputed,
  JobStatus.Draft,
  JobStatus.HeldForDisputeReview,
  JobStatus.LateCancellation,
  JobStatus.MissedShift,
  JobStatus.NoShow,
  JobStatus.Unavailable,
  JobStatus.Unfilled,
];

export const MONTH_DISPLAYED_JOB_STATUSES: JobStatus[] = [
  JobStatus.Active,
  JobStatus.Booked,
  JobStatus.ClockedIn,
  JobStatus.ClockedOut,
  JobStatus.Completed,
  JobStatus.Disputed,
  JobStatus.Draft,
  JobStatus.HeldForDisputeReview,
  JobStatus.MissedShift,
  JobStatus.NoShow,
];

export function isLate(starts_time: Date | string | undefined, status = 'booked'): boolean {
  return !!(
    status === 'booked' &&
    starts_time &&
    moment(starts_time).add(30, 'm').isBefore(moment())
  );
}

export function isPastEndTime(
  ends_time: Date | string | undefined,
  status = 'clocked_in',
): boolean {
  return status === 'clocked_in' && !!ends_time && moment(ends_time).isBefore(moment());
}

export function isPastClockInTime(
  starts_time: Date | string | undefined,
  status = 'booked',
): boolean {
  return status === 'booked' && !!starts_time && moment(starts_time).isBefore(moment());
}

export function getAssignmentWithIssues<T extends Pick<Assignment, 'id'>>(
  assignments: T[],
  assignmentHoursByAssignment: Map<number, AssignmentHours>,
): T[] {
  return assignments.filter((assignment) => {
    const hours = assignmentHoursByAssignment.get(assignment.id) ?? {
      expectedHoursPerWeek: 0,
      guaranteedHoursPerWeek: 0,
      scheduledHours: 0,
    };
    const isUnderbooked = hours.scheduledHours < hours.expectedHoursPerWeek;
    return isUnderbooked;
  });
}

export function getAssignmentsWithJobIssues<
  A extends Pick<Assignment, 'id'>,
  J extends Pick<Job, 'assignment_id'>,
>(assignments: A[], jobsWithIssues: J[]): A[] {
  const assignmentIdsWithJobIssues = uniq(map(jobsWithIssues, (job) => job.assignment_id ?? -1));
  return assignments.filter((assignment) => assignmentIdsWithJobIssues.includes(assignment.id));
}

export function getJobsWithAssignmentIssues<
  A extends Pick<Assignment, 'id'>,
  J extends Pick<Job, 'assignment_id'>,
>(jobs: J[], assignmentsWithIssues: A[]): J[] {
  const assignmentIdsWithIssues = assignmentsWithIssues.map((assignment) => assignment.id);
  return jobs.filter((job) => {
    const assignmentId = job.assignment_id ?? 0;
    return assignmentIdsWithIssues.includes(assignmentId);
  });
}

export function getJobsWithIssues<
  T extends Pick<Job, 'approval_status' | 'starts_time' | 'status'>,
>(jobs: T[]): T[] {
  return jobs.filter(
    (job) =>
      isPastClockInTime(job.starts_time, job.status) ||
      job.status === 'held_for_dispute_review' ||
      DisplayHelpers.job.isAwaitingApproval(job.status, job.approval_status),
  );
}

export interface AssignmentHours {
  expectedHoursPerWeek: number;
  guaranteedHoursPerWeek: number;
  scheduledHours: number;
}

export type CalculateScheduledHoursByWeekByAssignment = Pick<
  Assignment,
  | 'ends_date'
  | 'ends_time'
  | 'existing_job_hours'
  | 'guaranteed_minimum_hours'
  | 'id'
  | 'number_of_shifts_per_week'
  | 'starts_date'
  | 'starts_time'
>;
export function calculateScheduledHoursByWeekByAssignment(
  assignments: CalculateScheduledHoursByWeekByAssignment[],
): Map<number, Map<number, AssignmentHours>> {
  // Weekly assignment hours are calculated in the PST/PDT timezone, not the facility timezone
  const timezone = 'America/Los_Angeles';

  const assignmentHoursByWeekByAssignment = new Map<number, Map<number, AssignmentHours>>();
  forEach(assignments, (assignment) => {
    const assignmentFirstWeek = moment.tz(assignment.starts_date, moment.ISO_8601, timezone).week();
    const assignmentLastWeek = moment.tz(assignment.ends_date, moment.ISO_8601, timezone).week();

    const expectedHoursPerShift = getExpectedHoursPerShift(
      assignment.starts_time,
      assignment.ends_time,
      timezone,
    );

    const expectedHoursPerWeek = expectedHoursPerShift * assignment.number_of_shifts_per_week;
    const guaranteedHoursPerWeek =
      assignment.guaranteed_minimum_hours * assignment.number_of_shifts_per_week;

    const iterations =
      assignmentFirstWeek >= assignmentLastWeek
        ? TOTAL_NUMBER_OF_WEEKS - assignmentFirstWeek + assignmentLastWeek + 1
        : assignmentLastWeek - assignmentFirstWeek + 1;

    times(iterations, (i) => {
      const itr = assignmentFirstWeek + i;
      const currentWeek = ((itr - 1) % TOTAL_NUMBER_OF_WEEKS) + 1;
      const scheduledHours = assignment.existing_job_hours;

      const assignmentHours: AssignmentHours = {
        expectedHoursPerWeek,
        guaranteedHoursPerWeek,
        scheduledHours,
      };

      // Make sure there's a map for this week
      if (!assignmentHoursByWeekByAssignment.has(currentWeek)) {
        assignmentHoursByWeekByAssignment.set(currentWeek, new Map());
      }

      const assignmentHoursByAssignment = assignmentHoursByWeekByAssignment.get(currentWeek)!;
      assignmentHoursByAssignment.set(assignment.id, assignmentHours);
    });
  });
  return assignmentHoursByWeekByAssignment;
}

const CALCULATABLE_JOB_STATUS = [
  'booked',
  'clocked_in',
  'clocked_out',
  'completed',
  'missed_shift',
  'canceled_with_fee',
];

type CalculateScheduledHoursJobInput = Pick<
  Job,
  'current_ends_time' | 'current_starts_time' | 'shift_type' | 'status'
>;

export function calculateScheduledHours(
  jobs: CalculateScheduledHoursJobInput[],
  {
    shouldBeRounded = true,
    initialHoursCount = 0,
  }: { shouldBeRounded?: boolean; initialHoursCount?: number } = {},
): number {
  const totalHours = jobs.reduce((acc: number, job) => {
    const jobStartsTime = moment(job.current_starts_time);
    const jobEndsTime = moment(job.current_ends_time);
    // To account for overnight shifts
    if (jobStartsTime.isSameOrAfter(jobEndsTime)) {
      jobEndsTime.add(1, 'day');
    }

    return job.shift_type !== 'on_call' && CALCULATABLE_JOB_STATUS.includes(job.status as JobStatus)
      ? acc + jobEndsTime.diff(jobStartsTime, 'hours', true)
      : acc;
  }, initialHoursCount);

  if (shouldBeRounded) {
    return Math.round(totalHours * 10) / 10;
  }

  return totalHours;
}

type IsWithinTimeSpanJobInput = Pick<Job, 'current_starts_time'> & {
  location: Pick<Job['location'], 'timezone_lookup'>;
};

export function isWithinTimeSpan(timeSpan: string, job: IsWithinTimeSpanJobInput): boolean {
  const [start, end] = timeSpan.split('-');
  const [startHours, startMinutes] = start.split(':').map(Number);
  const [endHours, endMinutes] = end.split(':').map(Number);

  const startTime = startHours * 60 + startMinutes;
  const endTime = endHours * 60 + endMinutes;

  const timezoneLookup = job.location.timezone_lookup;
  let jobStartTime = null;
  if (timezoneLookup && moment(job.current_starts_time).isValid()) {
    const jobStart = tz(job.current_starts_time, timezoneLookup).format('HH:mm');
    const [jobStartHours, jobStartMinutes] = jobStart.split(':').map(Number);
    jobStartTime = jobStartHours * 60 + jobStartMinutes;
  }

  if (jobStartTime !== null) {
    if (startTime < endTime) {
      return startTime <= jobStartTime && jobStartTime <= endTime;
    } else {
      return startTime <= jobStartTime || endTime >= jobStartTime;
    }
  }

  return false;
}

export function filterJobsForTimePeriod<
  T extends Pick<Job, 'ends_time' | 'starts_time' | 'status'>,
>(jobs: T[], timePeriod: string, date: Moment, timezone: string): T[] {
  switch (timePeriod) {
    case 'day':
      return filterForDay(jobs, date, timezone);
    case 'week':
      return filterForWeek(jobs, date, timezone);
    case 'month':
      return filterForMonth(jobs, date, timezone);
    default:
      return jobs;
  }
}

export function filterForDay<T extends Pick<Job, 'ends_time' | 'starts_time' | 'status'>>(
  jobs: T[],
  date: Moment,
  timezone: string,
): T[] {
  const { startOfDay, endOfDay } = getDayBreakdown(date);

  return jobs.filter((job) => {
    const jobStartTime = moment.tz(job.starts_time, timezone);
    const jobEndTime = moment.tz(job.ends_time, timezone);

    if (jobEndTime.isBefore(startOfDay) || jobStartTime.isAfter(endOfDay)) {
      return false;
    }

    if (!DAY_DISPLAYED_JOB_STATUSES.includes(job.status as JobStatus)) {
      return false;
    }

    return true;
  });
}

export function filterForWeek<T extends Pick<Job, 'starts_time' | 'status'>>(
  jobs: T[],
  date: Moment,
  timezone: string,
): T[] {
  const { startOfWeek, endOfWeek } = getWeekBreakdown(date);

  // Ensure jobs fit within our week view
  return jobs.filter((job) => {
    const jobStartTime = moment.tz(job.starts_time, timezone);

    if (!WEEK_DISPLAYED_JOB_STATUSES.includes(job.status as JobStatus)) {
      return false;
    }

    return jobStartTime.isSameOrAfter(startOfWeek) && jobStartTime.isSameOrBefore(endOfWeek);
  });
}

export function filterForMonth<T extends Pick<Job, 'starts_time' | 'status'>>(
  jobs: T[],
  date: Moment,
  timezone: string,
): T[] {
  const { startOfMonth, endOfMonth } = getMonthBreakdown(date);

  return jobs.filter((job) => {
    const jobStartDate = moment.tz(job.starts_time, timezone);

    if (jobStartDate.isBefore(startOfMonth) || jobStartDate.isAfter(endOfMonth)) {
      return false;
    }

    if (!MONTH_DISPLAYED_JOB_STATUSES.includes(job.status as JobStatus)) {
      return false;
    }

    return true;
  });
}

export function filterAssignmentsForWeek<T extends Pick<Assignment, 'ends_date' | 'starts_date'>>(
  assignments: T[],
  date: Moment,
  timezone: string,
) {
  return assignments.filter((assignment) => {
    const startsDate = moment.tz(assignment.starts_date, moment.ISO_8601, timezone);
    const endsDate = moment.tz(assignment.ends_date, moment.ISO_8601, timezone);

    return startsDate.isSameOrBefore(date, 'week') && endsDate.isSameOrAfter(date, 'week');
  });
}
