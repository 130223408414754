import { GET_COMPANY } from 'graphql/companyQueries';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import useCurrentUser from './useCurrentUser';
import useHandleError from './useHandleError';
import { type QueryFunctionContext, useQuery } from '@tanstack/react-query';

type GetCompanyInput = { companyId: number };
type GetCompany = QueryFunctionContext<[_entity: unknown, _id: unknown, input: GetCompanyInput]>;

const useCompany = () => {
  const { request } = useGraphQLRequest();
  const { currentUser } = useCurrentUser();
  const onError = useHandleError();

  const getCompany = async ({ queryKey: [_entity, _userId, { companyId }] }: GetCompany) => {
    const { company } = await request(GET_COMPANY, { id: companyId });
    return company;
  };
  const enabled = !!currentUser;
  const { isLoading, data, isInitialLoading } = useQuery(
    ['company', currentUser.id, { companyId: currentUser?.company_id }],
    getCompany,
    {
      enabled,
      onError,
    },
  );
  return { isLoading: enabled ? isLoading : isInitialLoading, company: data ?? [] };
};

export default useCompany;
