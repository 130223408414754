import {
  Box,
  BoxProps,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  TypographyProps,
} from '@medely/web-components';
import { ArrowDropDown } from '@mui/icons-material';
import React, { useState } from 'react';
import { useLocationContext } from 'context/locationContext';
import { useMenu } from 'hooks/useMenu';
import { useRouteMatch } from 'react-router-dom';
import { SearchRegular } from '@medely/web-components/icons';
import { Location } from 'graphql/generated/graphql';
import { gateNames } from 'constants/statsig';
import useGate from 'hooks/useGate';

type TopBarLocationSelectorProps = BoxProps & {
  textProps?: TypographyProps;
};

const useSelectorVisibility = () => {
  const { value: featureDashboard } = useGate(gateNames.featureDashboard);

  const isBilling = useRouteMatch('/billing');
  const isSettings = useRouteMatch('/settings');
  const isApprovals = useRouteMatch(featureDashboard ? '/dashboard/approvals' : '/approvals');
  const isDashboard = useRouteMatch('/dashboard');
  const isAnalytics = useRouteMatch('/analytics');
  return !isBilling && !isSettings && !isApprovals && !isDashboard && !isAnalytics;
};

const TopBarLocationSelector = ({
  textProps = {},
  ...rest
}: TopBarLocationSelectorProps): React.ReactElement | null => {
  const [query, setQuery] = useState('');
  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
  };
  const clearQuery = () => setQuery('');
  const { location, setLocation, allLocations } = useLocationContext();

  const { menuProps, triggerProps } = useMenu();
  const isVisible = useSelectorVisibility();
  const handleSelect = (location: Location) => {
    setLocation?.(location);
    menuProps.onClose();
  };
  const hasMultipleLocations = allLocations.length >= 2;

  if (!isVisible || allLocations.length === 0) {
    return null;
  }

  const locationText = (
    <Typography variant="body2" weight="medium" {...textProps}>
      {location?.label}&nbsp;&nbsp;
    </Typography>
  );

  const filteredLocations = allLocations.filter((loc) =>
    loc.name.toLowerCase().includes(query.toLowerCase()),
  );

  return (
    <>
      <Box {...rest} data-testid="header-menu-location">
        {hasMultipleLocations && (
          <Button
            endIcon={<ArrowDropDown />}
            {...triggerProps}
            variant="contained"
            color="secondary"
            sx={{
              paddingLeft: 3,
              width: '350px',
              justifyContent: 'space-between',
            }}
          >
            {locationText}
          </Button>
        )}
        {!hasMultipleLocations && locationText}
      </Box>

      <Menu
        {...menuProps}
        sx={{
          top: '5px',
          '& .MuiPaper-root': {
            width: '350px',
            maxHeight: '544px',
          },
          '& .MuiFormControl-root': {
            width: '100%',
          },
        }}
      >
        <Box width="100%" px="10px" mb={1}>
          <TextField
            size="small"
            placeholder="Find facility"
            fullWidth={true}
            sx={{ input: { fontSize: '14px' } }}
            value={query}
            onChange={handleQueryChange}
            InputProps={{
              onKeyDown: (e) => e.stopPropagation(),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRegular />
                </InputAdornment>
              ),
              endAdornment: query.length ? (
                <InputAdornment position="end">
                  <Button
                    onClick={clearQuery}
                    sx={{
                      height: '17px',
                      width: '17px',
                      borderRadius: '50%',
                      lineHeight: '17px',
                    }}
                  >
                    <Typography>×</Typography>
                  </Button>
                </InputAdornment>
              ) : null,
            }}
          />
        </Box>

        {filteredLocations.map((location) => (
          <MenuItem
            key={location.id}
            aria-label={location.name}
            onClick={() => handleSelect(location)}
            data-testid="header-menu-item"
            sx={{ textWrap: 'wrap', hyphens: 'auto' }}
          >
            {location.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TopBarLocationSelector;
