import { useState } from 'react';

export type UseDialog = {
  open: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  dialogProps: {
    open: boolean;
    onClose: () => void;
  };
};

export const useDialog = (openedByDefault = false): UseDialog => {
  const [open, setOpen] = useState(openedByDefault);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  return {
    open,
    openDialog,
    closeDialog,
    dialogProps: {
      open,
      onClose: closeDialog,
    },
  };
};

export default useDialog;
