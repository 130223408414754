import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Box } from '@medely/web-components';
import React from 'react';
import TopBarDefault from 'containers/TopBar/TopBarDefault';
import { useTheme } from '@medely/web-components/themes';
import TopBarShiftDetails from './TopBarShiftDetails';
import TopBarTitleBack from './TopBarTitleBack';
import { hiddenSideBarPaths } from 'containers/SideBar/SideBar';
import { gateNames } from 'constants/statsig';
import { useGate } from 'statsig-react';

const useTopBarMode = (): 'default' | 'clean' | 'none' => {
  const isJobsScheduler = useRouteMatch('/shifts/new');
  const isJobEdit = useRouteMatch('/shifts/:id/edit');
  const isAssignmentJob = useRouteMatch('/assignments/shifts/new');
  const isAssignmentsScheduler = useRouteMatch('/assignments/new');
  const isNewTemplate = useRouteMatch('/templates/new');
  const isEditTemplate = useRouteMatch('/templates/:id/edit');
  const isThankYou = useRouteMatch('/thank-you');
  const isProfessional = useRouteMatch('/workforce/:id');
  if (
    isJobsScheduler ||
    isJobEdit ||
    isAssignmentsScheduler ||
    isAssignmentJob ||
    isNewTemplate ||
    isEditTemplate ||
    isProfessional
  ) {
    return 'clean';
  }
  if (isThankYou) {
    return 'none';
  }
  return 'default';
};

type Props = {
  isImpersonateBannerVisible: boolean;
};

const TopBarContainer = ({ isImpersonateBannerVisible }: Props): React.ReactElement | null => {
  const { value: isAssignmentUpdateGateOn } = useGate(gateNames.assignmentsUpdatePhase1);

  const showSideBar = !hiddenSideBarPaths.some((path) => {
    return useRouteMatch(path);
  });
  const mode = useTopBarMode();
  const theme = useTheme();

  if (mode === 'none') {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        height: '60px',
        top: isImpersonateBannerVisible ? '42px' : 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.common.white,
        boxShadow: mode === 'default' || showSideBar ? 'none' : '0 4px 4px 0 rgba(0, 0, 0, 0.15)',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 10,
        borderBottom: mode === 'default' ? `solid 1px ${theme.palette.grey[100]}` : undefined,
      }}
    >
      <Switch>
        <Route path="/shifts/new">
          <TopBarTitleBack displayEndContent={true} />
        </Route>
        <Route path="/shifts/:id/edit">
          <TopBarTitleBack displayEndContent={true} />
        </Route>
        <Route path="/shifts/:id">
          <TopBarShiftDetails />
        </Route>
        <Route path={`/workforce/:id(\\d+)`}>
          <TopBarTitleBack />
        </Route>
        <Route path="/assignments/new">
          <TopBarTitleBack />
        </Route>
        <Route path="/assignments/shifts/new">
          <TopBarTitleBack displayEndContent={true} />
        </Route>
        <Route path="/assignments/:id/applications/:application_id/offer/:action">
          <TopBarTitleBack />
        </Route>
        <Route path="/assignments/:id/extension/new">
          <TopBarTitleBack />
        </Route>
        {isAssignmentUpdateGateOn && (
          <Route path="/assignments/:id/applications/:status">
            <TopBarTitleBack />
          </Route>
        )}
        <Route path="/assignments/:id/edit">
          <TopBarTitleBack />
        </Route>
        <Route path="/templates/:id/edit">
          <TopBarTitleBack />
        </Route>
        <Route path="/templates/new">
          <TopBarTitleBack />
        </Route>
        <Route path="/templates/manager">
          <TopBarTitleBack />
        </Route>
        <Route path="/billing/credit_memos/:id">
          <TopBarTitleBack />
        </Route>
        <Route path="/billing/debit_memos/:id">
          <TopBarTitleBack />
        </Route>
        <Route path="/billing/invoices/:id">
          <TopBarTitleBack />
        </Route>
        <Route path="/billing/payments/:id">
          <TopBarTitleBack />
        </Route>
        <Route>
          <TopBarDefault />
        </Route>
      </Switch>
    </Box>
  );
};

export default TopBarContainer;
