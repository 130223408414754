import { Box } from '@medely/web-components';
import Notifications from 'containers/TopBar/Notifications';
import React from 'react';
import TopBarMobileChat from 'containers/TopBar/TopBarMobile/TopBarMobileChat';
import TopBarMobileNavigation from 'containers/TopBar/TopBarMobile/TopBarMobileNavigation';
import TopBarMobileTitle from 'containers/TopBar/TopBarMobile/TopBarMobileTitle';
import { useRouteMatch } from 'react-router-dom';

const TopBarMobile = (): React.ReactElement => {
  const isScheduler = useRouteMatch('/scheduler');
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TopBarMobileNavigation />
        <TopBarMobileTitle />
      </Box>
      {isScheduler && (
        <Box>
          <TopBarMobileChat />
          <Notifications />
        </Box>
      )}
    </Box>
  );
};

export default TopBarMobile;
