import { gql } from 'graphql-request';

export const GET_BOOKED_ASSIGNMENTS = gql`
  query BookedAssignments($input: AssignmentQueryInput) {
    assignments(input: $input) {
      id
      starts_time
      ends_time
      ends_date
      professional {
        id
        account {
          first_name
          last_name
          name
        }
      }
      primary_location {
        id
        timezone_lookup
      }
      status
      all_conditions_met
      locations {
        id
      }
    }
  }
`;

export const GET_LOCATION_ASSIGNMENTS = gql`
  query LocationAssignments($input: AssignmentQueryInput) {
    assignments(input: $input) {
      id
      professional {
        account {
          first_name
          last_name
        }
      }
      status
      extension_id
      guaranteed_minimum_hours
      number_of_shifts_per_week
      starts_time
      ends_time
      extension_status
      created_at
      starts_date
      ends_date
      charge_base_hourly_rate_cents
      positions {
        kind
        display_name
        id
      }
      assignment_requests {
        viewed
        status
      }
      charge_on_call_hourly_rate_cents
      specialties {
        label
      }
      assignment_request_status_counts {
        active_count
        awaiting_count
        interview_count
        offer_count
      }
      primary_location {
        timezone_lookup
      }
    }
  }
`;

export const GET_LOCATION_ASSIGNMENTS_COUNT = gql`
  query LocationAssignments($input: AssignmentQueryInput) {
    _assignmentsMeta(input: $input) {
      count
    }
  }
`;

export const GET_EXPIRING_ASSIGNMENTS = gql`
  query ExpiringAssignments($input: AssignmentQueryInput) {
    assignments(input: $input) {
      id
      ends_date
      positions {
        display_name
      }
    }
  }
`;

export const GET_PROFESSIONAL_UNAVAILABILITIES = gql`
  query ProfessionalUnavailability($input: JobQueryInput!) {
    blockedJobs(input: $input) {
      id
      current_starts_time
      current_ends_time
      location_id
      location {
        timezone_lookup
      }
      professional_id
    }
  }
`;

export const GET_ORIGINAL_ASSIGNMENT = gql`
  query OriginalAssignment($id: Int!) {
    assignment(id: $id) {
      id
      ends_date
    }
  }
`;

export const GET_ASSIGNMENT = gql`
  query Assignment($id: Int!) {
    assignment(id: $id) {
      id
      professional {
        id
        account {
          first_name
          last_name
          name
        }
      }
      professional_number
      all_conditions_met
      status
      created_at
      starts_date
      ends_date
      charge_base_hourly_rate_cents
      charge_on_call_hourly_rate_cents
      positions {
        id
        kind
        display_name
        name
      }
      surgery_types {
        id
        display_name
      }
      specialties {
        id
        label
      }
      status
      locations {
        id
        name
        timezone_lookup
        unit
      }
      primary_location {
        timezone_lookup
        id
      }
      original_assignment_id
      starts_time
      ends_time
      is_extension
      number_of_shifts_per_week
      schedule_type
      patient_population
      scrubs_provided
      jobs_detail
      paused
      is_w2
      charge_base_hourly_rate_cents
      clinical_contact_account_id
      clinical_contact {
        id
      }
      posting_field_contents
    }
  }
`;

export const GET_ASSIGNMENTS = gql`
  query Assignments($input: AssignmentQueryInput, $range: DateRangeInput!) {
    assignments(input: $input) {
      id
      is_extension
      starts_date
      ends_date
      starts_time
      ends_time
      existing_job_hours(range: $range)
      primary_location {
        timezone_lookup
      }
      professional {
        id
        account {
          first_name
          last_name
          name
        }
      }
      positions {
        id
        kind
        name
        display_name
      }
      guaranteed_minimum_hours
      number_of_shifts_per_week
      all_conditions_met
    }
  }
`;

export const GET_ASSIGNMENTS_IDS = gql`
  query Assignments($input: AssignmentQueryInput) {
    assignments(input: $input) {
      id
    }
  }
`;
