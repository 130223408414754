import { AuthProvider, TokenType } from '@medely/ui-kit/web';
import React, { PropsWithChildren } from 'react';
import { auth } from 'utils/firebase';

export const FacilityAuthProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [token, tokenType] = getInitialTokenSettings();
  return (
    <AuthProvider
      auth={auth}
      testUser={(window as any).Cypress ? ({ email: 'test@example.com' } as any) : undefined}
      token={token}
      tokenType={tokenType}
      onTokenError={console.error}
    >
      {children}
    </AuthProvider>
  );
};

const getInitialTokenSettings = (): [string, TokenType] | [undefined, undefined] => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const token = searchParams.get('token');
  const impersonateToken = searchParams.get('impersonateToken');

  if (impersonateToken) {
    return [impersonateToken, 'impersonate'];
  }

  return [token, undefined];
};
