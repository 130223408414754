// @ts-nocheck
import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { CircularProgress, Box } from '@medely/web-components';
import { useDeviceInfo } from '@medely/web-components/hooks';
import FacilityView from './View';
import ProtectedRoute from 'components/Authorization/ProtectedRoute';
import moment from 'moment';
import { useAnalytics } from '@medely/ui-kit/web';
import { useBillingGroupContext } from 'context/billingGroupContext';
import useCurrentUser from 'hooks/useCurrentUser';
import useHandleError from 'hooks/useHandleError';
import { useLocationContext } from 'context/locationContext';
import { useQueryClient } from '@tanstack/react-query';
import config from 'config';
import { createRedirectSearch } from 'utils/createRedirectSearch';
import { RedirectWithParams } from 'components/RedirectWithParams';
import { MedelyLogo } from '@medely/web-components/icons';
import { useTheme } from '@medely/web-components/themes';
import { gateNames } from 'constants/statsig';
import useGate from 'hooks/useGate';
import useLandingUrl from 'hooks/useLandingUrl';

const SchedulerRoot = lazy(() => import('./routes/scheduler/Root'));
const DashboardRoot = lazy(() => import('./routes/dashboard/Root'));
const AnalyticsRoot = lazy(() => import('./routes/analytics/Root'));
const JobsRoot = lazy(() => import('./routes/jobs/JobsRoot'));
const TemplatesRoot = lazy(() => import('./routes/templates/TemplatesRoot'));
const AssignmentsRoot = lazy(() => import('./routes/assignments/AssignmentsRoot'));
const TimeTrackingRoot = lazy(() => import('./routes/timeTracking/TimeTrackingRoot'));
const AccountSettingsRoot = lazy(() => import('./routes/account_settings/AccountSettingsRoot'));
const BillingRoot = lazy(() => import('./routes/billing/Root'));
const FacilityDetailsRoot = lazy(() => import('./components/Authorization/FacilityDetails'));
const SettingsRoot = lazy(() => import('./routes/settings/SettingsRoot'));
const Login = lazy(() => import('./components/Authorization/Login'));
const ThankYou = lazy(() => import('./routes/thank-you/ThankYou'));
const SignUp = lazy(() => import('./components/Authorization/SignUp'));
const SignUp60 = lazy(() => import('./components/Authorization/SignUp60'));
const SetPassword = lazy(() => import('./components/Authorization/SetPassword'));
const ResetPassword = lazy(() => import('./components/Authorization/ResetPassword'));
const AcceptInvite = lazy(() => import('./components/Authorization/AcceptInvite/AcceptInvite'));
const PaymentGateway = lazy(() => import('./routes/hosted-billing/Root'));
const WorkforceRoot = lazy(() => import('./routes/workforce/Root'));
const ApprovalsRoot = lazy(() => import('./routes/dashboard/approvals/ApprovalsRoot'));

const AppRouter = () => {
  const { isLoading: loadingUser, currentUser } = useCurrentUser();
  const { isLoading: loadingLocations } = useLocationContext();
  const { isLoading: loadingBillingGroups } = useBillingGroupContext();
  const { value: facilityOnboarding60 } = useGate(gateNames.facilityOnboarding60);
  const { value: featureDashboard } = useGate(gateNames.featureDashboard);
  const { value: analyticsDashboard } = useGate(gateNames.analyticsDashboard);
  const landingUrl = useLandingUrl();

  const SignUpComponent = facilityOnboarding60 ? SignUp60 : SignUp;

  const search = createRedirectSearch(window.location);
  const onError = useHandleError();
  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // queries going stale was messing up our multi-page forms
      // we can change this to something more aggressive, but we need
      // to be very careful about queries that populate form options
      staleTime: Infinity,
      onError,
    },
    mutations: {
      onMutate: (context) => context,
      onSettled: () => {
        queryClient.invalidateQueries();
      },
      onError,
    },
  });

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const redirectUnverifiedCompany = () => {
    if (
      currentUser &&
      !currentUser.company.verified &&
      (facilityOnboarding60 ? currentUser.company.locations?.length : true) &&
      location.pathname !== '/thank-you'
    ) {
      history.push('/thank-you');
    }
  };
  useEffect(redirectUnverifiedCompany, [currentUser, location, facilityOnboarding60]);

  const analytics = useAnalytics();
  const deviceInfo = useDeviceInfo();

  const identifyUser = () => {
    if (currentUser) {
      analytics.identify(
        currentUser.id.toString(),
        {
          name: currentUser.name,
          email: currentUser.email,
          hide_default_launcher: true,
          facility: true,
          company_id: currentUser.company_id,
          signed_up_at: currentUser.sign_up_completed_at,
        },
        {
          Intercom: { user_hash: currentUser.user_hash },
        },
      );
      analytics.track('Facility Login', {
        Date: moment().format('MM/DD/YYYY'),
        OperatingSystem: deviceInfo.current.operatingSystem,
        OperatingSystemVersion: deviceInfo.current.operatingSystemVersion,
        BrowserVersion: deviceInfo.current.browserVersion,
        Device: deviceInfo.current.device,
      });
    }
  };
  useEffect(identifyUser, [currentUser?.id]);

  useEffect(() => {
    analytics.page('Facilities', location.pathname);
  }, [location.pathname]);

  if (loadingUser || loadingLocations || loadingBillingGroups) {
    return <CircularProgress />;
  }
  if (!currentUser) {
    return (
      <Suspense fallback={<CircularProgress />}>
        <Switch>
          <Route path={`${config.root}/login`} component={Login} />
          <Route path={`${config.root}/sign-up`} component={SignUpComponent} />
          <Route path={`${config.root}/set-password`} component={SetPassword} />
          <Route path={`${config.root}/reset-password`} component={ResetPassword} />
          <Route path={`${config.root}/invite`} component={AcceptInvite} />
          <Route path={`${config.root}/hosted-billing`} component={PaymentGateway} />
          <Redirect to={{ pathname: `${config.root}/login`, search: search }} />
        </Switch>
      </Suspense>
    );
  }

  if (currentUser && !currentUser.company.verified && facilityOnboarding60) {
    return (
      <Box
        padding={3}
        bgcolor="grey.50"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="100vh"
        width="100vw"
      >
        <Box width="100%">
          <MedelyLogo color={theme.palette.primary.main} width="112" height="33" />
        </Box>
        <Suspense fallback={<CircularProgress />}>
          <Switch>
            <Route path={`${config.root}/facility-details`} component={FacilityDetailsRoot} />
            <Route path={`${config.root}/thank-you`} component={ThankYou} />
            <Redirect to={{ pathname: `${config.root}/facility-details` }} />
          </Switch>
        </Suspense>
      </Box>
    );
  }

  return (
    <FacilityView>
      <Suspense fallback={<CircularProgress />}>
        <Switch>
          <Route path={`${config.root}/thank-you`} component={ThankYou} />
          <Route path={`${config.root}/account-settings`} component={AccountSettingsRoot} />
          {featureDashboard && (
            <ProtectedRoute
              roles={['company_admin', 'facility_manager', 'scheduler']}
              path={`${config.root}/dashboard`}
              component={DashboardRoot}
            />
          )}
          <ProtectedRoute
            roles={['company_admin', 'facility_manager', 'view_only', 'scheduler']}
            path={`${config.root}/scheduler`}
            component={SchedulerRoot}
          />
          <ProtectedRoute
            roles={['company_admin', 'facility_manager', 'view_only', 'scheduler']}
            path={`${config.root}/shifts`}
            component={JobsRoot}
          />
          <ProtectedRoute
            roles={['company_admin', 'facility_manager', 'scheduler']}
            path={`${config.root}/templates`}
            component={TemplatesRoot}
          />
          <ProtectedRoute
            roles={['company_admin', 'facility_manager', 'view_only', 'scheduler']}
            path={`${config.root}/assignments`}
            component={AssignmentsRoot}
          />
          {!featureDashboard && (
            <ProtectedRoute
              roles={[
                'company_admin',
                'facility_manager',
                'scheduler',
                'billing_manager',
                'professional_manager',
                'view_only',
              ]}
              path={`${config.root}/approvals`}
              component={ApprovalsRoot}
            />
          )}
          <ProtectedRoute
            roles={['company_admin', 'facility_manager', 'view_only', 'scheduler']}
            path={`${config.root}/time-tracking`}
            component={TimeTrackingRoot}
          />
          <ProtectedRoute
            roles={['company_admin', 'facility_manager', 'billing_manager']}
            path={`${config.root}/billing`}
            component={BillingRoot}
          />
          {analyticsDashboard && (
            <ProtectedRoute
              roles={['company_admin', 'facility_manager', 'scheduler']}
              path={`${config.root}/analytics`}
              component={AnalyticsRoot}
            />
          )}
          <ProtectedRoute
            roles={['company_admin', 'facility_manager', 'billing_manager']}
            path={`${config.root}/settings`}
            component={SettingsRoot}
          />
          <ProtectedRoute
            roles={['company_admin', 'facility_manager', 'professional_manager']}
            path={`${config.root}/workforce`}
            component={WorkforceRoot}
          />
          <RedirectWithParams from={`${config.root}/jobs/*`} to={`${config.root}/shifts`} />
          <Redirect to={`${config.root}${landingUrl}`} />
          {featureDashboard && (
            <Redirect from={`${config.root}/approvals`} to={`${config.root}/dashboard/approvals`} />
          )}
        </Switch>
      </Suspense>
    </FacilityView>
  );
};

export default AppRouter;
