import { Redirect, Route, RouteProps } from 'react-router';
import React from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { useConfigurationContext } from '@medely/web-components/contexts';
import useLandingUrl from 'hooks/useLandingUrl';

type ProtectedRouteProps = RouteProps & {
  roles: string[];
  callback?: () => boolean;
};

const ProtectedRoute = ({ roles, callback, ...rest }: ProtectedRouteProps) => {
  const { currentUser } = useCurrentUser();
  const config = useConfigurationContext();
  const userRoles = currentUser?.permission_groups?.map((p) => p.role) ?? [];

  const permittedByRoles = userRoles.some((r) => roles?.includes(r));
  const permitted = callback ? permittedByRoles && callback() : permittedByRoles;

  const redirect = useLandingUrl();

  return permitted ? <Route {...rest} /> : <Redirect to={`${config.root}${redirect}`} />;
};

export default ProtectedRoute;
