export type PositionKind = 'registered_nurse' | 'allied' | 'lvn' | 'imaging_services';

type ProKind = {
  [key in PositionKind]: {
    kind: PositionKind;
    title: string;
  };
};

const PRO_KINDS: ProKind = {
  registered_nurse: {
    kind: 'registered_nurse',
    title: 'Nurses',
  },
  allied: {
    kind: 'allied',
    title: 'Allied',
  },
  lvn: {
    kind: 'lvn',
    title: 'LVN',
  },
  imaging_services: {
    kind: 'imaging_services',
    title: 'Imaging Services',
  },
};

export default PRO_KINDS;
