import React from 'react';
import { Box, FormControlLabel, Switch, Typography } from '@medely/web-components';

type Props = {
  itemKey: string;
  isChecked: boolean;
  onChange: (key: string, checked: boolean) => void;
  label: string;
  description: string;
};

const NotificationToggleItem = ({ itemKey, isChecked, onChange, label, description }: Props) => {
  return (
    <FormControlLabel
      labelPlacement="start"
      control={
        <Switch
          onChange={(event) => onChange(itemKey, event.target.checked)}
          checked={isChecked}
          data-testid="notification-switch"
        />
      }
      sx={{ width: '100%', justifyContent: 'space-between', m: 0, py: 1 }}
      label={
        <Box>
          <Typography weight="medium">{label}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      }
    />
  );
};

export default NotificationToggleItem;
