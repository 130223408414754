import React from 'react';
import { Box, Divider, Link, Typography } from '@medely/web-components';
import { PhoneField } from '@medely/web-components/forms';
import { composeValidation, phone, required } from 'core/utils/validation';
import { notificationItems } from './constants';
import NotificationToggleItem from './NotificationToggleItem';
import { MedelyConfiguration } from '@medely/base';
import useCurrentUser from 'hooks/useCurrentUser';
import { hasPermittedRoles } from 'utils/rolePermissions';

const phoneValidation = composeValidation(required, phone);

type Props = {
  handleSubmit: () => void;
  hasMobileNumber: boolean;
  submitSucceeded: boolean;
  values: Record<string, any>;
  change: (name: string, value: boolean) => void;
  config: MedelyConfiguration;
};

const FormContainer = ({
  handleSubmit,
  hasMobileNumber,
  submitSucceeded,
  values,
  change,
  config,
}: Props) => {
  const { currentUser } = useCurrentUser();
  const errorText = submitSucceeded
    ? 'The number you provided is not a mobile number. Please use a mobile number to receive text notifications.'
    : 'Please use a mobile number.';

  return (
    <form onSubmit={handleSubmit} data-testid="text-notifications-dialog">
      <Box marginBottom={1}>
        <Typography weight="medium" mb={2}>
          Introducing text notifications. Update your settings below to turn them on.
        </Typography>
        <PhoneField name="phone_number" validate={phoneValidation} />
        {!hasMobileNumber && (
          <Typography variant="body2" mt={1} color="error">
            {errorText}
          </Typography>
        )}
      </Box>
      <Box mb={3}>
        {notificationItems.map(({ key, label, description, permittedRoles }) => {
          if (
            hasPermittedRoles(permittedRoles, currentUser?.permission_groups) ||
            !permittedRoles
          ) {
            return (
              <Box key={key}>
                <NotificationToggleItem
                  itemKey={key}
                  isChecked={values[key]}
                  onChange={change}
                  label={label}
                  description={description ?? ''}
                />
                <Divider />
              </Box>
            );
          }
        })}
      </Box>
      <Typography variant="body2">
        By proceeding, I am also consenting to receive SMS messages from Medely and its affiliates
        to the number provided. Message and data rates may apply. Message frequency varies. Text
        &quot;HELP&quot; for help. Text &quot;STOP&quot; to opt out.{' '}
        <Link href={config.help.sms} external>
          Medely SMS Terms
        </Link>{' '}
        and{' '}
        <Link href={config.help.privacyPolicy} external>
          Privacy Policy
        </Link>
        .
      </Typography>
    </form>
  );
};

export default FormContainer;
