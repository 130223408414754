import { type ResultOf } from '@graphql-typed-document-node/core';
import { useAuthContext } from '@medely/ui-kit/web';
import { usePreferences } from '@medely/web-components/hooks';
import config from 'config';
import { graphql } from 'graphql/generated';
import type { Account, BillingGroup, Location } from 'graphql/generated/graphql';
import isNil from 'lodash/isNil';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Statsig, StatsigProvider, StatsigUser } from 'statsig-react';
import { useGraphQLQuery } from '../hooks/useGraphQLRequest';

interface IContext {
  isLoading: boolean;
  isUpdatingUser: boolean;
  selectedBillingGroupId: number | undefined;
  selectedLocationId: number | undefined;
  setBillingGroupId?: (billingGroupId: number) => void;
  setCustomAttributes?: (
    customAttributes: Record<string, string | number | boolean | string[]>,
  ) => void;
  setLocationId?: (locationId: number) => void;
  statsigUser?: StatsigUser;
}

const FeatureGateContext = createContext({
  isLoading: true,
  isUpdatingUser: true,
  selectedBillingGroupId: undefined,
  selectedLocationId: undefined,
  setBillingGroupId: undefined,
  setCustomAttributes: undefined,
  setLocationId: undefined,
  statsigUser: undefined,
});

const StatsigSetupGql = graphql(`
  query StatsigSetup($locationInput: LocationQueryInput, $billingInput: BillingGroupQueryInput) {
    activeBillingGroups: billingGroups(input: $billingInput) {
      id
    }
    activeLocations: locations(input: $locationInput) {
      id
      location_type_id
      market_id
    }
    me {
      company_id
      email
      id
    }
  }
`);

type StatsigBillingGroup = Pick<BillingGroup, 'id'>;
type StatsigLocation = Pick<Location, 'id' | 'location_type_id' | 'market_id'>;
type StatsigSetupResult = {
  currentUser: Pick<Account, 'company_id' | 'email' | 'id'>;
  selectedBillingGroup: StatsigBillingGroup;
  selectedLocation: StatsigLocation;
};

const useStatsigSetup = (
  billingGroupId: number | undefined,
  locationId: number | undefined,
): StatsigSetupResult & { isLoading: boolean } => {
  const { user } = useAuthContext();
  const enabled = !!user;

  const { data, isLoading, isInitialLoading } = useGraphQLQuery<ResultOf<typeof StatsigSetupGql>>({
    enabled,
    query: StatsigSetupGql,
    operationName: 'StatsigSetup',
    paramsFn: () => ({
      billingInput: {
        filter: {
          status: 'active',
        },
        orderBy: {
          name: true,
        },
      },
      locationInput: {
        filter: {
          status: 'active',
        },
        orderBy: {
          name: true,
        },
      },
    }),
    variables: {},
  });

  const selectedBillingGroup = findByIdOrFirst(data?.activeBillingGroups, billingGroupId);
  const selectedLocation = findByIdOrFirst(data?.activeLocations, locationId);

  return {
    currentUser: data?.me,
    isLoading: enabled ? isLoading : isInitialLoading,
    selectedBillingGroup,
    selectedLocation,
  };
};

const findByIdOrFirst = <T extends { id: number }>(
  list: Array<T> | undefined,
  id: number | undefined,
) => {
  if (!list?.length) {
    return;
  }

  if (isNil(id)) {
    return list[0];
  }

  return list.find((item) => item.id === id);
};

const GetJobCountForStatsigGql = graphql(`
  query GetJobCountForStatsig($input: JobQueryInput) {
    jobCount(input: $input) {
      count
    }
  }
`);

type UseJobCountForStatsigInput = {
  locationId: number | undefined;
};

const useJobCountForStatsig = (location: StatsigLocation) => {
  const enabled = !!location;

  const { data, isLoading, isInitialLoading } = useGraphQLQuery<
    ResultOf<typeof GetJobCountForStatsigGql>,
    UseJobCountForStatsigInput
  >({
    enabled,
    query: GetJobCountForStatsigGql,
    operationName: 'GetJobCountForStatsig',
    variables: { locationId: location?.id },
    paramsFn: ({ locationId }) => ({
      input: {
        search: {
          location_ids: [locationId],
        },
      },
    }),
  });

  return {
    isLoading: enabled ? isLoading : isInitialLoading,
    locationJobCount: data?.jobCount.count,
  };
};

export const FeatureGateProvider = ({
  children,
}: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  const { isImpersonating, user } = useAuthContext();
  const { preferences, updatePreferences } = usePreferences(user?.email);
  const [customAttributes, setCustomAttributes] = useState<Record<string, unknown>>({});
  const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(true);

  const {
    currentUser,
    isLoading: isLoadingSetup,
    selectedBillingGroup,
    selectedLocation,
  } = useStatsigSetup(preferences.defaultBillingGroupId, preferences.defaultLocationId);

  const { locationJobCount, isLoading: isLoadingJobCount } =
    useJobCountForStatsig(selectedLocation);

  const [statsigUser, setStatsigUser] = useState<StatsigUser | undefined>();
  /**
   * Function for setting custom attributes in the current statsig user.  This can be used to set custom attributes
   * based on current data from the page for more complicated feature gates/experiements (ex. gating based on the location_id
   * of a job the pro is currently clocking out of)
   */
  const updateCustomAttributes = (
    newCustomAttributes: Record<string, string | number | boolean>,
  ) => {
    setCustomAttributes({ ...customAttributes, ...newCustomAttributes });
  };

  const isLoading = [isLoadingSetup, isLoadingJobCount].some(
    (isLoadingResource) => isLoadingResource,
  );

  // Update statsig user when important user values change
  useEffect(() => {
    if (!isLoading && (currentUser?.id || customAttributes.billingGroupUuid)) {
      setIsUpdatingUser(true);
      setStatsigUser({
        userID: `account_${currentUser?.id}`,
        email: currentUser?.email,
        custom: {
          billingGroupId: selectedBillingGroup?.id,
          companyID: currentUser?.company_id,
          locationID: selectedLocation?.id,
          locationJobCount,
          locationTypeId: selectedLocation?.location_type_id,
          isImpersonating: isImpersonating,
          marketId: selectedLocation?.market_id,
          ...customAttributes,
        },
        customIDs: {
          billing_group_id: String(selectedBillingGroup?.id ?? ''),
          company_id: String(currentUser?.company_id ?? ''),
          location_id: String(selectedLocation?.id ?? ''),
        },
      });
    }
  }, [
    customAttributes,
    isImpersonating,
    isLoadingJobCount,
    isLoadingSetup,
    locationJobCount,
    selectedBillingGroup,
    selectedLocation,
  ]);

  if (!!window.Cypress) {
    (window as any).Statsig = Statsig;
  }

  return (
    <FeatureGateContext.Provider
      value={{
        isLoading,
        isUpdatingUser,
        selectedBillingGroupId: selectedBillingGroup?.id,
        selectedLocationId: selectedLocation?.id,
        setBillingGroupId: (billingGroupId: number) => {
          updatePreferences({ defaultBillingGroupId: billingGroupId });
        },
        setCustomAttributes: updateCustomAttributes,
        setLocationId: (locationId: number) => {
          updatePreferences({ defaultLocationId: locationId });
        },
        statsigUser,
      }}
    >
      <StatsigProvider
        sdkKey={config.statsigSdkKey}
        waitForInitialization={true}
        user={statsigUser}
        mountKey="statsig-prevent-dismount"
        options={{
          updateUserCompletionCallback: () => setIsUpdatingUser(false),
        }}
      >
        {children}
      </StatsigProvider>
    </FeatureGateContext.Provider>
  );
};

export const useFeatureGateContext = (): IContext => useContext(FeatureGateContext);

export default FeatureGateProvider;
