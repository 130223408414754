import useCompany from './useCompany';
import { useHistory } from 'react-router';

const useRedirectDisabledCompany = () => {
  const history = useHistory();
  const { company } = useCompany();
  if (company?.disabled_at) {
    history.push('/');
  }
};

export default useRedirectDisabledCompany;
