import { gql } from 'graphql-request';

export const GET_TEAM_MEMBERS = gql`
  query Accounts($input: AccountQueryInput) {
    accounts(input: $input) {
      id
      first_name
      last_name
      name
      title
      last_sign_in_at
      email
      phone_number
      account_locations {
        location_id
        source
      }
      status
      locations {
        id
        name
        status
      }
      billing_groups {
        id
        name
        status
      }
      permission_groups {
        id
        display_name
      }
    }
    _accountsMeta(input: $input) {
      count
    }
  }
`;

export const GET_TEAM_MEMBER = gql`
  query Account($id: Int!) {
    account(id: $id) {
      id
      first_name
      calculated_group_node_ids
      group_node_ids
      last_name
      account_locations {
        location_id
        source
      }
      company_id
      name
      last_sign_in_at
      email
      phone_number
      status
      locations {
        id
        name
        status
      }
      billing_groups {
        id
        name
        status
        locations {
          id
        }
      }
      permission_groups {
        id
        display_name
        role
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query Me {
    me {
      id
      user_hash
      first_name
      last_name
      calculated_group_node_ids
      group_node_ids
      name
      status
      last_sign_in_at
      sign_up_completed_at
      account_locations {
        location_id
        source
      }
      created_at
      account_type
      email
      company_id
      phone_number
      phone_number_sms_enabled
      company {
        name
        verified
        onboarding_complete
        saas_credentialing_enabled
        hide_invoicing_during_onboarding
        saas_scheduling_enabled
        agency_enabled
        display_pro_ehr_information
        dispute_submission_window_days
        locations {
          id
          name
          allow_assignments
        }
        billing_groups {
          id
        }
        has_onboarded_location
        has_billing_manager
      }
      notification_settings
      permission_groups {
        role
      }
      announcements_already_seen
    }
  }
`;
