import { Box } from '@medely/web-components';
import Onboarding from 'routes/onboarding/Onboarding';
import PagePadding from 'containers/PagePadding';
import React from 'react';
import Reminders from 'components/Reminders/Reminders';
import TopBar from 'containers/TopBar';
import SideBar from 'containers/SideBar';
import Unimpersonate from 'components/Unimpersonate';
import useRedirectDisabledCompany from 'hooks/useRedirectDisabledCompany';
import FeatureAnnouncements from 'components/FeatureAnnouncements';
import useSnackbar from 'hooks/useSnackbar';
import { hiddenSideBarPaths } from 'containers/SideBar/SideBar';
import { useLocation } from 'react-router';
import FacilityTermsOfServiceModal from 'components/TermsOfService/FacilityTermsOfServiceModal';
import { useIsImpersonating } from 'hooks/useIsImpersonating';
import useDevice from 'hooks/useDevice';

interface IFacilityViewProps {
  children?: React.ReactNode | React.ReactNode[];
}

const FacilityView = ({ children }: IFacilityViewProps): React.ReactElement => {
  const isDesktop = useDevice() === 'desktop';
  const location = useLocation();
  const noLeftPadding = hiddenSideBarPaths.includes(location.pathname) || !isDesktop;
  useRedirectDisabledCompany();
  const snackbar = useSnackbar();
  const { isImpersonating, isImpersonateBannerVisible, setIsImpersonateBannerVisible } =
    useIsImpersonating();

  return (
    <>
      <Unimpersonate
        isImpersonating={isImpersonating}
        isImpersonateBannerVisible={isImpersonateBannerVisible}
        setIsImpersonateBannerVisible={setIsImpersonateBannerVisible}
      />
      <Box
        display="flex"
        flexDirection="column"
        style={{
          minHeight: '100vh',
          width: 'fit-content',
          minWidth: '100%',
          paddingLeft: noLeftPadding ? 0 : '88px',
        }}
        sx={{ bgcolor: 'background.default' }}
        pb={8}
        data-testid="header-notifications-banner"
      >
        <PagePadding />
        <Reminders />
        <FacilityTermsOfServiceModal
          onSuccess={() => {
            snackbar.success('Success');
          }}
          onError={(message) => {
            snackbar.error(message);
          }}
        />
        <Box display="flex" mx="auto" flex="1 1 auto" width={1} alignItems="stretch">
          {children}
        </Box>
      </Box>
      <SideBar isImpersonateBannerVisible={isImpersonateBannerVisible} />
      <TopBar isImpersonateBannerVisible={isImpersonateBannerVisible} />
      <Onboarding />
      <FeatureAnnouncements />
    </>
  );
};

export default FacilityView;
