import { useQuery } from '@tanstack/react-query';
import { GET_APPROVAL_GROUPS } from 'graphql/approvalsQueries';
import useGraphQLRequest from 'hooks/useGraphQLRequest';
import { ApprovalGroup } from 'graphql/generated/graphql';

// Gets the active approval groups the logged in account is a part of.
export const useApprovalGroups = (): {
  isLoading: boolean;
  approvalGroups: ApprovalGroup[];
} => {
  const { request } = useGraphQLRequest();

  const getApprovalGroups = async (): Promise<ApprovalGroup[]> => {
    const { approvalGroups } = await request(GET_APPROVAL_GROUPS, {
      input: {
        search: { is_active: true },
      },
    });
    return approvalGroups;
  };
  const { isLoading, data } = useQuery(['approvalGroups'], getApprovalGroups);

  return { isLoading, approvalGroups: data ?? [] };
};
