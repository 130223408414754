import './styles/global.css';
import React, { useEffect } from 'react';
import { ThemeProvider, facilityLightTheme } from '@medely/web-components/themes';
import AppRouter from 'AppRouter';
import { BillingGroupProvider } from 'context/billingGroupContext';
import { Box, CircularProgress } from '@medely/web-components';
import { LocationProvider } from 'context/locationContext';
import { ReactQueryProvider } from 'context/reactQueryContext';
import { FeatureGateProvider, useFeatureGateContext } from 'context/featureGateContext';
import GraphQLPermissionsContextProvider, {
  useGraphQLPermissionsContext,
} from 'context/graphqlContext';
import intercomMethod from './utils/Intercom/method';
import { useLocation } from 'react-router';
import {
  GooglePlacesProvider,
  StripeProvider,
  useConfigurationContext,
} from '@medely/web-components/contexts';
import { useAuthContext } from '@medely/ui-kit/web';

const App = () => {
  const { isReady: authContextIsReady } = useAuthContext();
  const location = useLocation();
  const config = useConfigurationContext();
  useEffect(() => {
    intercomMethod('update');
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (!authContextIsReady && !(window as any)?.Cypress) {
    return <Loader />;
  }

  return (
    <ReactQueryProvider>
      <FeatureGateProvider>
        <GraphQLPermissionsContextProvider>
          <LocationProvider>
            <StripeProvider stripeKey={config.stripePublishableKey}>
              <BillingGroupProvider>
                <ThemeProvider theme={facilityLightTheme}>
                  <GooglePlacesProvider googlePlacesKey={process.env.REACT_APP_GOOGLE_PLACES_API}>
                    <LoadingBox>
                      <AppRouter />
                    </LoadingBox>
                  </GooglePlacesProvider>
                </ThemeProvider>
              </BillingGroupProvider>
            </StripeProvider>
          </LocationProvider>
        </GraphQLPermissionsContextProvider>
      </FeatureGateProvider>
    </ReactQueryProvider>
  );
};

const LoadingBox = ({ children }: { children: React.ReactNode }) => {
  const { isLoading: isLoadingFeatureGateContext } = useFeatureGateContext();
  const { isLoading: isLoadingGraphQLPermissions } = useGraphQLPermissionsContext();
  const isLoading = [isLoadingFeatureGateContext, isLoadingGraphQLPermissions].some(
    (isLoadingContext) => isLoadingContext,
  );

  if (isLoading) {
    return <Loader />;
  }

  return <>{children}</>;
};

const Loader = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress size={20} />
  </Box>
);

export default App;
