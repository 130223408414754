import { AssignmentRequest } from 'graphql/generated/graphql';
import { axios } from 'utils/networkRequests';

export const assignmentRequests = {
  actions: {
    markAsViewed: (request: AssignmentRequest) =>
      axios.post(
        `/v3/facility/assignments/${request.assignment_id}/assignment_requests/${request.id}/view`,
      ),
    saveAsFavorite: (request: AssignmentRequest) =>
      axios.put(
        `/v3/facility/assignments/${request.assignment_id}/assignment_requests/${request.id}`,
        {
          assignment_request: {
            is_favorite: true,
          },
        },
      ),
    removeAsFavorite: (request: AssignmentRequest) =>
      axios.put(
        `/v3/facility/assignments/${request.assignment_id}/assignment_requests/${request.id}`,
        {
          assignment_request: {
            is_favorite: false,
          },
        },
      ),
    rejectOffer: (
      assignmentRequest: AssignmentRequest,
      values: { reason: string; other_reason?: string },
    ) =>
      axios.post(
        `/v3/facility/assignments/${assignmentRequest.assignment_id}/assignment_requests/${assignmentRequest.id}/reject`,
        {
          reject: {
            reason: values.reason,
          },
        },
      ),
    revokeOffer: (assignmentRequest: AssignmentRequest) =>
      axios.post(
        `/v3/facility/assignments/${assignmentRequest.assignment_id}/assignment_requests/${assignmentRequest.id}/revoke_offer`,
      ),
    sendOffer: (assignmentRequest: AssignmentRequest) =>
      axios.post(
        `/v3/facility/assignments/${assignmentRequest.assignment_id}/assignment_requests/${assignmentRequest.id}/send_offer`,
      ),
  },
};
