import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from 'utils/displayHelpers';

export default function useHandleError(error?: Error | null) {
  const snackbar = useSnackbar();

  const handleError = (error) => {
    console.error('error', error);
    const message = getErrorMessage(error);
    if (!Boolean(window.Cypress)) {
      snackbar.enqueueSnackbar(message, { variant: 'error' });
    }

    return error;
  };

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return handleError;
}
