import { type ResultOf } from '@graphql-typed-document-node/core';
import { useAnalytics } from '@medely/ui-kit/web';
import { Button, Typography } from '@medely/web-components';
import { useLocationContext } from 'context/locationContext';
import { graphql } from 'graphql/generated';
import { usePerDiemEvent } from 'hooks/analytics/usePerDiemEvent';
import { useGraphQLQuery } from 'hooks/useGraphQLRequest';
import { toNumber } from 'lodash';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';

const TopBarJobRightContentGql = graphql(`
  query GetJobForTopBarRightContent($id: Int!) {
    job(id: $id) {
      assignment_id
      id
      status
    }
  }
`);

const TopBarJobRightContent = () => {
  const { location } = useLocationContext();
  const { id } = useParams<{ id: string }>();
  const { data } = useGraphQLQuery<ResultOf<typeof TopBarJobRightContentGql>>({
    operationName: 'GetJobForTopBarRightContent',
    query: TopBarJobRightContentGql,
    variables: { id: toNumber(id) },
    enabled: !!toNumber(id),
  });

  const job = data?.job;

  const history = useHistory();
  // Redundant parallel events until deprecated ones are ready to be remove
  const { trackClickedApplyTemplate } = usePerDiemEvent();
  const analytics = useAnalytics();

  const isAssignment =
    !!job?.assignment_id || job?.assignment_id === 0 || useRouteMatch('/assignments');
  const isBooked = job?.status === 'booked';

  if (isAssignment) {
    return (
      <Typography variant="subtitle1" color="textSecondary">
        {location?.label}
      </Typography>
    );
  }

  if (isBooked) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      onClick={() => {
        trackClickedApplyTemplate({
          location_id: location.id,
        });
        analytics.track('Clicked Apply a Template', {
          location_id: location.id,
        });
        history.push('/templates');
      }}
      data-testid="apply-template-button"
    >
      Apply a Template
    </Button>
  );
};

export default TopBarJobRightContent;
