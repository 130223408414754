import { BillingGroup } from 'graphql/generated/graphql';
import { useMemo } from 'react';

const useBillingValues = (billingGroup: BillingGroup | undefined) =>
  useMemo(
    () => ({
      id: billingGroup?.id,
      name: billingGroup?.name,
      stripe_token: undefined,
    }),
    [billingGroup],
  );

export default useBillingValues;
