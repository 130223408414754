import { pick } from 'lodash';
import useCurrentUser from 'hooks/useCurrentUser';
import useHandleError from 'hooks/useHandleError';
import { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { api } from 'api/index';
import { Account } from 'graphql/generated/graphql';

const useAccountUpdate = () => {
  const { currentUser } = useCurrentUser();
  const snackbar = useSnackbar();
  const onError = useHandleError();
  const initialValues = useMemo(
    () => pick(currentUser, ['first_name', 'last_name', 'phone_number', 'email']),
    [currentUser],
  );

  const { mutate: mutateWithToast } = useMutation(
    (values: Partial<Account>) => api.me.update(values, currentUser.id),
    {
      onSuccess: () => {
        snackbar.success('Your account has been successfully updated');
      },
      onError,
    },
  );

  const { mutate: mutateWithoutToast } = useMutation((values: Partial<Account>) =>
    api.me.update(values, currentUser.id),
  );

  const updateAccount = (values) => {
    mutateWithToast(values);
  };

  const updateSeenAnnouncements = (values) => {
    mutateWithoutToast(values);
  };

  return { initialValues, updateAccount, updateSeenAnnouncements };
};

export default useAccountUpdate;
