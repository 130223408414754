import { BrowserRouter, Route } from 'react-router-dom';
import { AnalyticsProvider, ConfigurationProvider } from '@medely/ui-kit/web';
import App from 'App';
import ErrorBoundary from 'core/containers/ErrorBoundary';
import { QueryParamProvider } from 'use-query-params';
import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import config from 'config';
import moment from 'moment-timezone';
import { FacilityAuthProvider } from 'context/facilityAuthContext';

window.moment = moment;

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        hideIconVariant
        maxSnack={3}
      >
        <QueryParamProvider ReactRouterRoute={Route}>
          <AnalyticsProvider writeKey={config.segmentWriteKey}>
            <ConfigurationProvider>
              <FacilityAuthProvider>
                <App />
              </FacilityAuthProvider>
            </ConfigurationProvider>
          </AnalyticsProvider>
        </QueryParamProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root'),
);

// Webpack Hot Module Replacement API
// $FlowFixMe
// if (module.hot) {
//   // $FlowFixMe
//   module.hot.accept('../src/App', () => {
//     // $FlowFixMe
//     ReactRailsUJS.mountComponents(); // eslint-disable-line
//   });
// }
