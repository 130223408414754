import { ArrowLeftFromLine, BellSolid } from '@medely/web-components/icons';
import { Badge, Box, Drawer, IconButton, Typography } from '@medely/web-components';
import React, { useState } from 'react';
import ExpiringAssignments from './ExpiringAssignments';
import NewApplicants from './NewApplicants';
import PhoneInterviews from './PhoneInterviews';
import ReferralButton from 'components/ReferralButton';
import useNotifications from 'hooks/useNotifications';

const Notifications = (): React.ReactElement => {
  const { newApplications, upcomingPhoneScreenings, expiringAssignments, count } =
    useNotifications();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton onClick={handleOpen}>
        <Box
          display="inline-block"
          position="relative"
          mx="auto"
          data-testid="header-menu-notifications"
        >
          {count > 0 ? (
            <Badge content={count}>
              <BellSolid color="primary" />
            </Badge>
          ) : (
            <BellSolid color="primary" />
          )}
        </Box>
      </IconButton>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        <Box display="flex" flexDirection="column" width="17em" height="100%">
          <Box>
            <Box display="flex" mx={3} alignItems="center" justifyContent="space-between" mb={3}>
              <Typography /*fontSize={2}*/ weight="bold">Reminders</Typography>
              <IconButton onClick={handleClose}>
                <ArrowLeftFromLine />
              </IconButton>
            </Box>
            {count === 0 && (
              <Box textAlign="center" mx={3}>
                <Typography /*as="p"  color="gray.50"*/>No reminders at this time.</Typography>
              </Box>
            )}
            {!!upcomingPhoneScreenings.length && (
              <PhoneInterviews phone_interviews={upcomingPhoneScreenings} />
            )}
            {!!newApplications.length && (
              <NewApplicants assignmentRequests={newApplications} handleClose={handleClose} />
            )}
            {!!expiringAssignments.length && (
              <ExpiringAssignments assignments={expiringAssignments} handleClose={handleClose} />
            )}
          </Box>
          <ReferralButton />
        </Box>
      </Drawer>
    </>
  );
};

export default Notifications;
