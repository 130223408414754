import React from 'react';
import { Box, OptionType, Select, Tooltip } from '@medely/web-components';
import { Field } from 'react-final-form';
import { FundingSourceStatus } from '@medely/types';
import { FundingSource } from 'graphql/generated/graphql';
import { InfoBadge } from '@medely/ui-kit/web';
import { VERIFY_ON_STRIPE_TOOLTIP_TITLE } from 'constants/strings';
import { useFundingSources } from 'hooks/useFundingSources';

export type PaymentMethodOption = {
  label: string;
  value: string;
  isProcessing?: boolean;
  disabled?: boolean;
};

type PaymentMethodFieldProps = {
  onChange?: (value: PaymentMethodOption | null) => void;
  showOnlyNewOptions?: boolean;
};

const PAYMENT_METHOD_OPTIONS: PaymentMethodOption[] = [
  {
    label: 'New credit card',
    value: 'card',
  },
  {
    label: 'New bank account',
    value: 'us_bank_account',
  },
];

const WIRE_TRANSFER_OPTION: PaymentMethodOption = {
  label: 'ACH credit or domestic wire to J.P. Morgan instructions',
  value: 'wire_instructions',
};

const getOptionsWithSavedPaymentMethods = (
  savedCC: FundingSource | undefined,
  savedBankAccount: FundingSource | undefined,
  showOnlyNewOptions: boolean,
) => {
  const isBankAccountProcessing = savedBankAccount?.status === FundingSourceStatus.Processing;
  let paymentMethodOptions = PAYMENT_METHOD_OPTIONS;
  if (isBankAccountProcessing) {
    // Disable new bank account option if a pre-existing bank account is still processing
    paymentMethodOptions = PAYMENT_METHOD_OPTIONS.map((opt) => {
      return opt.label === 'New bank account' ? { ...opt, disabled: true } : opt;
    });
  }

  if (showOnlyNewOptions) {
    // Return early if we only want to show new payment method options
    return paymentMethodOptions;
  }

  const savedOptions: PaymentMethodOption[] = [];
  if (!!savedCC) {
    savedOptions.push({
      label: `Credit card (${savedCC.brand.toUpperCase()}, ${savedCC.last4})`,
      value: `savedCard_${savedCC.id}`,
    });
  }
  if (!!savedBankAccount) {
    savedOptions.push({
      label: `Bank account (••••${savedBankAccount.last4})`,
      value: `savedBankAccount_${savedBankAccount.id}`,
      isProcessing: isBankAccountProcessing,
    });
  }

  return [...savedOptions, ...paymentMethodOptions, WIRE_TRANSFER_OPTION];
};

export const PaymentMethodField = ({
  onChange,
  showOnlyNewOptions = false,
}: PaymentMethodFieldProps) => {
  const { savedCC, savedBankAccount } = useFundingSources();

  const options = getOptionsWithSavedPaymentMethods(savedCC, savedBankAccount, showOnlyNewOptions);

  return (
    <Field
      name="payment_method"
      render={({ input, meta }) => (
        <Select
          textFieldProps={{
            label: 'Payment method',
            error: meta.touched && meta.error,
            helperText: meta.touched && meta.error,
            fullWidth: true,
          }}
          {...input}
          value={options.find((o) => input.value === o.value)}
          renderValue={(option: PaymentMethodOption) => {
            return (
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box>{option.label}</Box>
                <Tooltip title={option.isProcessing ? VERIFY_ON_STRIPE_TOOLTIP_TITLE : ''}>
                  <Box>
                    {option.isProcessing && <InfoBadge color="warning" label="Verify on Stripe" />}
                  </Box>
                </Tooltip>
              </Box>
            );
          }}
          options={options}
          onChange={(option: OptionType) => {
            if (!!onChange) {
              onChange(option as PaymentMethodOption);
            }
            input.onChange(option?.value);
          }}
        />
      )}
    />
  );
};
