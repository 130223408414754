import { omit } from 'lodash';
import { Assignment, Job } from 'graphql/generated/graphql';
import { axios } from 'utils/networkRequests';
import { JobPayload } from './jobs';

export const assignments = {
  create: (values: { assignment: Partial<Assignment> }) =>
    axios.post('/v3/facility/assignments', values),
  update: (values: { assignment: Partial<Assignment> }, assignmentId: number) =>
    axios.put(`/v3/facility/assignments/${assignmentId}`, values),
  actions: {
    addJobs: (jobs: JobPayload[], assignmentId: number) =>
      axios.post(`/v3/facility/assignments/${assignmentId}/jobs`, {
        jobs,
      }),
    updateJob: (job: Partial<Job>, assignmentId: number, jobId: number) =>
      axios.put(`/v3/facility/assignments/${assignmentId}/jobs/${jobId}`, job),
    cancel: (
      values: { cancellation_date: string; other_reason: string; reason: string },
      assignmentId: number,
    ) =>
      axios.post(`/v3/facility/assignments/${assignmentId}/cancel`, {
        cancel: {
          cancellation_date: values.cancellation_date,
          cancellation_reason: values.other_reason || values.reason,
        },
      }),
    extend: (values: { assignment: Partial<Assignment> }, assignmentId: number) =>
      axios.post(`/v3/facility/assignments/${assignmentId}/extension`, {
        assignment: omit(values.assignment, ['primary_location_id', 'location_ids']),
      }),
    pause: (assignmentId: number) =>
      axios.patch(`/v3/facility/assignments/${assignmentId}`, {
        assignment: {
          paused: true,
        },
      }),
    unpause: (assignmentId: number) =>
      axios.patch(`/v3/facility/assignments/${assignmentId}`, {
        assignment: {
          paused: false,
        },
      }),
  },
};
