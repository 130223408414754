import useCurrentUser from 'hooks/useCurrentUser';
import React, { useEffect, useState } from 'react';
import useAccountUpdate from 'hooks/useAccountUpdate';
import TextNotificationsDialog from './FeatureAnnouncementDialogs/TextNotificationsDialog';
import usePermissions from 'hooks/usePermissions';
import { Role } from '@medely/types';
import { isNotNewUser } from 'utils/datetime';

export const roles: Role[] = ['company_admin', 'facility_manager', 'scheduler', 'view_only'];

const announcements = [
  {
    key: 'text-notifications',
    Component: TextNotificationsDialog,
  },
];
const announcementKeys = announcements.map((announcement) => announcement.key);

const FeatureAnnouncements = () => {
  const [openAnnouncementKey, setOpenAnnouncementKey] = useState<string>('');
  const { updateSeenAnnouncements } = useAccountUpdate();
  const { currentUser } = useCurrentUser();
  const currentAnnouncement = announcements.find(
    (announcement) => announcement.key === openAnnouncementKey,
  );
  const canSeeAnnouncements = usePermissions(roles) && isNotNewUser(currentUser.created_at);

  useEffect(() => {
    if (canSeeAnnouncements) {
      handleNextAnnouncement();
    }
  }, []);

  const handleNextAnnouncement = () => {
    // Has seen all announcements
    if (announcementKeys.every((key) => currentUser?.announcements_already_seen?.includes(key))) {
      setOpenAnnouncementKey('');
      return;
    }

    const currentAnnouncementIdx = announcements.findIndex(
      (announcement) => announcement.key === openAnnouncementKey,
    );

    const nextAnnouncement = announcements[currentAnnouncementIdx + 1];
    if (!nextAnnouncement) {
      setOpenAnnouncementKey('');
      return;
    }

    const hasNotSeenNextAnnouncement = !currentUser?.announcements_already_seen?.includes(
      nextAnnouncement?.key,
    );

    if (hasNotSeenNextAnnouncement) {
      setOpenAnnouncementKey(nextAnnouncement.key);
      updateSeenAnnouncements({ announcements_already_seen: [nextAnnouncement.key] });
    } else {
      handleNextAnnouncement();
    }
  };

  const handleClose = () => {
    setOpenAnnouncementKey('');
  };

  return (
    <>
      {openAnnouncementKey !== '' && (
        <currentAnnouncement.Component
          open={openAnnouncementKey === currentAnnouncement?.key}
          handleNextAnnouncement={handleNextAnnouncement}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default FeatureAnnouncements;
