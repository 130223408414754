import accept from 'attr-accept';
import { centsToCurrency } from './currency';
import moment from 'moment-timezone';

export const MESSAGES = {
  email: 'Email is not valid',
  mime: 'File type is not valid',
  minCents: (amount: number): string => `Can't be less ${centsToCurrency(amount)}`,
  minLength: (length: number): string => `Minimum is ${length} characters`,
  notExpired: 'Expired',
  phone: 'Phone number is not valid',
  required: 'Required',
  sameAs: (name: string): string => `Doesn't match ${name}`,
  zip: 'Zip code is not valid',
};

export const required = (v): string | undefined => {
  if (typeof v === 'undefined' || v === null) {
    return MESSAGES.required;
  }
  if (typeof v === 'object' && !Object.keys(v).length && !(v instanceof Date)) {
    return MESSAGES.required;
  }
  if (Array.isArray(v) && v.length === 0) {
    return MESSAGES.required;
  }
  if (typeof v === 'number') {
    return undefined;
  }
  if (typeof v === 'boolean') {
    return undefined;
  }
  if (typeof v === 'string') {
    return Boolean(v.trim()) ? undefined : MESSAGES.required;
  }
  return !v ? MESSAGES.required : undefined;
};

export const mimeType =
  (mime: string) =>
  (v: { type: string }): string | undefined =>
    v?.type ? (accept(v, mime) ? undefined : MESSAGES.mime) : undefined;
export const minLength =
  (length: number) =>
  (v: string): string | undefined =>
    v && v.length < length ? MESSAGES.minLength(length) : undefined;

export const sameAs =
  (name: string, displayName?: string) =>
  (v: string, all: any): string | undefined =>
    v === all[name] ? undefined : MESSAGES.sameAs(displayName || name);

export const notExpired = (d: string): string | undefined =>
  moment(d).diff(moment(), 'days') < 0 ? MESSAGES.notExpired : undefined;

export const email = (v: string): string | undefined => {
  if (!v) {
    return undefined;
  }
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    v,
  )
    ? undefined
    : MESSAGES.email;
};

export const zip = (v: string): string | undefined => {
  if (!v) {
    return undefined;
  }
  const regex = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return regex.test(v) ? undefined : MESSAGES.zip;
};

export const phone = (v: string): string | undefined =>
  v && v.length < 12 ? MESSAGES.phone : undefined;
export const minCents =
  (amount: number) =>
  (v: number): string | undefined =>
    v < amount ? `Can't be less ${centsToCurrency(amount)}` : undefined;

export const pastDates = (v: string): string | undefined => {
  if (!v) {
    return undefined;
  }
  return moment().isAfter(moment(v)) ? undefined : 'Date must be in the past';
};

export const endWithStartDate = (v: string, obj: { start_date: string }): string | undefined => {
  if (!v || !obj.start_date) {
    return undefined;
  }
  return moment(v).isAfter(obj.start_date) ? undefined : 'End date should be after start date';
};

export const dob = (value: moment.Moment | undefined): string | undefined => {
  if (value && moment().diff(value, 'years') < 18) {
    return 'You must be at least 18 years old to have a Medely account';
  }
  return undefined;
};

export const pipeValidation =
  (...fns) =>
  (...args) =>
    fns.filter(Boolean).reduce((acc, fn) => acc || fn(...args), undefined);

export const composeValidation =
  (...fns) =>
  (...args) =>
    fns.filter(Boolean).reduceRight((acc, fn) => acc || fn(...args), undefined);

/**
 * @todo this was using a deprecated api file that was using old rails endpoint
 * figure out if we need to keep this, and if we do update it to use graphql
 */
// export const twilioPhone = composeValidation(
//   R.memoizeWith(R.identity, (value: string) =>
//     API.phone_lookups(value)
//       .then(() => undefined)
//       .catch(() => MESSAGES.phone),
//   ),
//   phone,
//   required,
// );
